import React from 'react';

interface CenterVertically {
    gap?: string;
    children: React.ReactNode;
}

export function CenterVertically(props: CenterVertically) {
    return (
        <div style={{display: 'flex', alignItems: 'center', gap: props.gap}}>
            {props.children}
        </div>
    );
}