import React, {useEffect} from "react";
import {useStepFunctions} from "../../../api/steps/useStepFunctions";
import {CommonStepProps} from "./DynamicStepComponentRenderer";
import notifier from "simple-react-notifications2";
import {Button, Col, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    CenterVerticallyAndHorizontally
} from "../../layout/CenterVerticallAndHorizontally/CenterVerticallyAndHorizontally";
import Container from "react-bootstrap/Container";
import {Loading} from "../../Loading";


export function PreviewContract(props: CommonStepProps) {

    const {loading, error, execute, accept, decline} = useStepFunctions();
    const {orderId} = useParams();
    const {t, i18n} = useTranslation();


    return(
        <>
            <Container className="vh-100 px-0 mt-5">

            </Container>

        </>
    )
}
