import {MyNavbar} from "./MyNavbar";
import {Outlet} from "react-router-dom";


export function MainLayout() {

    return (
        <>
            <MyNavbar/>
            <Outlet/>
        </>

    )
}