import notifier from "simple-react-notifications2";
import "simple-react-notifications2/dist/index.css";

notifier.configure({
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    animation: {
        in: "zoomIn",
        out: "zoomOut",
        duration: 400
    }
});