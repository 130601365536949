import React, { createContext, useContext, useState } from 'react';
import {TenantDto} from "../../model/TenantDto";

interface SelectedTenantContextType {
    selectedTenant: TenantDto | undefined;
    setSelectedTenant: React.Dispatch<React.SetStateAction<TenantDto | undefined>>;
}

const SelectedTenantContext = createContext<SelectedTenantContextType | undefined>(undefined);

export const useSelectedTenant = () => {
    const context = useContext(SelectedTenantContext);
    if (!context) {
        throw new Error('useDropdown must be used within a DropdownProvider');
    }
    return context;
};

interface Props {
    children: React.ReactNode;
}

export const SelectedTenantProvider: React.FC<Props> = ({ children }) => {
    const [selectedTenant, setSelectedTenant] = useState<TenantDto | undefined>(undefined);

    return (
        <SelectedTenantContext.Provider value={{ selectedTenant, setSelectedTenant }}>
            {children}
        </SelectedTenantContext.Provider>
    );
};
