import {customAxios} from "../customAxios";
import {useEffect, useState} from "react";
import {UserDto} from "../../model/Users";

export function useDeactivateUser() {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    async function execute(tenantId: number, userId: number) {
        try {
            setLoading(true);
            await deactivate(tenantId, userId)
            return;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading, error, execute
    };
}

export async function deactivate(tenantId: number, userId: number): Promise<UserDto> {
    const response = await customAxios.delete<UserDto>(`/v1/tenant/${tenantId}/users/${userId}`);
    return response.data;
}

export async function updateUser(tenantId: number, dto: UserDto, id: number): Promise<UserDto> {
    const response = await customAxios.put<UserDto>(`/v1/tenant/${tenantId}/users/${dto.id}`, dto);
    return response.data;
}
