import {customAxios} from "../customAxios";
import {useState} from "react";
import {LeasePackageImportDto} from "../../model/Imports";

export function useGetImports() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<LeasePackageImportDto[]>([]);
    const [error, setError] = useState<Error>();

    async function execute(tenantId: number) {
        try {
            setLoading(true);
            const data = await getImports(tenantId);
            setData(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading, data, error, execute
    };
}

export async function getImports(tenantId: number): Promise<LeasePackageImportDto[]> {
    const response = await customAxios.get<LeasePackageImportDto[]>(`/v1/lease-packages/import/tenant/${tenantId}`, );
    return response.data;
}

