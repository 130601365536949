


export function nf(
    value: number,
    locale: string,
    maximumFractionDigits: number,
    minimumFractionDigits: number = 0
): string {
    return value.toLocaleString(locale, {
        maximumFractionDigits: maximumFractionDigits,
        minimumFractionDigits: minimumFractionDigits,
    });
}