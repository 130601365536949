import {Spinner} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import React from "react";
import {CenterVerticallyAndHorizontally} from "./layout/CenterVerticallAndHorizontally/CenterVerticallyAndHorizontally";


export function Loading() {

    return (
        <Container>
            <CenterVerticallyAndHorizontally>
            <Spinner animation="border" variant="primary" />
            </CenterVerticallyAndHorizontally>
        </Container>
    )
}