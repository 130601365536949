import {
    Col,
    Form,
    FormCheck,
    FormControl,
    FormControlProps,
    FormGroup,
    FormLabel,
    FormSelect,
    Row
} from "react-bootstrap";
import {Field, FormikErrors, FormikTouched} from "formik";
import React from "react";
import {useTranslation} from "react-i18next";
import {Bundesland, CodeNumberValueDto} from "../../model/salary/SalaryOptions";
import {UserDto, UserSalaryInfo} from "../../model/salary/UserInfo";
import {parseBoolean} from "../../util/parsing";
import {TestTaxDto} from "../../model/tax";

interface TaxTestBooleanRadioFieldProps {
    name: string;
    value: boolean | undefined;
    setFieldValue: (field: string, value: any, shouldValidate?: (boolean | undefined)) => Promise<void | FormikErrors<TestTaxDto>>
    touched: FormikTouched<{ [key: string]: any }>;
    errors: FormikErrors<{ [key: string]: any }>;
}

export default function TaxTestBooleanRadioFormGroup(
    props: TaxTestBooleanRadioFieldProps
) {
    const {t, i18n} = useTranslation();
    const error = props.errors[props.name];

    return (
        <>
            <FormGroup className="mt-1 mb-3">
                <Row>
                    <Col xs={6}>
                        <FormLabel
                            className="form-check-inline">{t(`salary.${props.name}`)}</FormLabel>
                    </Col>
                    <Col xs={6} className="text-end">
                        <div className="form-check-inline">
                            <FormCheck
                                className="form-check-inline"
                                type="radio"
                                aria-describedby="inputGroupPrepend"
                                name={props.name}
                                id={`${props.name}Yes`}
                                label={t('salary.yes')}
                                onChange={event => {
                                    props.setFieldValue(props.name, parseBoolean(event.target.value));
                                }}
                                checked={props.value === true}
                                value="true"
                                isInvalid={!!props.touched[props.name] && !!props.errors[props.name]}
                                as={Field}
                            />
                            <FormCheck
                                className="form-check-inline"
                                type="radio"
                                aria-describedby="inputGroupPrepend"
                                name={props.name}
                                id={`${props.name}No`}
                                label={t('salary.no')}
                                onChange={event => {
                                    props.setFieldValue(props.name, parseBoolean(event.target.value));
                                }}
                                checked={props.value === false}
                                value="false"
                                isInvalid={!!props.touched[props.name] && !!props.errors[props.name]}
                                as={Field}
                            />
                        </div>
                    </Col>
                </Row>
                {error && (
                    <FormControl.Feedback type="invalid">
                        {error.toString()}
                    </FormControl.Feedback>
                )}
            </FormGroup>
        </>

    )
}

