import React from "react";
import {CommonStepProps} from "./DynamicStepComponentRenderer";


export function AdminSteps(props: CommonStepProps) {


    return (
        <>
            <h1>Waiting for admin confirmation</h1>
        </>
    )
}