import {customAxios} from "../customAxios";
import {useState} from "react";
import {FoundVehiclesDto, PrepareResponse, VehicleKeyAll, VehicleKeyAndFound} from "../../model/import/importDtos";
import {TenantDto} from "../../model/TenantDto";


export function useLeasePackagesImportPrepare() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<PrepareResponse>();
    const [error, setError] = useState<Error>();

    async function execute(formData: FormData,) {
        try {
            setLoading(true);
            const data = await leasePackagesImportPrepare(formData,);
            setData(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }


    return {
        loading, data, error, execute
    };
}

async function leasePackagesImportPrepare(formData: FormData,): Promise<PrepareResponse> {
    const response = await customAxios.post<PrepareResponse>(`/v1/lease-packages/import-prepare`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}
