import {customAxios} from "../customAxios";
import {useState} from "react";

export function useEnableImport() {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    async function execute(importId: number) {
        try {
            setLoading(true);
            await enableImport(importId);
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading, error, execute
    };
}

export async function enableImport(importId: number): Promise<void> {
    const response = await customAxios.put<void>(`/v1/lease-packages/import/${importId}`, );
    return response.data;
}

export function useDisableImport() {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    async function execute(importId: number) {
        try {
            setLoading(true);
            await disableImport(importId);
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading, error, execute
    };
}

export async function disableImport(importId: number): Promise<void> {
    const response = await customAxios.delete<void>(`/v1/lease-packages/import/${importId}`, );
    return response.data;
}

