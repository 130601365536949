import {customAxios} from "../customAxios";
import {useEffect, useState} from "react";
import {UserDto} from "../../model/Users";

export function useReactivateUser() {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    async function execute(tenantId: number, userId: number) {
        try {
            setLoading(true);
            await reactivate(tenantId, userId)
            return;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading, error, execute
    };
}

export async function reactivate(tenantId: number, userId: number): Promise<UserDto> {
    const response = await customAxios.post<UserDto>(`/v1/tenant/${tenantId}/users/${userId}/reactivate`);
    return response.data;
}