import {useState} from 'react';
import {Bundesland, SalaryOptions} from "../../model/salary/SalaryOptions";

export function useSalaryOptions() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<SalaryOptions>();
    const [error, setError] = useState<Error>();

    async function execute() {
        try {
            setLoading(true);
            const data: SalaryOptions = {
                // federalStates: [{code: 'BW', value:'Baden-Württemberg'}, {code: 'BY', value:'Bayern'}, {code: 'BA', value:'Berlin-Berlin-West'}, {code: 'BN', value:'Berlin-Ost'}, {code: 'BB', value:'Brandenburg'}, {code: 'HB', value:'Bremen'}, {code: 'HH', value:'Hamburg'}, {code: 'HE', value:'Hessen'}, {code: 'MV', value:'Mecklenburg-Vorpommern'}, {code: 'NI', value:'Niedersachsen'}, {code: 'NW', value:'Nordrhein-Westfalen'}, {code: 'RP', value:'Rheinland-Pfalz'}, {code: 'SL', value:'Saarland'}, {code: 'SN', value:'Sachsen'}, {code: 'ST', value:'Sachsen-Anhalt'}, {code: 'SH', value:'Schleswig-Holstein'}, {code: 'TH', value:'Thüringen'}],
                federalStates: bundeslaender,
                taxClass: [{code: 1, value:'1'}, {code: 2, value:'2'}, {code: 3, value:'3'}, {code: 4, value:'4'}, {code: 5, value:'5'}, {code: 6, value:'6'}],
                childrenUnder25: [{code: 0, value: '0'}, {code: 1, value: '1'}, {code: 2, value: '2'}, {code: 3, value: '3'}, {code: 4, value: '4'}, {code: 5, value: '5'}, {code: 6, value: '6 or more'},],
                childrenAllowances: [{code: 0, value: '0'}, {code: 0.5, value: '0.5'}, {code: 1, value: '1'}, {code: 1.5, value: '1.5'}, {code: 2, value: '2'}, {code: 2.5, value: '2.5'}, {code: 3, value: '3'}, {code: 3.5, value: '3.5'}, {code: 4, value: '4'}, {code: 4.5, value: '4.5'}, {code: 5, value: '5'}, {code: 5.5, value: '5.5'}, {code: 6, value: '6'}, {code: 6.5, value: '6.5'}, {code: 7, value: '7'}, {code: 7.5, value: '7.5'}, {code: 8, value: '8'}, {code: 8.5, value: '8.5'}, {code: 9, value: '9'}, {code: 9.5, value: '9.5'}, {code: 10, value: '10'}],
            };
            setData(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {loading, data, error, execute};
}


const bundeslaender: Bundesland[] = [
    {code: 'BW', name:'Baden-Wurttemberg', churchTax: 0.08, isWest: true, pvs: false, akb: false},
    {code: 'BY', name:'Bayern', churchTax: 0.08, isWest: true, pvs: false, akb: false},
    {code: 'BA', name:'Berlin-Berlin-West', churchTax: 0.09, isWest: true, pvs: false, akb: false},
    {code: 'BN', name:'Berlin-Ost', churchTax: 0.09, isWest: false, pvs: false, akb: false},
    {code: 'BB', name:'Brandenburg', churchTax: 0.09, isWest: false, pvs: false, akb: false},
    {code: 'HB', name:'Bremen', churchTax: 0.09, isWest: true, pvs: false, akb: true},
    {code: 'HH', name:'Hamburg', churchTax: 0.09, isWest: true, pvs: false, akb: false},
    {code: 'HE', name:'Hessen', churchTax: 0.09, isWest: true, pvs: false, akb: false},
    {code: 'MV', name:'Mecklenburg-Vorpommern', churchTax: 0.09, isWest: false, pvs: false, akb: false},
    {code: 'NI', name:'Niedersachsen', churchTax: 0.09, isWest: true, pvs: false, akb: false},
    {code: 'NW', name:'Nordrhein-Westfalen', churchTax: 0.09, isWest: true, pvs: false, akb: false},
    {code: 'RP', name:'Rheinland-Pfalz', churchTax: 0.09, isWest: true, pvs: false, akb: false},
    {code: 'SL', name:'Saarland', churchTax: 0.09, isWest: true, pvs: false, akb: true},
    {code: 'SN', name:'Sachsen', churchTax: 0.09, isWest: false, pvs: true, akb: false},
    {code: 'ST', name:'Sachsen-Anhalt', churchTax: 0.09, isWest: false, pvs: false, akb: false},
    {code: 'SH', name:'Schleswig-Holstein', churchTax: 0.09, isWest: true, pvs: false, akb: false},
    {code: 'TH', name:'Thüringen', churchTax: 0.09, isWest: false, pvs: false, akb: false},
];