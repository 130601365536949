import {useState} from "react";
import {customAxios} from "../customAxios";

export function useDownloadSignedContract() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<string>();
    const [error, setError] = useState<Error>();

    async function execute(orderId: number, documentId: number) {
        try {
            setLoading(true);
            const data = await downloadSignedContract(orderId, documentId);
            setData(data);
            return data;
        }
        catch (e) {
            setError(error);
        }
        finally {
            setLoading(false);
        }
    }

    return {loading, data, error, execute};
}


export async function downloadSignedContract(orderId: number, documentId: number): Promise<string> {
    const response = await customAxios.get<Blob>(`/v1/order/${orderId}/contract/${documentId}`, {responseType: 'blob', headers: {'Content-Type' : 'application/pdf'}});
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
    return URL.createObjectURL(pdfBlob);
}