import {TenantContractConfigDto, TenantDto} from "../../model/TenantDto";
import {customAxios} from "../customAxios";
import {useEffect, useState} from "react";

export function useGetTenantContractConfig() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<TenantContractConfigDto>();
    const [error, setError] = useState<Error>();

    async function execute(id: number) {
        try {
            setLoading(true);
            const data = await getTenant(id);
            setData(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading, data, error, execute
    };
}

export async function getTenant(id: number): Promise<TenantContractConfigDto> {
    const response = await customAxios.get<TenantContractConfigDto>(`/v1/tenant/${id}/contract-config`, );
    return response.data;
}

