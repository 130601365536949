import {useState} from "react";
import {customAxios} from "../customAxios";
import {TenantUserInfoConfigDto} from "../../model/salary/SalaryOptions";

export function useUpdateTenantUserInfoConfig() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<TenantUserInfoConfigDto>();
    const [error, setError] = useState<Error>();

    async function execute(dto: TenantUserInfoConfigDto, tenantId: number) {
        try {
            setLoading(true);
            const data = await updateTenantUserInfoConfig(dto, tenantId);
            setData(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }


    return {
        loading, data, error, execute
    };
}

export async function updateTenantUserInfoConfig(dto: TenantUserInfoConfigDto, tenantId: number): Promise<TenantUserInfoConfigDto> {
    const response = await customAxios.put<TenantUserInfoConfigDto>(`/v1/tenant/${tenantId}/user-info-config`, dto);
    return response.data;
}
