import React from 'react';
import {Navigate, useLocation} from "react-router-dom";
import {Location} from "@remix-run/router/history";

interface Authenticated {
    children: React.ReactNode;
}

export function Authenticated(props: Authenticated) {
    const router = useLocation()
    if (localStorage.getItem('authData')) return props.children;
    else return <Navigate to={getCallbackPathname(router)}/>;
}

function getCallbackPathname(router: Location) {
    const path = router.pathname
    if(path.length > 1) {
        return `/login?${callbackName}=${path}`
    } else {
        return '/login'
    }
}

export const callbackName = 'callbackPath';