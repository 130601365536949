import {useGetVehicles} from "../../api/vehicle/useVehicles";
import {VehicleSimpleDto} from "../../model/Vehicle";
import {DatatableWrapper, TableBody, TableColumnType, TableHeader} from "react-bs-datatable";
import {Button, Row, Table} from "react-bootstrap";
import React from "react";
import Container from "react-bootstrap/Container";
import {TenantDto} from "../../model/TenantDto";
import {useNavigate} from "react-router-dom";

type ArrayElementType = VehicleSimpleDto & {
    button: any;
};

export default function Vehicles() {
    const navigate = useNavigate();

    const {loading, data, error, execute} = useGetVehicles();

    const header: TableColumnType<ArrayElementType>[] = [
        {title: 'Id', prop: 'id', isSortable: true, isFilterable: true},
        {title: 'Brand', prop: 'brand', isSortable: true, isFilterable: true},
        {title: 'Model', prop: 'model', isSortable: true, isFilterable: true},
        {title: 'Car model', prop: 'carModel', isSortable: true, isFilterable: true},
        {title: 'Body Type', prop: 'bodyType', isSortable: true, isFilterable: true},
        {title: 'Trim level', prop: 'trimLevel', isSortable: true, isFilterable: true},
        {title: 'Range', prop: 'rangeKm', isSortable: true, isFilterable: true},
        {title: 'Power', prop: 'maximumPowerKw', isSortable: true, isFilterable: true},
        {
            prop: "button",
            cell: (row) => (
                <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => {
                        edit(row);
                    }}
                >
                    Edit
                </Button>
            )
        }
    ];

    function edit(dto: VehicleSimpleDto) {
        navigate(`/vehicles/${dto.id}`)
    }

    return (
        <>
            <Container>
                <Row className="mt-5 mb-5">
                    <DatatableWrapper
                        body={data as ArrayElementType[]}
                        headers={header}
                    >
                        <Table className="custom-table">
                            <TableHeader/>
                            <TableBody
                                onRowClick={(row, event) => edit(row as VehicleSimpleDto)}
                            />
                        </Table>
                    </DatatableWrapper>
                </Row>
            </Container>
        </>
    )
}