import {customAxios} from "../customAxios";
import {useState} from "react";
import {
    FoundVehiclesDto,
    MultiTenantImportsDto,
    VehicleKeyAll,
    VehicleKeyAndFound
} from "../../model/import/importDtos";
import {TenantDto} from "../../model/TenantDto";


export function useLeasePackagesImportV2Execute() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<void>();
    const [error, setError] = useState<Error>();

    async function execute(dto: MultiTenantImportsDto,) {
        try {
            setLoading(true);
            const data = await leasePackagesImportV2Execute(dto,);
            setData(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }


    return {
        loading, data, error, execute
    };
}

async function leasePackagesImportV2Execute(dto: MultiTenantImportsDto): Promise<void> {
    const response = await customAxios.post<void>(`/v1/lease-packages/import-execute`, dto,);
    return response.data;
}
