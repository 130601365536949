import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import FileUploadForm from "./FileUploadForm";
import {useSelectedTenant} from "../tenant/SelectedTenantContext";

function FileUploadModal(
    props: {reload: () => void}
) {
    const [showModal, setShowModal] = useState(false);
    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const { selectedTenant, setSelectedTenant } = useSelectedTenant();

    if(!selectedTenant) {
        return (<></>)
    }

    function uploadFinished() {
        setShowModal(false);
        props.reload();
    }

    return (
        <div>
            <Button variant="primary" onClick={handleShow}>
                Upload File
            </Button>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload a File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FileUploadForm reload={uploadFinished} tenantId={selectedTenant.id}></FileUploadForm>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default FileUploadModal;
