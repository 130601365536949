import {Button} from "react-bootstrap";
import React, {useState} from "react";
import notifier from "simple-react-notifications2";
import ConfirmModal from "../components/modals/ConfirmModal";
import {useTempFunctions} from "../api/temp/useTempFunctions";


export function Other() {

    const [showNotifyModal, setShowNotifyModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const {loading, error, notifyUnconfirmed, deleteUnconfirmed} = useTempFunctions();

    function notifyClicked() {
        setShowNotifyModal(true)
    }

    function deleteClicked() {
        setShowDeleteModal(true)
    }

    function executeNotify() {
        notifyUnconfirmed().then(res => {
            notifier.success("Success")
        }).catch(e => notifier.error(e))
            .finally(() =>
                setShowNotifyModal(false)
            )
    }

    function executeDelete() {
        deleteUnconfirmed().then(res => {
            notifier.success("Success")
        }).catch(e => notifier.error(e))
            .finally(() =>
                setShowDeleteModal(false)
            )
    }

    return (
        <>
            <Button disabled={loading} variant="primary" onClick={() => notifyClicked()}>
               Notify unconfirmed
            </Button>
            <Button disabled={loading} variant="warning" onClick={() => deleteClicked()}>
                Delete unconfirmed
            </Button>
            <ConfirmModal
                show={showNotifyModal}
                onHide={() => setShowNotifyModal(false)}
                onConfirm={executeNotify}
                title={'Notify unconfirmed'}
                body={'Notify unconfirmed'}
            />
            <ConfirmModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onConfirm={executeDelete}
                title={'Delete unconfirmed'}
                body={'Delete unconfirmed'}
            />
        </>
    )
}