import {customAxios} from "../customAxios";
import {useState} from "react";


export function useVehicleMainPhotoImport() {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    async function execute(formData: FormData, vehicleId: number) {
        try {
            setLoading(true);
            const data = await photoImport(formData, vehicleId);
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }


    return {
        loading, error, execute
    };
}

async function photoImport(formData: FormData, vehicleId: number): Promise<void> {
    const response = await customAxios.post(`/v1/vehicle/${vehicleId}/main-photo`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}
