import {useState} from "react";
import {customAxios} from "../customAxios";
import {OrderFullDto} from "../../model/order/orderFullDto";
import {OrderWorkflowStep, OrderWorkflowUserStep} from "../../model/order/orderWorkflow";
import notifier from "simple-react-notifications2";
import {findUserStepsForSteps} from "./useOrders";

export function useOrder() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<OrderFullDto>();
    const [error, setError] = useState<Error>();

    async function execute(id: number) {
        try {
            setLoading(true);
            const fetched = await getOrder(id);
            findUserStepsForSteps(fetched.steps, fetched.userSteps)
            setData(fetched);
            return fetched;
        }
        catch (e) {
            setError(e as Error);
            notifier.error((e as Error).message)
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {loading, data, error, execute};
}


export async function getOrder(id: number): Promise<OrderFullDto> {
    const response = await customAxios.get<OrderFullDto>(`/v1/order/${id}/full`);
    return response.data;
}