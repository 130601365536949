import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button, Col, Row, Table} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {useTranslation} from "react-i18next";
import {useOrder} from "../../api/orders/useOrder";
import {useDownloadSignedContract} from "../../api/orders/useDownloadSignedContract";
import {Loading} from "../../components/Loading";
import {
    CenterVerticallyAndHorizontally
} from "../../components/layout/CenterVerticallAndHorizontally/CenterVerticallyAndHorizontally";
import {OrderFullDto} from "../../model/order/orderFullDto";
import {nf} from "../../util/numberFormat";
import {ReadMoreTaxTable} from "../../components/tax/ReadMoreTaxTable";
import {ReadMoreUserInfo} from "../../components/users/ReadMoreUserInfo";
import {calculateSavingsPercent} from "../../util/saving";
import VehicleCarousel from "../../components/vehicle/VehicleCarousel";
import {useStepFunctions} from "../../api/steps/useStepFunctions";
import {MyOrderStepper} from "../../components/stepper/MyOrderStepper";
import {useActivateOrder, useDeactivateOrder} from "../../api/orders/useDeactivateOrder";
import {useDeleteOrder} from "../../api/orders/deleteOrder";
import ConfirmModal from "../../components/modals/ConfirmModal";
import notifier from "simple-react-notifications2";



export default function Order() {

    const {t, i18n} = useTranslation();
    const {loading, data, error, execute} = useOrder();
    const {loading: loadingSteps, error: errorSteps, execute: executeStep, accept, decline} = useStepFunctions();
    const {loading: loadingContract, data: dataContract, error: errorContract, execute: executeContract,} = useDownloadSignedContract();
    const {loading: loadingDeactivate, error: errorDeactivate, execute: executeDeactivate,} = useDeactivateOrder();
    const {loading: loadingActivate, error: errorActivate, execute: executeActivate,} = useActivateOrder();
    const {loading: loadingDelete, error: errorDelete, execute: executeDelete} = useDeleteOrder();
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const {orderId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        reload();
    }, [orderId]);

    function reload() {
        if(orderId && !Number.isNaN(orderId)) {
            execute(Number.parseInt(orderId));
        }
    }

    function downloadDoc(orderId: number, documentId: number) {
        executeContract(orderId, documentId).then(r => {
            window.open(r)
        })
    }

    function executeDeleteClicked() {
        setShowDeleteModal(true)
    }
    function deleteOrder() {
        if(orderId && !Number.isNaN(orderId)) {
            executeDelete(Number.parseInt(orderId))
                .then(res => {
                    notifier.success("deleted");
                    navigate('/orders');
                });
        }
    }

    if(loading) return (<Loading/>);
    if(!data) return (<CenterVerticallyAndHorizontally><div>No data</div></CenterVerticallyAndHorizontally>);

    return (
        <>
            <Container>
                <Row>
                    {data.documents && data.documents.map(d => (
                        <Col>
                            <Button onClick={() => downloadDoc(data.id, d.id)}>{d.name}</Button>
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col>
                        {(loadingActivate || loadingDeactivate) && <Loading/>}
                        {data.active && (<Button disabled={loadingDeactivate} onClick={() => executeDeactivate(data.id,).finally(reload)}>Deactivate</Button>)}
                        {!data.active && (<Button disabled={loadingActivate} onClick={() => executeActivate(data.id,).finally(reload)}>Activate</Button>)}
                        <Button disabled={loadingDelete} variant="warning" onClick={() => executeDeleteClicked()}>Delete</Button>
                        <ConfirmModal
                            show={showDeleteModal}
                            onHide={() => setShowDeleteModal(false)}
                            onConfirm={deleteOrder}
                            title="Delete order"
                            body="Delete order"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={6} className="mb-5">
                        <AdminOrderPreview order={data}/>
                        <Row className="mt-3">
                            <h3 className="mt-5 mb-4">{t('vehicle.tax')}</h3>
                            <ReadMoreTaxTable tax={data.taxResult} maxHeight={200}/>
                        </Row>
                    </Col>
                    <Col md={12} lg={6}>
                        <MyOrderStepper
                        horizontal={false} steps={data.steps} userSteps={data.userSteps} reload={reload} orderId={data.id}
                        filterFirstUserSteps={false} isAdmin={true} isUser={false} isTenant={false} />
                        <Row className="mt-3">
                            <h3 className="mt-2 mb-4">Gehaltsdaten</h3>
                            <ReadMoreUserInfo orderUserInfo={data.orderUserInfo} maxHeight={200}/>
                        </Row>
                    </Col>
                </Row>

            </Container>
        </>
    )
}

function AdminOrderPreview(props: {order: OrderFullDto}) {
    const {t, i18n} = useTranslation();

    function getSavingPercent(): string {
        if (props.order) {
            const saving = calculateSavingsPercent(props.order.leasePackage.grossComparisonCostEur, props.order.taxResult.userPrice)
            return nf(saving, i18n.language, 0) + "%"
        }
        return ""
    }

    function getSavingValue(): string {
        if (props.order) {
            return nf((props.order.leasePackage.grossComparisonCostEur - props.order.taxResult.userPrice), i18n.language, 0) + "€ p.m."
        }
        return ""
    }

    function getTrimLevel(): string {
        if(props.order?.vehicle.trimLevel && props.order.vehicle.trimLevel.trim().length > 0) {
            return `${props.order.vehicle.trimLevel}, `
        }
        return ""
    }

    return (
        <>
            <Row>
                <Col xs={12} >
                    <div>
                        <h1>{props.order.user.lastName}, {props.order.user.firstName}</h1>
                    </div>
                    <h1>{`${props.order.vehicle.brand}, ${props.order.vehicle.carModel}`}</h1>
                    <h3>{getTrimLevel()} {`${props.order.vehicle.maximumPowerKw} KW`}, {t('vehicle.range')}: {`${props.order.vehicle.rangeKm} km`}</h3>  {/*${props.order.rangeKm}*/}
                    <VehicleCarousel pictureUrls={props.order.vehicle.otherPhotos}
                                     mainPhotoUrl={props.order.vehicle.mainPhotoUrl}/>
                    <Container fluid={true}>
                        <Row className="mb-5">
                            <Col xs={6}>
                                <h5>{t('vehicle.yourNetRate')}</h5>
                                <h1 style={{
                                    fontSize: '3.4rem',
                                    fontWeight: '900',
                                    whiteSpace: 'nowrap',
                                    display: 'inline-block',
                                    maxWidth: '100%'
                                }}>{nf(props.order.taxResult.userPrice, i18n.language, 0)} €</h1>
                            </Col>
                            <Col xs={6} className="d-flex align-items-center">
                                <div>
                                    <div className="small">{t('vehicle.privateLeasing')}: {nf(props.order.leasePackage.grossComparisonCostEur, i18n.language, 0)} €
                                    </div>
                                    <div className="small">{t('vehicle.savings')}: {getSavingPercent()}</div>
                                    <div className="small">{t('vehicle.listPrice')}: {nf(props.order.leasePackage.grossRetailPriceEur, i18n.language, 0)} €
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid={true}>
                        <Row className="mt-4">
                            <Col xs={6}>{t('vehicle.duration')}</Col>
                            <Col xs={6}>
                                <div className="d-grid mx-auto">
                                    {/*<Button className="btn-outline-secondary btn-sm"  >*/}
                                    {/*    {selectedPackage.durationMonths} Months*/}
                                    {/*</Button>*/}
                                    <div className="button-outline-like-property btn-sm text-center">
                                        {props.order.leasePackage.durationMonths} {t('vehicle.months')}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col xs={6}>{t('vehicle.kmPerYear')}</Col>
                            <Col xs={6}>
                                <div className="d-grid mx-auto">
                                    <div className="button-outline-like-property btn-sm text-center">
                                        {nf(props.order.leasePackage.kmPerYear, i18n.language, 0)} km
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col xs={6}>{t('vehicle.tires')}</Col>
                            <Col xs={6}>
                                <div className="d-grid mx-auto">
                                    <div className="button-outline-like-property btn-sm text-center">
                                        {props.order.leasePackage.tires}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="mt-2">
                            <Col xs={6}>{t('vehicle.color')}</Col>
                            <Col xs={6}>
                                <div className="d-grid mx-auto">
                                    <div className="button-outline-like-property btn-sm text-center">
                                        {props.order.color}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-2 mb-5">
                            <Col xs={6}>{t('vehicle.delivery')}</Col>
                            <Col xs={6}>
                                <div className="d-grid mx-auto">
                                    <div className="button-outline-like-property btn-sm text-center">
                                        {props.order.delivery}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-5 mb-5">
                            <Col xs={12} className="mt-5 text-center">
                                <small>{t('vehicle.savings')}: <b>{getSavingValue()} ({getSavingPercent()})</b></small>
                            </Col>
                        </Row>

                    </Container>
                </Col>

                {/*<Col xs={7}>*/}
                {/*    <TaxDataTable tax={props.order.taxResult}/>*/}
                {/*    <div>*/}
                {/*        <Button onClick={ok} variant="primary">*/}
                {/*            {t('buttons.continue')}*/}
                {/*        </Button>*/}
                {/*    </div>*/}
                {/*</Col>*/}
            </Row>

        </>
    )
}