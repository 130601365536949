import {Accordion, Button, Col, Row} from "react-bootstrap";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useGetImports} from "../../api/imports/useImports";
import {useGetMultiImports} from "../../api/importsV2/useMultiImports";
import {useDisableMultiImport, useEnableMultiImport} from "../../api/importsV2/useDisableEnableMultiImports";
import {Loading} from "../../components/Loading";
import {ImportHeader} from "../imports/components/ImportHeader";
import {VehicleWithPackages} from "../imports/components/VehicleWithPackages";
import {LeasePackageImportDto, LeasePackageMultiImportDto} from "../../model/Imports";
import TenantLeasePackageImports from "../imports/components/TenantLeasePackageImports";
import {disableImport, enableImport} from "../../api/imports/useDisableEnableImports";
import notifier from "simple-react-notifications2";


export function ImportsV2() {
    const navigate = useNavigate();

    const {loading, data, error, execute} = useGetMultiImports();
    const {loading: loadingEnable, error: errorEnable, execute: executeEnable} = useEnableMultiImport();
    const {loading: loadingDisable, error: errorDisable, execute: executeDisable} = useDisableMultiImport();

    useEffect(() => {
        execute()
    }, []);

    function enable(id: number) {
        executeEnable(id).then(r => {
            execute(); notifier.success('Sucess')
        }).catch(e => {execute(); notifier.error('Error')})
    }

    function disable(id: number) {
        executeDisable(id).then(r => {
            execute(); notifier.success('Sucess')
        }).catch(e => {execute(); notifier.error('Error')})
    }

    if(loading) return (<Loading/>)
    if(!data) return (<>No data</>)

    function getSortedImports() {
        return data.sort((a, b) => b.id - a.id)
    }

    return (
        <>
            <Row>
                <div className="mt-2 mb-5 d-grid gap-2 col-md-8 col-12 mx-auto">
                    <Button className="mb-5" variant="primary" type="submit" onClick={() => navigate('/imports-v2-new')}>
                        New
                    </Button>
                </div>
            </Row>
            <Row>
                <Accordion>
                    {getSortedImports().map((it, index) => (
                        <Accordion.Item key={'Ims' + it.id} eventKey={index.toString()}>
                            <Accordion.Header>
                                <MultiImportHeader import={it}/>
                            </Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    <Col>
                                        {(loadingDisable || loadingEnable) && (<Loading/>)}
                                        <Button className="button-outline" size={"sm"} onClick={() => {
                                            if(it.enabled) {disable(it.id)} else {enable(it.id)}
                                        }}>
                                            {it.enabled ? 'Disable' : 'Enable'}
                                        </Button>
                                    </Col>
                                </Row>
                                <TenantLeasePackageImports imports={it.tenantImports} reload={execute}/>
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </Row>
        </>
    )
}

export function MultiImportHeader(
    props: {import: LeasePackageMultiImportDto}
) {


    return (
        <>
            <Row>
                <Col>{props.import.id}</Col>
                <Col>{props.import.created.toString()}</Col>
                <Col>{props.import.enabled ? 'Active' : 'Disabled'}</Col>
                {(props.import.filename && props.import.filename.trim().length > 0) ? (
                    <Col xs={7}>{props.import.filename}</Col>
                ): (<></>)}
            </Row>
        </>
    )
}