import {customAxios} from "../customAxios";
import {useState} from "react";
import {TenantUserInfoConfigDto} from "../../model/salary/SalaryOptions";

export function useGetTenantUserInfoConfig() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<TenantUserInfoConfigDto>();
    const [error, setError] = useState<Error>();

    async function execute(id: number) {
        try {
            setLoading(true);
            const data = await getTenant(id);
            setData(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading, data, error, execute
    };
}

export async function getTenant(id: number): Promise<TenantUserInfoConfigDto> {
    const response = await customAxios.get<TenantUserInfoConfigDto>(`/v1/tenant/${id}/user-info-config`, );
    return response.data;
}

