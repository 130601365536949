import {useState} from "react";
import {UserDto} from "../../model/salary/UserInfo";
import {customAxios} from "../customAxios";

export function useUserInfo() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<UserDto>();
    const [error, setError] = useState<Error>();

    async function execute() {
        try {
            setLoading(true);
            const data = await getUserInfo();
            setData(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {loading, data, error, execute};
}


export async function getUserInfo(): Promise<UserDto> {
    const response = await customAxios.get<UserDto>(`/v1/user/info`);
    return response.data;
}