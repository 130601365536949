import {useState} from "react";
import {customAxios} from "../customAxios";



export function useStepFunctions() {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    async function execute(orderId: number, stepId: number) {
        try {
            setLoading(true);
            await executeStep(orderId, stepId)
            return;
        }
        catch (e) {
            setError(error);
            // return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    async function accept(orderId: number, stepId: number) {
        try {
            setLoading(true);
            await acceptStep(orderId, stepId)
            return;
        }
        catch (e) {
            setError(error);
            // return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    async function decline(orderId: number, stepId: number) {
        try {
            setLoading(true);
            await declineStep(orderId, stepId)
            return;
        }
        catch (e) {
            setError(error);
            // return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {loading, error, execute, accept, decline};
}

export async function executeStep(orderId: number, stepId: number): Promise<void> {
    const response = await customAxios.put<void>(`/v1/order/${orderId}/step/${stepId}/execute`);
    return response.data;
}

export async function acceptStep(orderId: number, stepId: number): Promise<void> {
    const response = await customAxios.put<void>(`/v1/order/${orderId}/step/${stepId}/accept`);
    return response.data;
}

export async function declineStep(orderId: number, stepId: number): Promise<void> {
    const response = await customAxios.put<void>(`/v1/order/${orderId}/step/${stepId}/decline`);
    return response.data;
}