import {useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {Button, Table} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {useTranslation} from "react-i18next";
import {Loading} from "../../components/Loading";
import {CountersignDto} from "../../model/order/countersign";
import {useGetCountersignList} from "../../api/orders/useGetCountersignList";
import {SelectedTenantProvider, useSelectedTenant} from "../../components/tenant/SelectedTenantContext";
import {SelectTenant} from "../../components/tenant/SelectTenant";

export function TenantOrdersCountersignList() {

    return (
        <SelectedTenantProvider>
            <SelectTenant/>
            <OrdersCountersignList/>
        </SelectedTenantProvider>
    )
}
export default function OrdersCountersignList() {

    const {loading, data, error, execute} = useGetCountersignList();
    const navigate = useNavigate();
    const { selectedTenant, setSelectedTenant } = useSelectedTenant();
    const { t, i18n } = useTranslation();
    const [selected, setSelected] = useState<number[]>([]);
    const indeterminateRef = useRef(null);


    useEffect(() => {
        if(selectedTenant) {
            execute(selectedTenant.id);
        }
    }, [selectedTenant]);

    if(loading) return (<Loading/>)
    if(!data) return (<div>No data</div>)

    function edit(id: number) {
        navigate(`/countersign/${id}/tenant/${selectedTenant?.id}`)
    }

    function sorted(): Array<CountersignDto> {
        if (data) {
            return data.sort((v1: CountersignDto, v2: CountersignDto) => {
                if (v1.id > v2.id) {
                    return -1;
                }
                if (v1.id < v2.id) {
                    return 1;
                }
                return 0;
            });
        }
        return []
    }

    function countersign() {
        navigate(`/tenant/${selectedTenant?.norm}/orders/countersign/new`)
    }

    if(data && data.length === 0) {
        return (
            <>
                <h2>{t('countersign.previousSignings')}</h2>
                {t('countersign.noOldSignings')}
            </>
        )
    }

    return (
        <>
            <Container className="mt-5">
                <h2 className="mt-2 mb-2">{t('countersign.previousSignings')}</h2>
                {/*<Row className="mt-2">*/}
                {/*    <Col className="text-end">*/}
                {/*        <Button className="m-1" variant="primary" onClick={countersign}>*/}
                {/*            /!*{t('user.invitePeople')}*!/*/}
                {/*            New Countersign*/}
                {/*        </Button>*/}
                {/*    </Col>*/}

                {/*</Row>*/}
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Success</th>
                        <th>Finished</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {sorted().map((row) => (
                        <tr key={row.id} >
                            <td>{row.id}</td>
                            <td>{row.success ? 'True' : 'False'}</td>
                            <td>{row.finished ? 'True' : 'False'}</td>
                            <td><Button variant="primary" size="sm" onClick={() => edit(row.id)}>View</Button></td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Container>
        </>
    )
}