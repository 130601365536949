import React from 'react';
import logo from './logo.svg';
import {ThemeProvider} from "react-bootstrap";
import {createBrowserRouter, redirect, RouterProvider} from "react-router-dom";
import {Login} from "./pages/Login";
import {MainLayoutAuthenticated} from "./components/layout/MainLayoutAuthenticated";
import {TenantImports} from "./pages/imports/Imports";
import Settings from "./pages/settings/Settings";
import Vehicles from "./pages/vehicles/Vehicles";
import {TenantOrders} from "./pages/orders/Orders";
import Tenants from "./pages/tenants/Tenants";
import Dashboard from "./pages/dashboard/Dashboard";
import VehicleEdit from "./pages/vehicles/VehicleEdit";
import {TenantUsers} from "./pages/users/Users";
import UserEdit from "./pages/users/UserEdit";
import TenantsEdit from "./pages/tenants/TenantsEdit";
import {TaxTest} from "./pages/test/TaxTest";
import Logout from "./pages/Logout";
import './notifications';
import VehicleOverride from "./pages/vehicles/VehicleOverride";
import Order from "./pages/orders/Order";
import TenantContractConfigEdit from "./pages/tenants/TenantContractConfigEdit";
import {Other} from "./pages/Other";
import OrdersCountersign from "./pages/countersign/OrdersCountersign";
import {TenantOrdersCountersignList} from "./pages/countersign/OrdersCountersignList";
import {TenantDeactivatedUsers} from "./pages/users/DeactivatedUsers";
import TenantUserInfoConfigEdit from "./pages/tenants/TenantUserInfoConfigEdit";
import Suppliers from "./pages/suppliers/Suppliers";
import SupplierEdit from "./pages/suppliers/SupplierEdit";
import SupplierNew from "./pages/suppliers/SupplierNew";
import ImportsV2New from "./pages/importsV2/ImportsV2New";
import {ImportsV2} from "./pages/importsV2/ImportsV2";

function App() {
    const router = createBrowserRouter([
        {
            element: <Login/>,
            path: '/login',
        },
        {
            element: <Logout/>,
            path: '/logout',
        },
        {
            element: <MainLayoutAuthenticated/>,
            children: [
                {
                    path: '/',
                    loader: () => redirect('/dashboard'),
                },
                {
                    path: '/dashboard',
                    element: <Dashboard/>
                },
                {
                    element: <TenantImports/>,
                    path: '/imports',
                },
                {
                    element: <ImportsV2/>,
                    path: '/imports-v2',
                },
                {
                    element: <ImportsV2New/>,
                    path: '/imports-v2-new',
                },
                {
                    element: <TenantUsers/>,
                    path: '/users',
                },
                {
                    element: <TenantDeactivatedUsers/>,
                    path: '/deactivated-users',
                },
                {
                    element: <UserEdit/>,
                    path: '/tenant/:tenantId/users/:userId',
                },
                {
                    element: <Settings/>,
                    path: '/settings',
                },
                {
                    element: <Vehicles/>,
                    path: '/vehicles',
                },
                {
                    element: <VehicleEdit/>,
                    path: '/vehicles/:vehicleId',
                },
                {
                    element: <VehicleOverride/>,
                    path: '/vehicles/:vehicleId/imports/:importId/override',
                },
                {
                    path: '/countersign/:countersignId/tenant/:tenantId',
                    element: <OrdersCountersign/>
                },
                {
                    path: '/countersign',
                    element: <TenantOrdersCountersignList/>
                },
                {
                    element: <TenantOrders/>,
                    path: '/orders',
                },
                {
                    element: <Order/>,
                    path: '/orders/:orderId',
                },
                {
                    element: <Tenants/>,
                    path: '/tenants',
                },
                {
                    element: <TenantsEdit/>,
                    path: '/tenants/:tenantId',
                },
                {
                    element: <TenantContractConfigEdit/>,
                    path: '/tenants/:tenantId/contract-config',
                },
                {
                    element: <TenantUserInfoConfigEdit/>,
                    path: '/tenants/:tenantId/user-info-config',
                },
                {
                    element: <Suppliers/>,
                    path: '/suppliers',
                },
                {
                    element: <SupplierEdit/>,
                    path: '/suppliers/:supplierId',
                },
                {
                    element: <SupplierNew/>,
                    path: '/supplier-new',
                },
                {
                    element: <TaxTest/>,
                    path: '/tax-test',
                },
                {
                    element: <Other/>,
                    path: '/other',
                },
            ]
        }
    ]);


    return (
        <React.StrictMode>
            <ThemeProvider prefixes={{theme: "dark"}}>
                <RouterProvider router={router}/>
            </ThemeProvider>
        </React.StrictMode>
    );
}

export default App;

