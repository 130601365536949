import React from 'react';
import { Formik, Form, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {Form as BootstrapForm, Button, Alert, Spinner} from 'react-bootstrap';
import {useLeasePackagesImport} from "../../api/imports/leasePaackagesImport";
import notifier from "simple-react-notifications2";
import Container from "react-bootstrap/Container";
import {Loading} from "../Loading";

interface FormValues {
    file: File | null;
}

function FileUploadForm(
    props: { reload: () => void, tenantId: number}
)  {

    const {loading, error, execute} = useLeasePackagesImport();


    const initialValues: FormValues = {
        file: null,
    };

    const validationSchema = Yup.object().shape({
        file: Yup.mixed()
            .required("A file is required")
            .test("fileSize", "File is too large", value => {
                return value && (value as File).size <= 10048576; // 10MB limit
            })
            .test("type", "Unsupported file format", (value) => {
                return value && (value as File).name.endsWith('.xlsx');
            }),
    });

    const onSubmit = (
        values: FormValues,
        { setSubmitting }: FormikHelpers<FormValues>
    ) => {
        setSubmitting(true);
        const formData = new FormData();
        formData.append('file', values.file!!);
        execute(formData, props.tenantId)
            .then(res => {
                notifier.success("Success")
                props.reload();
            }).catch(e => notifier.error("Error"))
            .finally(() => setSubmitting(false));
    };

    if(loading) {return (<Loading/>)}

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ setFieldValue }) => (
                <Form>
                    <BootstrapForm.Group>
                        <BootstrapForm.Label>Upload File</BootstrapForm.Label>
                        <BootstrapForm.Control
                            type="file"
                            name="file"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue("file", event.currentTarget.files![0]);
                            }}
                        />
                        <ErrorMessage name="file">
                            {(msg) => <Alert variant="danger">{msg}</Alert>}
                        </ErrorMessage>
                    </BootstrapForm.Group>

                    <div className="mt-5 mb-5 d-grid gap-2 col-md-8 col-12 mx-auto">
                        <Button variant="primary" type="submit">
                            Upload
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default FileUploadForm;
