import React, { useState } from 'react';
import styles from './ReadMore.module.css';
import {useTranslation} from "react-i18next";
import {TaxCalculationResult} from "../../model/order/tax";
import {TaxDataTable} from "./TaxDataTable";

interface ReadMoreTaxTableProps {
    tax: TaxCalculationResult;
    maxHeight: number; // Maximum height in pixels
}

export function ReadMoreTaxTable(props: ReadMoreTaxTableProps){
    const {t, i18n} = useTranslation();

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };


    return (
        <div>
            <div
                className={`${styles.readMoreContent} ${!isExpanded ? styles.collapsed : ''}`}
                style={{ maxHeight: isExpanded ? 'none' : `${props.maxHeight}px`, overflow: "auto" }}
            >
                <TaxDataTable tax={props.tax}/>
            </div>
            <div onClick={toggleExpand} style={{fontWeight: "800", cursor: "pointer"}}>
                {isExpanded ? t('vehicle.readLess') : t('vehicle.readMore')}
            </div>
        </div>
    );
}
