import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import GenericFileUploadForm from "./GenericFileUploadForm";

function GenericFileUploadModal(props: {
    tenantId: number | undefined
    execute: (formData: FormData, tenantId: number) => Promise<void>
    type: string[]
    title: string
    buttonTitle: string
}) {

    const [showModal, setShowModal] = useState(false);
    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);


    if(!props.tenantId) {
        return (<></>)
    }

    return (
        <div>
            <Button variant="primary" onClick={handleShow}>
                {props.buttonTitle}
            </Button>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <GenericFileUploadForm execute={props.execute} type={props.type} tenantId={props.tenantId}></GenericFileUploadForm>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default GenericFileUploadModal;
