import {customAxios} from "../customAxios";
import { useState} from "react";
import { VehicleImportOverride} from "../../model/Vehicle";

export function useEditVehicleOverride() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<VehicleImportOverride>();
    const [error, setError] = useState<Error>();

    async function execute(vehicleId: number, importId: number, dto: VehicleImportOverride) {
        try {
            setLoading(true);
            const data = await editVehicleOverride(vehicleId, importId, dto);
            setData(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading, data, error, execute
    };
}

export async function editVehicleOverride(vehicleId: number, importId: number, dto: VehicleImportOverride): Promise<VehicleImportOverride> {
    const response = await customAxios.post<VehicleImportOverride>(`/v1/vehicle/${vehicleId}/import/${importId}/override`, dto);
    return response.data;
}

