import {useState} from "react";
import {TenantContractConfigDto, TenantDto} from "../../model/TenantDto";
import {customAxios} from "../customAxios";

export function useUpdateTenantContractConfig() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<TenantContractConfigDto>();
    const [error, setError] = useState<Error>();

    async function execute(dto: TenantContractConfigDto, tenantId: number) {
        try {
            setLoading(true);
            const data = await updateTenantContractConfig(dto, tenantId);
            setData(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }


    return {
        loading, data, error, execute
    };
}

export async function updateTenantContractConfig(dto: TenantContractConfigDto, tenantId: number): Promise<TenantContractConfigDto> {
    const response = await customAxios.put<TenantContractConfigDto>(`/v1/tenant/${tenantId}/contract-config`, dto);
    return response.data;
}
