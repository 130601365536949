import {customAxios} from "../customAxios";
import {useState} from "react";
import {LeasePackageImportDto} from "../../model/Imports";
import {UserDto} from "../../model/Users";

export function useGetUser() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<UserDto>();
    const [error, setError] = useState<Error>();

    async function execute(tenantId: number, userId: number) {
        try {
            setLoading(true);
            const data = await getUser(tenantId, userId);
            setData(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading, data, error, execute
    };
}

export async function getUser(tenantId: number, userId: number): Promise<UserDto> {
    const response = await customAxios.get<UserDto>(`/v1/tenant/${tenantId}/users/${userId}`, );
    return response.data;
}

