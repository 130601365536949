import {Button, Form, FormControl, FormGroup, FormLabel, FormSelect, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {useNavigate, useParams} from "react-router-dom";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {Loading} from "../../components/Loading";
import notifier from "simple-react-notifications2";
import {useGetTenantUserInfoConfig} from "../../api/tenants/useTenantUserInffoConfig";
import {useUpdateTenantUserInfoConfig} from "../../api/tenants/useUpdateTenantUserInfoConfig";
import {TenantUserInfoConfigDto} from "../../model/salary/SalaryOptions";


export default function TenantUserInfoConfigEdit() {
    const navigate = useNavigate();

    const {loading, data, error, execute} = useGetTenantUserInfoConfig();

    const {loading: loadingSave, data: dataSave, error: errorSave, execute: executeSave} = useUpdateTenantUserInfoConfig();
    const {tenantId} = useParams();


    useEffect(() => {
        executeGet();
    }, [tenantId]);

    function executeGet() {
        if (tenantId && !Number.isNaN(tenantId)) {
            execute(Number.parseInt(tenantId));
        }
    }

    if(!data) return (<Loading/>);

    const schema = Yup.object().shape({
        arrivalNumberMin: Yup.number().required(),
        arrivalNumberMax: Yup.number().required(),
    });

    function save(dto: TenantUserInfoConfigDto) {
        executeSave(dto, Number.parseInt(tenantId!!)).then(res => {
            notifier.success("Saved")
            executeGet()
        });
    }

    return (
        <>
            <Container>
                <Formik initialValues={data} validationSchema={schema} onSubmit={values => save(values)}>
                    {({handleSubmit, setFieldValue, handleChange, handleBlur, values, touched, errors}) => (
                        <Form onSubmit={handleSubmit}>
                            {/*<Row>{JSON.stringify(values)}</Row>*/}


                            <FormGroup>
                                <FormLabel>arrivalNumberMin</FormLabel>
                                <Field type="number" name="arrivalNumberMin" value={values.arrivalNumberMin} as={Form.Control}
                                       isInvalid={touched.arrivalNumberMin && !!errors.arrivalNumberMin} onChange={handleChange}/>
                                <FormControl.Feedback type="invalid">{errors.arrivalNumberMin}</FormControl.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>arrivalNumberMax</FormLabel>
                                <Field type="number" name="arrivalNumberMax" value={values.arrivalNumberMax} as={Form.Control}
                                       isInvalid={touched.arrivalNumberMax && !!errors.arrivalNumberMax} onChange={handleChange}/>
                                <FormControl.Feedback type="invalid">{errors.arrivalNumberMax}</FormControl.Feedback>
                            </FormGroup>
                            <div className="mt-5 mb-5 d-grid gap-2 col-md-8 col-12 mx-auto">
                                {loadingSave && (<Loading/>)}
                                <Button disabled={loadingSave} variant="primary" type="submit">
                                    Submit
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    )
}
