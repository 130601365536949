import React, {useEffect} from "react";
import Container from "react-bootstrap/Container";
import {useNavigate, useParams} from "react-router-dom";
import * as yup from "yup";
import {Field, Formik} from "formik";
import {Form, FormGroup, FormControl, FormLabel, Button, FormSelect, Row, Col, FormCheck} from "react-bootstrap";
import {useGetUser} from "../../api/users/useUser";
import {UserDto} from "../../model/Users";
import {useSaveUser} from "../../api/users/useSaveUser";
import {useGetRoles} from "../../api/users/useRoles";
import {useDeactivateUser} from "../../api/users/useDeactivateUser";
import notifier from "simple-react-notifications2";
import {Loading} from "../../components/Loading";


export default function UserEdit(
) {
    const navigate = useNavigate()
    const {loading, data, error, execute} = useGetUser();
    const {loading: loadingSave, data: dataSave, error: errorSave, execute: executeSave} = useSaveUser();
    const {loading: loadingDeactivate, error: errorDeactivate, execute: executeDeactivate} = useDeactivateUser();
    const {loading: loadingRoles, data: dataRoles, error: errorRoles, execute: executeRoles} = useGetRoles();
    const {tenantId, userId} = useParams();

    useEffect(() => {
        executeGet();
    }, [userId]);

    useEffect(() => {
        if (tenantId && !Number.isNaN(tenantId) ) {
            executeRoles(Number.parseInt(tenantId));
        }
    }, [tenantId]);

    function executeGet() {
        if (tenantId && !Number.isNaN(tenantId) && userId && !Number.isNaN(userId)) {
            execute(Number.parseInt(tenantId), Number.parseInt(userId));
        }
    }


    const validationSchema = yup.object().shape({
        // model: yup.string().required('Required'),
        // numberOfDoors: yup.number().required('Required').positive().integer(),
        // maximumPowerKw: yup.number().required('Required').positive(),
        // mainPhotoUrl: yup.string().url('Invalid URL').required('Required'),
        // vehicleDetails: yup.object().shape({
            // standardEquipment: yup.string().required('Required'),
            // specialEquipment: yup.string().required('Required'),
            // estimatedDeliveryTime: yup.string().required('Required'),
            // ... more nested validations ...
        // }),
    });

    if (!data) return (<div></div>);

    function save(dto: UserDto) {
        executeSave(Number.parseInt(tenantId!!), dto)//.finally(() =>executeGet())
    }

    function deactivate() {
        executeDeactivate(Number.parseInt(tenantId!!), data?.id!!)
            .then(res => {
                notifier.success("Successfully deactivated")
                navigate("/users")
            })
    }

    return (
        <>
            <Container>
                <Row>
                    <Col>
                        {loadingDeactivate && (<Loading/>)}
                        <Button variant="primary" disabled={loadingDeactivate} onClick={deactivate}>
                            Deactivate
                        </Button>
                    </Col>
                </Row>
                <Formik initialValues={data} validationSchema={validationSchema} onSubmit={values => save(values)}>
                    {({handleSubmit, setFieldValue, handleChange, handleBlur, values, touched, errors}) => (
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <FormLabel className="d-block">Title</FormLabel>
                                <FormCheck
                                    className="form-check-inline"
                                    type="radio"
                                    aria-describedby="inputGroupPrepend"
                                    name="title"
                                    id="titleM"
                                    label={'Mr'}
                                    onChange={handleChange}
                                    value="m"
                                    isInvalid={!!errors.title && touched.title}
                                    as={Field}
                                />
                                <FormCheck
                                    className="form-check-inline"
                                    type="radio"
                                    aria-describedby="inputGroupPrepend"
                                    name="title"
                                    id="titelF"
                                    label={'Ms'}
                                    onChange={handleChange}
                                    value="f"
                                    isInvalid={!!errors.title && touched.title}
                                    as={Field}
                                />
                                <FormControl.Feedback type="invalid">
                                    {errors.title}
                                </FormControl.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>First name</FormLabel>
                                <Field name="firstName" value={values.firstName} as={Form.Control}
                                       isInvalid={touched.firstName && !!errors.firstName}/>
                                <FormControl.Feedback type="invalid">{errors.firstName}</FormControl.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Last name</FormLabel>
                                <Field name="lastName" value={values.lastName} as={Form.Control}
                                       isInvalid={touched.lastName && !!errors.lastName}/>
                                <FormControl.Feedback type="invalid">{errors.lastName}</FormControl.Feedback>
                            </FormGroup>
                            {/*<FormGroup>*/}
                            {/*    <FormLabel>Title</FormLabel>*/}
                            {/*    <Field name="title" value={values.title} as={Form.Control}*/}
                            {/*           isInvalid={touched.title && !!errors.title}/>*/}
                            {/*    <FormControl.Feedback type="invalid">{errors.title}</FormControl.Feedback>*/}
                            {/*</FormGroup>*/}
                            <FormGroup>
                                <FormLabel>Username</FormLabel>
                                <Field name="username" value={values.username} as={Form.Control}
                                       isInvalid={touched.username && !!errors.username}/>
                                <FormControl.Feedback type="invalid">{errors.username}</FormControl.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Email</FormLabel>
                                <Field name="email" value={values.email} as={Form.Control}
                                       isInvalid={touched.email && !!errors.email}/>
                                <FormControl.Feedback type="invalid">{errors.email}</FormControl.Feedback>
                            </FormGroup>
                            {/*<FormGroup>*/}
                            {/*    <FormLabel>Username</FormLabel>*/}
                            {/*    <Field name="username" value={values.username} as={Form.Control}*/}
                            {/*           isInvalid={touched.username && !!errors.username}/>*/}
                            {/*    <FormControl.Feedback type="invalid">{errors.username}</FormControl.Feedback>*/}
                            {/*</FormGroup>*/}
                            <FormGroup>
                                <FormLabel>Select Option</FormLabel>
                                <FormSelect
                                    name="role"
                                    value={values.role}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={!!touched.role && !!errors.role}
                                >
                                    <option value="" label="Select an option" />
                                    {dataRoles.map(role => (
                                        <option key={role.id} value={role.id}>
                                            {role.name}
                                        </option>
                                    ))}
                                </FormSelect>
                                <FormControl.Feedback type="invalid">{errors.role}</FormControl.Feedback>
                            </FormGroup>

                            <div className="mt-5 mb-5 d-grid gap-2 col-md-8 col-12 mx-auto">
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                                {/*<Button variant="primary" onClick={() => handleSubmit()}>*/}
                                {/*    Submit2*/}
                                {/*</Button>*/}
                            </div>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    )
}