import {customAxios} from "../customAxios";
import {useState} from "react";
import {LeasePackageImportDto, LeasePackageMultiImportDto} from "../../model/Imports";

export function useGetMultiImports() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<LeasePackageMultiImportDto[]>([]);
    const [error, setError] = useState<Error>();

    async function execute() {
        try {
            setLoading(true);
            const data = await getMultiImports();
            setData(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading, data, error, execute
    };
}

export async function getMultiImports(): Promise<LeasePackageMultiImportDto[]> {
    const response = await customAxios.get<LeasePackageMultiImportDto[]>(`/v1/lease-packages/import/multi`, );
    return response.data;
}

