import {useState} from "react";
import notifier from "simple-react-notifications2";
import {SupplierDto} from "../../model/supplier/supplier";
import {customAxios} from "../customAxios";

export function useSaveSupplier() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<SupplierDto>();
    const [error, setError] = useState<Error>();

    async function execute(dto: SupplierDto) {
        try {
            setLoading(true);
            if(dto.id > 0) {
                setData(await updateSupplier(dto, dto.id))
            } else {
                setData(await createSupplier(dto))
            }
            return data;
        }
        catch (e) {
            notifier.error((e as Error).message)
            setError(e as Error);
            // return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }


    return {
        loading, data, error, execute
    };
}

export async function updateSupplier(dto: SupplierDto, id: number): Promise<SupplierDto> {
    const response = await customAxios.put<SupplierDto>(`/v1/supplier/${id}`, dto);
    return response.data;
}

export async function createSupplier(dto: SupplierDto): Promise<SupplierDto> {
    const response = await customAxios.post<SupplierDto>('/v1/supplier', dto);
    return response.data;
}