import {Col, Form, FormControl, FormControlProps, FormGroup, FormLabel, Row} from "react-bootstrap";
import {Field, FormikErrors, FormikTouched} from "formik";
import React from "react";
import {useTranslation} from "react-i18next";

interface UserInfoStringFieldProps {
    name: string;
    type: string;
    value: any | undefined;
    onChange: React.ChangeEventHandler<FormControlProps>;
    touched: FormikTouched<{ [key: string]: any }>;
    errors: FormikErrors<{ [key: string]: any }>;
}

export default function UserInfoGenericSplitFormGroup(
    props: UserInfoStringFieldProps
) {
    const {t, i18n} = useTranslation();
    const error = props.errors[props.name];

    return (
        <FormGroup className="mb-3">
            <Row>
                <Col xs={6}>
                    <div
                        style={{display: 'flex', alignItems: 'center', height: "100%"}}>
                        <FormLabel
                            className="form-check-inline">{t(`salary.${props.name}`)}</FormLabel>
                    </div>
                </Col>
                <Col xs={6} className="text-end">
                    <Field
                        type={props.type}
                        name= {props.name}
                        value={props.value}
                        onChange={props.onChange}
                        isInvalid={!!props.touched[props.name] && !!props.errors[props.name]}
                        as={Form.Control}
                    >

                    </Field>
                    {error && (
                        <FormControl.Feedback type="invalid">
                            {error.toString()}
                        </FormControl.Feedback>
                    )}
                </Col>
            </Row>
        </FormGroup>
    )
}