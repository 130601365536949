import {ChildParentTenantDto, TenantContractConfigDto, TenantDto} from "../../model/TenantDto";
import {Button, Form, FormControl, FormGroup, FormLabel, FormSelect, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {useNavigate, useParams} from "react-router-dom";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {Loading} from "../../components/Loading";
import notifier from "simple-react-notifications2";
import {useGetTenantContractConfig} from "../../api/tenants/useTenantContractConfig";
import {useUpdateTenantContractConfig} from "../../api/tenants/useUpdateTenantContractConfig";


export default function TenantContractConfigEdit() {
    const navigate = useNavigate();

    const {loading, data, error, execute} = useGetTenantContractConfig();

    const {loading: loadingSave, data: dataSave, error: errorSave, execute: executeSave} = useUpdateTenantContractConfig();
    const {tenantId} = useParams();


    useEffect(() => {
        executeGet();
    }, [tenantId]);

    function executeGet() {
        if (tenantId && !Number.isNaN(tenantId)) {
            execute(Number.parseInt(tenantId));
        }
    }

    if(!data) return (<Loading/>);

    const schema = Yup.object().shape({
        contractName: Yup.string().required(),
        userSignatureX: Yup.number().required(),
        userSignatureY: Yup.number().required(),
        userSignaturePage: Yup.number().required(),
        adminSignatureX: Yup.number().required(),
        adminSignatureY: Yup.number().required(),
        adminSignaturePage: Yup.number().required(),
    });

    function save(dto: TenantContractConfigDto) {
        executeSave(dto, Number.parseInt(tenantId!!)).then(res => {
            notifier.success("Saved")
            executeGet()
        });
    }

    return (
        <>
            <Container>
                <Formik initialValues={data} validationSchema={schema} onSubmit={values => save(values)}>
                    {({handleSubmit, setFieldValue, handleChange, handleBlur, values, touched, errors}) => (
                        <Form onSubmit={handleSubmit}>
                            {/*<Row>{JSON.stringify(values)}</Row>*/}
                            <FormGroup>
                                <FormLabel>contractName</FormLabel>
                                <Field name="contractName" value={values.contractName} as={Form.Control}
                                       isInvalid={touched.contractName && !!errors.contractName} onChange={handleChange}/>
                                <FormControl.Feedback type="invalid">{errors.contractName}</FormControl.Feedback>
                            </FormGroup>

                            <FormGroup>
                                <FormLabel>userSignatureX</FormLabel>
                                <Field type="number" name="userSignatureX" value={values.userSignatureX} as={Form.Control}
                                       isInvalid={touched.userSignatureX && !!errors.userSignatureX} onChange={handleChange}/>
                                <FormControl.Feedback type="invalid">{errors.userSignatureX}</FormControl.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>userSignatureY</FormLabel>
                                <Field type="number" name="userSignatureY" value={values.userSignatureY} as={Form.Control}
                                       isInvalid={touched.userSignatureY && !!errors.userSignatureY} onChange={handleChange}/>
                                <FormControl.Feedback type="invalid">{errors.userSignatureY}</FormControl.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>userSignaturePage</FormLabel>
                                <Field type="number" name="userSignaturePage" value={values.userSignaturePage} as={Form.Control}
                                       isInvalid={touched.userSignaturePage && !!errors.userSignaturePage} onChange={handleChange}/>
                                <FormControl.Feedback type="invalid">{errors.userSignaturePage}</FormControl.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>adminSignatureX</FormLabel>
                                <Field type="number" name="adminSignatureX" value={values.adminSignatureX} as={Form.Control}
                                       isInvalid={touched.adminSignatureX && !!errors.adminSignatureX} onChange={handleChange}/>
                                <FormControl.Feedback type="invalid">{errors.adminSignatureX}</FormControl.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>adminSignatureY</FormLabel>
                                <Field type="number" name="adminSignatureY" value={values.adminSignatureY} as={Form.Control}
                                       isInvalid={touched.adminSignatureY && !!errors.adminSignatureY} onChange={handleChange}/>
                                <FormControl.Feedback type="invalid">{errors.adminSignatureY}</FormControl.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>adminSignaturePage</FormLabel>
                                <Field type="number" name="adminSignaturePage" value={values.adminSignaturePage} as={Form.Control}
                                       isInvalid={touched.adminSignaturePage && !!errors.adminSignaturePage} onChange={handleChange}/>
                                <FormControl.Feedback type="invalid">{errors.adminSignaturePage}</FormControl.Feedback>
                            </FormGroup>

                            <div className="mt-5 mb-5 d-grid gap-2 col-md-8 col-12 mx-auto">
                                {loadingSave && (<Loading/>)}
                                <Button disabled={loadingSave} variant="primary" type="submit">
                                    Submit
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    )
}
