import React, {useEffect} from "react";
import {Accordion, Button, Col, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {SelectedTenantProvider, useSelectedTenant} from "../../components/tenant/SelectedTenantContext";
import {SelectTenant} from "../../components/tenant/SelectTenant";
import {useGetImports} from "../../api/imports/useImports";
import {LeasePackageImportDto} from "../../model/Imports";
import {useDisableImport, useEnableImport} from "../../api/imports/useDisableEnableImports";
import FileUploadModal from "../../components/imports/FileUploadModal";
import {Loading} from "../../components/Loading";
import notifier from "simple-react-notifications2";
import {useNavigate} from "react-router-dom";
import {useDisableImportPackage, useEnableImportPackage} from "../../api/imports/useDisableEnableImportsPackages";
import {useDisableImportVehicle, useEnableImportVehicle} from "../../api/imports/useDisableEnableImportsVehicles";
import {VehicleWithPackages} from "./components/VehicleWithPackages";
import {ImportHeader} from "./components/ImportHeader";
import TenantLeasePackageImports from "./components/TenantLeasePackageImports";


export function TenantImports() {

    return (
        <SelectedTenantProvider>
            <SelectTenant/>
            <Imports></Imports>
        </SelectedTenantProvider>
    )
}

// type ArrayElementType = LeasePackageImportDto & {
//     button: any;
// };


export default function Imports() {

    const {loading, data, error, execute} = useGetImports();
    const {loading: loadingEnable, error: errorEnable, execute: executeEnable} = useEnableImport();
    const {loading: loadingDisable, error: errorDisable, execute: executeDisable} = useDisableImport();
    const { selectedTenant, setSelectedTenant } = useSelectedTenant();

    useEffect(() => {
        load()
    }, [selectedTenant]);

    function load() {
        if(selectedTenant) {
            execute(selectedTenant.id);
        }
    }
    function enable(id: number) {
        executeEnable(id)
    }

    function disable(id: number) {
        executeDisable(id)
    }

    if(loading) return (<Loading/>)
    if(!data) return (<>No data</>)

    return (
        <>
            <Container>
                <Row>
                    <FileUploadModal reload={load}></FileUploadModal>
                </Row>
                <Row>
                    {loading && (<Loading/>)}
                    <TenantLeasePackageImports imports={data} reload={load}/>
                </Row>

            </Container>
        </>
    )
}



