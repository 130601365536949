import {Button, Col, FormGroup, FormLabel, Row, Table} from "react-bootstrap";
import {Salary} from "./Salary";
import * as yup from "yup";

import {useGetUser} from "../../api/users/useUser";
import {useTaxTest} from "../../api/test/useTaxTest";
import {Form, Formik} from "formik";
import React from "react";
import {TaxCalculationResult, TestTaxDto} from "../../model/tax";
import UserInfoGenericFormGroup from "../../components/form/UserInfoGenericFormGroup";
import Container from "react-bootstrap/Container";
import {AuthData} from "../../model/AuthData";
import UserInfoBooleanRadioFormGroup from "../../components/form/UserInfoBooleanRadioFormGroup";
import TaxTestBooleanRadioFormGroup from "../../components/form/TaxTestBooleanRadioFormGroup";


export function TaxTest() {

    const {loading, data, error, execute} = useTaxTest();

    return (
        <>
            <Container>
            <Row>
                <Col xs={6}><Salary/></Col>
                <Col xs={6}>
                    <TaxTestForm execute={execute}/>
                    {
                        data && (
                            <TaxDataTable tax={data}/>
                        )
                    }
                </Col>
            </Row>
            </Container>
        </>
    )
}

function TaxDataTable(props: {tax: TaxCalculationResult}) {

    return(
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>Property</th>
                <th>Before</th>
                <th>After</th>
                <th>Conversion</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
                <tr>
                    <td>brutto</td><td>{props.tax.before.brutto}</td><td>{props.tax.after.brutto}</td>
                    <td>nonCashBenefit</td><td>{props.tax.salarySacrificeData.nonCashBenefit}</td>
                </tr>
                <tr>
                    <td>netto</td><td>{props.tax.before.netto}</td><td>{props.tax.after.netto}</td>
                    <td>vehicleTaxation</td><td>{props.tax.salarySacrificeData.vehicleTaxation}</td>
                </tr>
                <tr>
                    <td>nettoAfterBenefit</td><td>{props.tax.before.nettoAfterBenefit}</td><td>{props.tax.after.nettoAfterBenefit}</td>
                    <td>commutingTaxation</td><td>{props.tax.salarySacrificeData.commutingTaxation}</td>
                </tr>
                <tr>
                    <td>takeHome</td><td>{props.tax.before.takeHome}</td><td>{props.tax.after.takeHome}</td>
                    <td>employerContribution</td><td>{props.tax.salarySacrificeData.employerContribution}</td>
                </tr>
                <tr>
                    <td>lohnst</td><td>{props.tax.before.lohnst}</td><td>{props.tax.after.lohnst}</td>
                    <td>monthlyNetLeasing</td><td>{props.tax.salarySacrificeData.monthlyNetLeasing}</td>
                </tr>
                <tr>
                    <td>soli</td><td>{props.tax.before.soli}</td><td>{props.tax.after.soli}</td>
                    <td>price</td><td>{props.tax.price}</td>
                </tr>
                <tr>
                    <td>kirchensteuer</td><td>{props.tax.before.kirchensteuer}</td><td>{props.tax.after.kirchensteuer}</td>
                    <td>userPrice</td><td>{props.tax.userPrice}</td>
                </tr>
                <tr>
                    <td>krankenversicherung</td><td>{props.tax.before.krankenversicherung}</td><td>{props.tax.after.krankenversicherung}</td>
                    <td>saving</td><td>{props.tax.saving}</td>
                </tr>
                <tr>
                    <td>pflegeversicherung</td><td>{props.tax.before.pflegeversicherung}</td><td>{props.tax.after.pflegeversicherung}</td>
                </tr>
                <tr>
                    <td>rentenversicherung</td><td>{props.tax.before.rentenversicherung}</td><td>{props.tax.after.rentenversicherung}</td>
                </tr>
                <tr>
                    <td>arbeitslosenversicherung</td><td>{props.tax.before.arbeitslosenversicherung}</td><td>{props.tax.after.arbeitslosenversicherung}</td>
                </tr>
                <tr>
                    <td>arbeitskammerbeitrag</td><td>{props.tax.before.arbeitskammerbeitrag}</td><td>{props.tax.after.arbeitskammerbeitrag}</td>
                </tr>
                <tr>
                    <td>summeSteuer</td><td>{props.tax.before.summeSteuer}</td><td>{props.tax.after.summeSteuer}</td>
                </tr>
                <tr>
                    <td>summeSoz</td><td>{props.tax.before.summeSoz}</td><td>{props.tax.after.summeSoz}</td>
                </tr>
                <tr>
                    <td>arbeitgeberzuschussPKV</td><td>{props.tax.before.arbeitgeberzuschussPKV}</td><td>{props.tax.after.arbeitgeberzuschussPKV}</td>
                </tr>
                <tr>
                    <td>arbeitgeberzuschussPV</td><td>{props.tax.before.arbeitgeberzuschussPV}</td><td>{props.tax.after.arbeitgeberzuschussPV}</td>
                </tr>
            </tbody>
        </Table>
    )
}


function TaxTestForm(
    props: {execute: (dto: TestTaxDto) => Promise<TaxCalculationResult>}
) {

    const initialValues: TestTaxDto = {
        grossPrice: 50000,
        monthlyNet: 480,
        monthlyGrossCompareLeasing: 550,
        contribution: 10,
        motor: 'Elektro',
        useGrossLeasing: false
    };

    const validationSchema = yup.object().shape({
        grossPrice: yup.number().required('Gross price is required'),
        monthlyNet: yup.number().required('Monthly net is required'),
        monthlyGrossCompareLeasing: yup.number().required('Monthly net is required'),
        contribution: yup.number().required('Contribution is required'),
        motor: yup.string().required('Motor is required').oneOf(['Verbrenner', 'Hybrid', 'Elektro']),
        useGrossLeasing: yup.boolean().required('Motor is required'),
    });

    const handleSubmit = (values: TestTaxDto) => {
        props.execute(values)
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({handleSubmit, setFieldValue, handleChange, handleBlur, values, touched, errors}) => (
                <Form>
                    <UserInfoGenericFormGroup type={"number"} name={"grossPrice"} value={values.grossPrice} onChange={handleChange} touched={touched} errors={errors}/>
                    <UserInfoGenericFormGroup type={"number"} name={"monthlyNet"} value={values.monthlyNet} onChange={handleChange} touched={touched} errors={errors}/>
                    <UserInfoGenericFormGroup type={"number"} name={"monthlyGrossCompareLeasing"} value={values.monthlyGrossCompareLeasing} onChange={handleChange} touched={touched} errors={errors}/>
                    <UserInfoGenericFormGroup type={"number"} name={"contribution"} value={values.contribution} onChange={handleChange} touched={touched} errors={errors}/>
                    <div>'Verbrenner', 'Hybrid', 'Elektro'</div>
                    <UserInfoGenericFormGroup type={"string"} name={"motor"} value={values.motor} onChange={handleChange} touched={touched} errors={errors}/>
                    <TaxTestBooleanRadioFormGroup name={"useGrossLeasing"} value={values.useGrossLeasing} setFieldValue={setFieldValue} touched={touched} errors={errors}/>
                    <Button type="submit">Submit</Button>
                </Form>
            )}
        </Formik>
    );
}