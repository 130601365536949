import {useState} from "react";


export function useTheme() {
    const [theme, setTheme] = useState<string>('light');

    function toggleTheme() {
        if(theme === 'light') {
            setTheme('dark')
        } else {
            setTheme('light')
        }
    }


    return {
        theme, toggleTheme
    };
}