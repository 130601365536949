import axios from 'axios';
import {AuthData} from "../model/AuthData";
import {refresh} from "./refresh";

let isRefreshing = false;
let failedQueue: { resolve: (value?: unknown) => void, reject: (reason?: any) => void }[] = [];

const processQueue = (error: any, token: string | null) => {
    failedQueue.forEach(prom => {
        if (error) prom.reject(error);
        else prom.resolve(token);
    });

    failedQueue = [];
};

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: {'Content-Type' : 'application/json'}
});

axiosInstance.interceptors.request.use(
    function (config) {
        const authDataString = localStorage.getItem('authData');
        if (authDataString) {
            config.headers['Authorization'] = 'Bearer ' + (JSON.parse(authDataString) as AuthData).accessToken;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry && localStorage.getItem('authData')) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({resolve, reject})
                }).then(() => {
                    const authDataString = localStorage.getItem('authData') as string;
                    originalRequest.headers['Authorization'] = 'Bearer ' + (JSON.parse(authDataString) as AuthData).accessToken;
                    return axios(originalRequest);
                }).catch(err => {
                    return Promise.reject(err);
                })
            }

            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise(((resolve, reject) => {
                refresh((JSON.parse(localStorage.getItem('authData') as string) as AuthData).refreshToken)
                    .then(authData => {
                        if(!authData.authenticated) {
                            localStorage.removeItem('authData');
                            document.location.href = '/login';
                            processQueue("Error refreshing", null);
                            reject("Error refreshing");
                        } else {
                            localStorage.setItem('authData', JSON.stringify(authData));
                            originalRequest.headers['Authorization'] = 'Bearer ' + authData.accessToken;
                            // @ts-ignore
                            processQueue(null, authData.accessToken);
                            resolve(axios(originalRequest));
                        }
                    })
                    .catch((error: any) => {
                        localStorage.removeItem('authData');
                        document.location.href = '/login';
                        processQueue(error, null);
                        reject(error);
                    })
                    .then(() => {
                        isRefreshing = false;
                    });
            }));
        }
        if (error.response && error.response.status === 403) {
            /* ako se nekako user uspio zavuc u stranicu a nema token, api na upit koji nema bearer token vraca 403 */
            document.location.href = '/login';
        }

        return Promise.reject(error);
    }
);

export {axiosInstance as customAxios};