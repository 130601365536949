import {TenantDto} from "../../model/TenantDto";
import {customAxios} from "../customAxios";
import {useEffect, useState} from "react";

export function useGetTenants() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<TenantDto[]>([]);
    const [error, setError] = useState<Error>();

    useEffect(() => {
        execute();
    }, []);
    async function execute() {
        try {
            setLoading(true);
            const data = await getTenants();
            setData(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading, data, error, execute
    };
}

export async function getTenants(): Promise<TenantDto[]> {
    const response = await customAxios.get<TenantDto[]>('/v1/tenant', );
    return response.data;
}

