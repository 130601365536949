import {useState} from "react";
import {TenantDto} from "../../model/TenantDto";
import {customAxios} from "../customAxios";

export function useCreateTenant() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<TenantDto>();
    const [error, setError] = useState<Error>();

    async function execute(dto: TenantDto) {
        try {
            setLoading(true);
            const data = await createTenant(dto);
            setData(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }


    return {
        loading, data, error, execute
    };
}

export async function createTenant(dto: TenantDto): Promise<TenantDto> {
    const response = await customAxios.post<TenantDto>('/v1/tenant', dto);
    return response.data;
}
