import React from "react";
import {Carousel} from "react-bootstrap";


export default function VehicleCarousel(props: {
    pictureUrls: string[],
    mainPhotoUrl: string | undefined,
}) {

    function getUrls(): string[] {
        // todo default
        if(props.mainPhotoUrl) {
            return [props.mainPhotoUrl, ...props.pictureUrls]
        }
        if(props.pictureUrls.length === 0) {
            // return ["https://www.jaguarnewportbeach.com/wp-content/plugins/dealerinspire-jaguar-oem-plugin/assets/images/jaguar-suvs-placeholder.jpg"];
            return ["/lightning400.png"];
        }
        return props.pictureUrls
    }

    return (
        <Carousel style={{ maxHeight: '300px' }}>
            {getUrls().map((url, index) => (
                <Carousel.Item key={index}>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <img
                            className="d-block"
                            style={{maxHeight: '300px'}}
                            src={url}
                            alt={`Slide ${index}`}
                        />
                    </div>

                </Carousel.Item>
            ))}
        </Carousel>
    );

}