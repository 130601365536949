import {customAxios} from "../customAxios";
import {useState} from "react";
import {SupplierDto} from "../../model/supplier/supplier";

export function useGetSupplier() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<SupplierDto>();
    const [error, setError] = useState<Error>();

    async function execute(id: number) {
        try {
            setLoading(true);
            const data = await getSupplier(id);
            setData(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading, data, error, execute
    };
}

export async function getSupplier(id: number): Promise<SupplierDto> {
    const response = await customAxios.get<SupplierDto>(`/v1/supplier/${id}`, );
    return response.data;
}

