import {useState} from "react";
import {OrderFullDto} from "../../model/order/orderFullDto";
import {OrderWorkflowStep, OrderWorkflowUserStep} from "../../model/order/orderWorkflow";
import notifier from "simple-react-notifications2";
import {customAxios} from "../customAxios";

export function useOrders() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<OrderFullDto[]>();
    const [error, setError] = useState<Error>();

    async function execute(tenantId: number) {
        try {
            setLoading(true);
            const fetched = await getOrders(tenantId);
            fetched.forEach(f => findUserStepsForSteps(f.steps, f.userSteps))
            setData(fetched);
            return fetched;
        }
        catch (e) {
            setError(e as Error);
            notifier.error((e as Error).message)
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {loading, data, error, execute};
}


export function findUserStepsForSteps(steps: OrderWorkflowStep[], userSteps: OrderWorkflowUserStep[]) {
    const resultsByStepId = userSteps.reduce((acc, result) => {
        const existingResult = acc[result.stepId];
        if (!existingResult || existingResult.modified < result.modified) {
            acc[result.stepId] = result;
        }
        return acc;
    }, {} as Record<number, OrderWorkflowUserStep>);
    steps.forEach(step => {
        step.status = resultsByStepId[step.id]
    });
    steps.sort((a, b) => a.id - b.id);
}

export async function getOrders(tenantId: number): Promise<OrderFullDto[]> {
    const response = await customAxios.get<OrderFullDto[]>(`/v1/order/tenant/${tenantId}`);
    return response.data;
}