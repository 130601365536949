import React from "react";
import {DropdownButton, DropdownItem} from "react-bootstrap";
import {useSelectedTenant} from "./SelectedTenantContext";
import {TenantDto} from "../../model/TenantDto";
import {useGetTenants} from "../../api/tenants/useTenants";


export const SelectTenant: React.FC = () => {
    const { selectedTenant, setSelectedTenant } = useSelectedTenant();
    const {loading, data, error, execute} = useGetTenants();

    const handleSelect = (eventKey: string | undefined | null) => {
        if(eventKey && !Number.isNaN(eventKey)) {
            const id = Number.parseInt(eventKey)
            setSelectedTenant(data.find(t => t.id === id));
        }
    };

    if(loading || !data) {
        return (<div>Loading</div>)
    }


    return (
        <div className="d-flex justify-content-end">
            <DropdownButton
                id="dropdown-basic-button"
                title={selectedTenant?.title || "Select tenant"}
                onSelect={handleSelect}
            >
                {/*<option value={undefined} label="Select tenant"/>*/}
                {data.map((t, i) => {
                    return (
                        <DropdownItem key={i} eventKey={t.id} >{t.title}</DropdownItem>
                    )
                })}
            </DropdownButton>
        </div>
    );
};