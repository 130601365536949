import {customAxios} from "../customAxios";
import {useEffect, useState} from "react";
import {AdminVehicleFullDto, VehicleSimpleDto} from "../../model/Vehicle";
import {updateTenant} from "../tenants/useUpdateTenant";
import {createTenant} from "../tenants/useCreateTenant";
import {TenantDto} from "../../model/TenantDto";

export function useSaveVehicle() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<AdminVehicleFullDto>();
    const [error, setError] = useState<Error>();

    async function execute(dto: AdminVehicleFullDto) {
        try {
            setLoading(true);
            if(dto.id > 0) {
                setData(await updateVehicle(dto, dto.id))
            } else {
                setData(await createVehicle(dto))
            }
            return data;
        }
        catch (e) {
            setError(error);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading, data, error, execute
    };
}

export async function createVehicle(dto: AdminVehicleFullDto): Promise<AdminVehicleFullDto> {
    const response = await customAxios.post<AdminVehicleFullDto>('/v1/vehicle/admin', dto);
    return response.data;
}

export async function updateVehicle(dto: AdminVehicleFullDto, id: number): Promise<AdminVehicleFullDto> {
    const response = await customAxios.put<AdminVehicleFullDto>(`/v1/vehicle/admin/${id}`, dto);
    return response.data;
}
