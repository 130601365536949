import {customAxios} from "../customAxios";
import {useState} from "react";


export function useLeasePackagesImport() {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    async function execute(formData: FormData, tenantId: number) {
        try {
            setLoading(true);
            const data = await leasePackagesImport(formData, tenantId);
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }


    return {
        loading, error, execute
    };
}

async function leasePackagesImport(formData: FormData, tenantId: number): Promise<void> {
    const response = await customAxios.post(`/v1/lease-packages/import/tenant/${tenantId}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}
