import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import {useTranslation} from "react-i18next";

interface ConfirmModalProps {
    show: boolean;
    onHide: () => void;
    onConfirm: () => void;
    title?: string;
    body?: string;
}
export function ConfirmModal(props: ConfirmModalProps) {
    const {t, i18n} = useTranslation();


    return (
        <Modal show={props.show} onHide={props.onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>{props.title || 'Are You Sure?'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.body || 'Do you want to proceed with this action?'}</Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={props.onHide}>
                    {t('main.cancel')}
                </Button>
                <Button variant="primary" onClick={props.onConfirm}>
                    {t('main.confirm')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmModal;
