import {useGetTenants} from "../../api/tenants/useTenants";
import {DatatableWrapper, TableBody, TableColumnType, TableHeader} from "react-bs-datatable";
import {TenantDto} from "../../model/TenantDto";
import {Button, Row, Table} from "react-bootstrap";
import React, {useState} from "react";
import Container from "react-bootstrap/Container";
import TenantModal from "../../components/tenant/TenantUploadModal";
import {useSaveTenant} from "../../api/tenants/useSaveTenant";
import {useNavigate} from "react-router-dom";

type ArrayElementType = TenantDto & {
    button: any;
};
export default function Tenants() {
    const navigate = useNavigate();

    const {loading, data, error, execute} = useGetTenants();
    const {loading: loadingSave, data: dataSave, error: errorSave, execute: executeSave} = useSaveTenant();
    const [showModal, setShowModal] = useState(false);

    const header: TableColumnType<ArrayElementType>[] = [
        {title: 'Id', prop: 'id', isSortable: true, isFilterable: true},
        {title: 'Name', prop: 'title', isSortable: true, isFilterable: true},
        {title: 'Contribution', prop: 'employerContribution', isSortable: true, isFilterable: true},
        {title: 'Signup code', prop: 'signupCode', isSortable: true, isFilterable: true},
        {
            prop: "button",
            cell: (row) => (
                <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => {
                        edit(row);
                    }}
                >
                    Edit
                </Button>
            )
        }
    ];

    function edit(row: TenantDto) {
        navigate(`/tenants/${row.id}`)
    }

    function rowClick(row: TenantDto) {
        console.log(row)
    }

    function newTenant() {
        setShowModal(true);
    }

    return (
        <>
            <Container>
                <Row className="mt-5 mb-5">
                    <Button variant="primary" onClick={() => newTenant()}>Create Tenant</Button>
                    <TenantModal
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        submitCallback={(newTenant) => {
                            executeSave(newTenant).finally(() => {
                                setShowModal(false);
                                execute();
                            })
                        }}
                    />
                </Row>
                <Row className="mt-5 mb-5">
                    <DatatableWrapper
                        body={data as ArrayElementType[]}
                        headers={header}
                    >
                        <Table className="custom-table">
                            <TableHeader/>
                            <TableBody
                                onRowClick={(row, event) => rowClick(row as TenantDto)}
                            />
                        </Table>
                    </DatatableWrapper>
                </Row>
            </Container>
        </>
    )
}