import React, {useEffect, useState} from 'react';
import {Modal, Button, Form, Row, Col, FormLabel, FormSelect, FormControl, FormGroup} from 'react-bootstrap';
import {BagX, Icon123, X} from "react-bootstrap-icons";
import {useGetRoles} from "../../api/users/useRoles";
import {useInvite} from "../../api/users/useInvite";

export function InviteModal(props: {
    tenantId: number | undefined,
    callback: () => void
}) {
    const [show, setShow] = useState(false);
    const [roleId, setRoleId] = useState<number>();
    const [emails, setEmails] = useState<string[]>([]);
    const [emailInput, setEmailInput] = useState<string>('');
    const {loading: loadingRoles, data: dataRoles, error: errorRoles, execute: executeRoles} = useGetRoles();
    const {loading: loadingInvite, error: errorInvite, execute: executeInvite} = useInvite();

    useEffect(() => {
        if (props.tenantId) {
            executeRoles(props.tenantId);
        }
    }, [props.tenantId]);


    if(!props.tenantId) {
        return (<></>)
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailInput(e.target.value);
    };

    function handleAddEmail() {
        if (emailInput && !emails.includes(emailInput)) {
            setEmails([...emails, emailInput]);
            setEmailInput('');
        }
    }

    function remove(email: string) {
        setEmails(emails.filter(item => item !== email));
    }

    function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
        if(!Number.isNaN(event.target.value)) {
            setRoleId(Number.parseInt(event.target.value))
        }
    }

    function invite() {
        executeInvite(props.tenantId!!, {roleId: roleId!!, emails: emails}).then(() => {
                props.callback();
                handleClose();
        })
    }

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Invite People
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Send Invites</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <FormGroup>
                            <FormLabel>Select Role</FormLabel>
                            <FormSelect
                                name="role"
                                value={roleId}
                                onChange={handleChange}
                            >
                                <option value="" label="Select role" />
                                {dataRoles.map(role => (
                                    <option key={role.id} value={role.id}>
                                        {role.name}
                                    </option>
                                ))}
                            </FormSelect>
                        </FormGroup>
                        <Form.Group className="mb-3">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                value={emailInput}
                                onChange={handleEmailChange}
                            />
                            <Button variant="secondary" onClick={handleAddEmail} className="mt-2">
                                Add Email
                            </Button>
                        </Form.Group>

                        {emails.map((email, index) => (
                            <Row key={index}>
                                <Col >{email}</Col>
                                <Col><a><X onClick={() => remove(email)}/></a></Col>
                            </Row>
                        ))}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={invite}>
                        Send Invites
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default InviteModal;
