import {useGetImports} from "../../../api/imports/useImports";
import {useDisableImport, useEnableImport} from "../../../api/imports/useDisableEnableImports";
import React from "react";
import Container from "react-bootstrap/Container";
import {Accordion, Row} from "react-bootstrap";
import {ImportHeader} from "./ImportHeader";
import {VehicleWithPackages} from "./VehicleWithPackages";
import {LeasePackageImportDto} from "../../../model/Imports";


export default function TenantLeasePackageImports(props: { imports: LeasePackageImportDto[], reload: () => void }) {

    const {loading: loadingEnable, error: errorEnable, execute: executeEnable} = useEnableImport();
    const {loading: loadingDisable, error: errorDisable, execute: executeDisable} = useDisableImport();

    function enable(id: number) {
        executeEnable(id)
    }

    function disable(id: number) {
        executeDisable(id)
    }

    function getSortedImports() {
        return props.imports.sort((a, b) => b.id - a.id)
    }

    return (
        <>
            <Row>
                <Accordion>
                    {getSortedImports().map((it, index) => (
                        <Accordion.Item key={'Ims' + it.id} eventKey={index.toString()}>
                            <Accordion.Header><ImportHeader import={it}/></Accordion.Header>
                            <Accordion.Body>
                                <VehicleWithPackages import={it} reload={props.reload}/>
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </Row>
        </>
    )
}

