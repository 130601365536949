import {LeasePackageImportDto} from "../../../model/Imports";
import {Col, Row} from "react-bootstrap";
import React from "react";


export function ImportHeader(
    props: {import: LeasePackageImportDto}
) {

    return (
        <>
            <Row>
                <Col>{props.import.tenant.title}</Col>
                <Col>{props.import.id}</Col>
                <Col xs={2}>{props.import.created.toString()}</Col>
                <Col>{props.import.enabled ? 'Active' : 'Disabled'}</Col>
                {(props.import.filename && props.import.filename.trim().length > 0) ? (
                    <Col xs={6}>{props.import.filename}</Col>
                ): (<></>)}
                {/*<Col>*/}
                {/*    <div className="button-outline-like-property" */}
                {/*             onClick={() => {*/}
                {/*                 if(props.import.enabled) {disable(props.import.id)} else {enable(props.import.id)}*/}
                {/*             }}>*/}
                {/*    {props.import.enabled ? 'Disable' : 'Enable'}*/}
                {/*    </div>*/}
                {/*</Col>*/}
            </Row>
        </>
    )
}