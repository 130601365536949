
export function parseBoolean(str: string | undefined): boolean {
    if(!str) return false;
    return str.toLowerCase() === 'true';
}

export function parseNumber(str: string | undefined): number | undefined {
    if(!str) return undefined;
    if(Number.isNaN(str)) return undefined
    return Number.parseInt(str)
}