import {useEffect} from "react";
import {logout} from "../api/useAuthenticate";
import {useNavigate} from "react-router-dom";
import notifier from "simple-react-notifications2";


export default function Logout() {

    const navigate = useNavigate();

    useEffect(() => {
        logout();
        notifier.success("Successfully logged out")
        navigate("/")
    }, []);

    return (
        <>
            Successfully logged out
        </>
    )
}