import React from 'react';
import {CenterHorizontally} from "../CenterHorizontally/CenterHorizontally";
import {CenterVertically} from "../CenterVertically/CenterVertically";

interface CenterVerticallyAndHorizontally {
    gap?: string;
    children: React.ReactNode;
}

export function CenterVerticallyAndHorizontally(props: CenterVerticallyAndHorizontally) {
    return (
        <CenterVertically gap={props.gap}>
            <CenterHorizontally gap={props.gap}>
                {props.children}
            </CenterHorizontally>
        </CenterVertically>
    );
}