import {TaxCalculationResult} from "../../model/order/tax";
import {useTranslation} from "react-i18next";
import {Table} from "react-bootstrap";
import {nf} from "../../util/numberFormat";
import {calculateSavingsPercent} from "../../util/saving";


export function TaxDataTable(props: { tax: TaxCalculationResult }) {
    const {t, i18n} = useTranslation();

    return (
        // <Table striped bordered hover>
        <Table bordered={true} responsive={true} hover>
            <thead>
            <tr>
                <th></th>
                <th className="text-end">{t('confirmation.column1')}</th>
                <th className="text-end">{t('confirmation.column2')}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td style={{backgroundColor: '#d9d9d9'}}>{t('confirmation.line1')}</td>
                <td className="text-end"
                    style={{backgroundColor: '#d9d9d9'}}>{nf((props.tax.before.brutto), i18n.language, 2, 2)} EUR
                </td>
                <td className="text-end"
                    style={{backgroundColor: '#d9d9d9'}}>{nf((props.tax.after.brutto), i18n.language, 2, 2)} EUR
                </td>
            </tr>
            <tr>
                <td>{t('confirmation.line2')}</td>
                <td className="text-end">{nf(0, i18n.language, 2, 2)} EUR</td>
                <td className="text-end">-{nf((props.tax.salarySacrificeData.monthlyNetLeasing), i18n.language, 2, 2)} EUR</td>
            </tr>
            <tr>
                <td>{t('confirmation.line3')}</td>
                <td className="text-end">{nf(0, i18n.language, 2, 2)} EUR</td>
                <td className="text-end">{nf((props.tax.salarySacrificeData.vehicleTaxation), i18n.language, 2, 2)} EUR</td>
            </tr>
            <tr>
                <td>{t('confirmation.line4')}</td>
                <td className="text-end">{nf(0, i18n.language, 2, 2)} EUR</td>
                <td className="text-end">{nf((props.tax.salarySacrificeData.commutingTaxation), i18n.language, 2, 2)} EUR</td>
            </tr>
            <tr>
                <td>{t('confirmation.line5')}</td>
                <td className="text-end">{nf(0, i18n.language, 2, 2)} EUR</td>
                <td className="text-end">{nf((props.tax.salarySacrificeData.employerContribution), i18n.language, 2, 2)} EUR</td>
            </tr>
            <tr>
                <td style={{backgroundColor: '#d9d9d9'}}>{t('confirmation.line6')}</td>
                <td className="text-end"
                    style={{backgroundColor: '#d9d9d9'}}>{nf((props.tax.before.brutto), i18n.language, 2, 2)} EUR
                </td>
                <td className="text-end"
                    style={{backgroundColor: '#d9d9d9'}}>{nf((props.tax.after.brutto), i18n.language, 2, 2)} EUR
                </td>
            </tr>
            <tr>
                <td>{t('confirmation.line7')}</td>
                <td className="text-end">{nf((props.tax.before.lohnst), i18n.language, 2, 2)} EUR</td>
                <td className="text-end">{nf((props.tax.after.lohnst), i18n.language, 2, 2)} EUR</td>
            </tr>
            <tr>
                <td>{t('confirmation.line8')}</td>
                <td className="text-end">{nf((props.tax.before.soli), i18n.language, 2, 2)} EUR</td>
                <td className="text-end">{nf((props.tax.after.soli), i18n.language, 2, 2)} EUR</td>
            </tr>
            <tr>
                <td>{t('confirmation.line9')}</td>
                <td className="text-end">{nf((props.tax.before.kirchensteuer), i18n.language, 2, 2)} EUR</td>
                <td className="text-end">{nf((props.tax.after.kirchensteuer), i18n.language, 2, 2)} EUR</td>
            </tr>
            <tr>
                <td>{t('confirmation.line10')}</td>
                <td className="text-end">{nf((props.tax.before.krankenversicherung), i18n.language, 2, 2)} EUR</td>
                <td className="text-end">{nf((props.tax.after.krankenversicherung), i18n.language, 2, 2)} EUR</td>
            </tr>
            <tr>
                <td>{t('confirmation.line11')}</td>
                <td className="text-end">{nf((props.tax.before.pflegeversicherung), i18n.language, 2, 2)} EUR</td>
                <td className="text-end">{nf((props.tax.after.pflegeversicherung), i18n.language, 2, 2)} EUR</td>
            </tr>
            <tr>
                <td>{t('confirmation.line12')}</td>
                <td className="text-end">{nf((props.tax.before.rentenversicherung), i18n.language, 2, 2)} EUR</td>
                <td className="text-end">{nf((props.tax.after.rentenversicherung), i18n.language, 2, 2)} EUR</td>
            </tr>
            <tr>
                <td>{t('confirmation.line13')}</td>
                <td className="text-end">{nf((props.tax.before.arbeitslosenversicherung), i18n.language, 2, 2)} EUR</td>
                <td className="text-end">{nf((props.tax.after.arbeitslosenversicherung), i18n.language, 2, 2)} EUR</td>
            </tr>
            <tr>
                <td>{t('confirmation.line14')}</td>
                <td className="text-end">{nf((props.tax.before.arbeitskammerbeitrag), i18n.language, 2, 2)} EUR</td>
                <td className="text-end">{nf((props.tax.after.arbeitskammerbeitrag), i18n.language, 2, 2)} EUR</td>
            </tr>
            {/*<tr>*/}
            {/*    <td>summeSteuer</td><td>{props.tax.before.summeSteuer}</td><td>{props.tax.after.summeSteuer}</td>*/}
            {/*</tr>*/}
            {/*<tr>*/}
            {/*    <td>summeSoz</td><td>{props.tax.before.summeSoz}</td><td>{props.tax.after.summeSoz}</td>*/}
            {/*</tr>*/}
            <tr>
                <td>{t('confirmation.line15')}</td>
                <td className="text-end">{nf((props.tax.before.arbeitgeberzuschussPKV), i18n.language, 2, 2)} EUR</td>
                <td className="text-end">{nf((props.tax.after.arbeitgeberzuschussPKV), i18n.language, 2, 2)} EUR</td>
            </tr>
            {/*<tr>*/}
            {/*    <td>arbeitgeberzuschussPV</td><td>{props.tax.before.arbeitgeberzuschussPV}</td><td>{props.tax.after.arbeitgeberzuschussPV}</td>*/}
            {/*</tr>*/}
            <tr>
                <td>{t('confirmation.line16')}</td>
                <td></td>
                <td className="text-end">{nf(-(props.tax.salarySacrificeData.commutingTaxation + props.tax.salarySacrificeData.vehicleTaxation), i18n.language, 2, 2)} EUR</td>
            </tr>
            <tr>
                <td style={{backgroundColor: '#d9d9d9'}}>{t('confirmation.line17')}</td>
                <td className="text-end"
                    style={{backgroundColor: '#d9d9d9'}}>{nf((props.tax.before.takeHome), i18n.language, 2, 2)} EUR
                </td>
                <td className="text-end"
                    style={{backgroundColor: '#d9d9d9'}}>{nf((props.tax.after.takeHome), i18n.language, 2, 2)} EUR
                </td>
            </tr>
            <tr>
                <td>{t('confirmation.line18')}</td>
                <td></td>
                <td className="text-end">{nf((props.tax.userPrice), i18n.language, 2, 2)} EUR</td>
            </tr>
            <tr>
                <td>{t('confirmation.line19')}</td>
                <td className="text-end">{nf((props.tax.price), i18n.language, 2, 2)} EUR</td>
                <td></td>
            </tr>
            <tr>
                <td style={{backgroundColor: '#d9d9d9'}}>{t('confirmation.line20')}</td>
                <td className="text-end"
                    style={{backgroundColor: '#d9d9d9'}}>{nf((props.tax.before.takeHome - props.tax.price), i18n.language, 2, 2)} EUR
                </td>
                <td className="text-end"
                    style={{backgroundColor: '#d9d9d9'}}>{nf((props.tax.after.nettoAfterBenefit), i18n.language, 2, 2)} EUR
                </td>
            </tr>
            <tr>
                <td>{t('confirmation.line21')}</td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>{t('confirmation.line22')}</td>
                <td></td>
                <td className="text-end">{nf((props.tax.price - props.tax.userPrice), i18n.language, 2, 2)} EUR</td>
            </tr>
            <tr>
                <td>{t('confirmation.line23')}</td>
                <td></td>
                <td className="text-end">{nf(((props.tax.price - props.tax.userPrice) * 12), i18n.language, 2, 2)} EUR</td>
            </tr>
            <tr>
                <td>{t('confirmation.line24')}</td>
                <td></td>
                <td className="text-end">{nf((calculateSavingsPercent(props.tax.price, props.tax.userPrice)), i18n.language, 2, 2)}%</td>
            </tr>

            </tbody>
        </Table>
    )
}