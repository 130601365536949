import {customAxios} from "../customAxios";
import {useState} from "react";

export function useEnableMultiImport() {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    async function execute(importId: number) {
        try {
            setLoading(true);
            await enableMultiImport(importId);
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading, error, execute
    };
}

export async function enableMultiImport(importId: number): Promise<void> {
    const response = await customAxios.put<void>(`/v1/lease-packages/import/multi/${importId}`, );
    return response.data;
}

export function useDisableMultiImport() {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    async function execute(importId: number) {
        try {
            setLoading(true);
            await disableMultiImport(importId);
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading, error, execute
    };
}

export async function disableMultiImport(importId: number): Promise<void> {
    const response = await customAxios.delete<void>(`/v1/lease-packages/import/multi/${importId}`, );
    return response.data;
}

