import React from 'react';
import {Form, Formik} from "formik";
import * as Yup from 'yup';
import {useNavigate, useSearchParams} from "react-router-dom";
import {useAuthenticate} from "../api/useAuthenticate";
import Container from "react-bootstrap/Container";
import {Button, FormControl, FormGroup, FormLabel, FormText} from "react-bootstrap";
import {
    CenterVerticallyAndHorizontally
} from "../components/layout/CenterVerticallAndHorizontally/CenterVerticallyAndHorizontally";
import InputGroup from "react-bootstrap/InputGroup";
import {callbackName} from "../components/Authenticated/Authenticated";

export function Login() {
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();

    const {loading, data, error, execute} = useAuthenticate();

    function navigateTo(): string {
        const callback = searchParams.get(callbackName);
        if(callback) return callback;
        return '/'
    }

    return (
        <Container className="vh-100 mt-5">
            <CenterVerticallyAndHorizontally>
            <Formik
                initialValues={initValues}
                validationSchema={schema}
                onSubmit={(values, actions) => {
                    execute(values.username, values.password)
                        .then(authData => {
                            if (authData.authenticated) {
                                localStorage.setItem("authData", JSON.stringify(authData));
                                navigate(navigateTo())
                            }
                        });
                }}
            >
                {({ handleSubmit, handleChange, values, touched, errors, isSubmitting }) => (

                <Form noValidate onSubmit={handleSubmit} className="d-grid w-100">
                    <div className="d-grid mx-auto">
                        <h1>Login</h1>
                    </div>
                    <FormGroup className="mb-3" >
                        <FormLabel>Username</FormLabel>
                        <InputGroup hasValidation>
                            <FormControl
                                type="text"
                                placeholder="Username"
                                aria-describedby="inputGroupPrepend"
                                name="username"
                                value={values.username}
                                onChange={handleChange}
                                isInvalid={!!errors.username && !!touched.username}
                            />
                            <FormControl.Feedback type="invalid">
                                {errors.username}
                            </FormControl.Feedback>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-3" >
                        <FormLabel>Password</FormLabel>
                        <InputGroup hasValidation>
                            <FormControl
                                type="password"
                                placeholder="Password"
                                aria-describedby="inputGroupPrepend"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                isInvalid={!!errors.password && !!touched.password}
                            />
                            <FormControl.Feedback type="invalid">
                                {errors.password}
                            </FormControl.Feedback>
                        </InputGroup>
                    </FormGroup>
                    {/*<div style={{marginBottom: "10px"}}>*/}
                    {/*    <a href={'/forgotten'}>Forgot password?</a>*/}
                    {/*</div>*/}
                    <div className="d-grid gap-2 col-md-8 col-12 mx-auto">
                        <Button variant="primary" type="submit">
                            Log in
                        </Button>
                        {/*<Button onClick={() => navigate('/register')} variant="primary-light">*/}
                        {/*    Register*/}
                        {/*</Button>*/}
                    </div>

                </Form>
                )}
            </Formik>
            </CenterVerticallyAndHorizontally>
        </Container>
    );
}

const initValues =  {
    username: '',
    password: '',
}

const schema = Yup.object({
    username: Yup.string().required('Username is required!'),
    password: Yup.string().required('Password is required!'),
})