import {customAxios} from "../customAxios";
import {useState} from "react";


export function useDeactivateOrder() {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    async function execute(id: number) {
        try {
            setLoading(true);
            await deactivate(id)
            return;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading, error, execute
    };
}

export async function deactivate(id: number): Promise<void> {
    const response = await customAxios.put<void>(`/v1/order/${id}/deactivate`);
    return response.data;
}


export function useActivateOrder() {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    async function execute(id: number) {
        try {
            setLoading(true);
            await activate(id)
            return;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading, error, execute
    };
}

export async function activate(id: number): Promise<void> {
    const response = await customAxios.put<void>(`/v1/order/${id}/activate`);
    return response.data;
}