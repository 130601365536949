import React from "react";
import {useStepFunctions} from "../../../api/steps/useStepFunctions";
import {CommonStepProps} from "./DynamicStepComponentRenderer";
import notifier from "simple-react-notifications2";
import {Button} from "react-bootstrap";


export function UserStepsFinished(props: CommonStepProps) {

    const {loading, error, execute, accept, decline} = useStepFunctions();

    function ok() {

    }

    return(
        <>
            <>UserStepsFinished</>
            <>Thank you for ordering ...</>

            <Button onClick={ok} variant="primary">
                Back
            </Button>

        </>
    )
}