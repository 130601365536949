import React, { useState } from 'react';
import styles from '../tax/ReadMore.module.css';
import {useTranslation} from "react-i18next";
import {OrderUserInfoDto} from "../../model/order/orderFullDto";

interface ReadMoreUserInfoProps {
    orderUserInfo: OrderUserInfoDto;
    maxHeight: number; // Maximum height in pixels
}

interface KeyValue{
    key: string,
    value: string,
}

export function ReadMoreUserInfo(props: ReadMoreUserInfoProps){
    const {t, i18n} = useTranslation();

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    function getRows(): KeyValue[] {
        const properties: string[] = Object.getOwnPropertyNames(props.orderUserInfo)
        const entries = new Map(Object.entries(props.orderUserInfo));
        const map: KeyValue[] = [];
        // return properties.map(p => `${t(`salary.salaryInfo.${p}`)}: ${map.get(p)?.toString()}`)
        for (const p of properties) {
            const property = t(`salary.salaryInfo.${p}`, '')
            if(property) {
                const value = entries.get(p)
                if(value) {
                    map.push({key: property, value: value})
                }
            }
        }

        return map;
    }

    return (
        <div>
            <div
                className={`${styles.readMoreContent} ${!isExpanded ? styles.collapsed : ''}`}
                style={{ maxHeight: isExpanded ? 'none' : `${props.maxHeight}px`, }}
            >
                {getRows().map(i => (
                    <div>
                        <b>{i.key}</b>: {i.value}
                    </div>
                ))}
            </div>
            <div onClick={toggleExpand} className="mt-2" style={{fontWeight: "800", cursor: "pointer", textDecoration: 'underline'}}>
                {isExpanded ? t('vehicle.readLess') : t('vehicle.readMore')}
            </div>
        </div>
    );
}
