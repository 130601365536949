
import {Authenticated} from "../Authenticated/Authenticated";
import {MainLayout} from "./MainLayout";
import React from "react";


export function MainLayoutAuthenticated() {


    return (
        // @ts-ignore
        <Authenticated>
            <MainLayout></MainLayout>
        </Authenticated>

    )
}