import React from 'react';
import {Formik, Form, ErrorMessage, FormikHelpers} from 'formik';
import * as Yup from 'yup';
import {Form as BootstrapForm, Button, Alert} from 'react-bootstrap';
import {useLeasePackagesImport} from "../../api/imports/leasePaackagesImport";

interface FormValues {
    file: File | null;
}

function GenericFileUploadForm(props: {
    tenantId: number
    execute: (formData: FormData, tenantId: number) => Promise<void>
    type: string[]
}) {

    const initialValues: FormValues = {
        file: null,
    };

    const validationSchema = Yup.object().shape({
        file: Yup.mixed()
            .required("A file is required")
            .test("fileSize", "File is too large", value => {
                return value && (value as File).size <= 10048576; // 10MB limit
            })
            .test("type", "Unsupported file format", (value) => {
                return value && props.type.find(it => (value as File).name.endsWith(it)) !== undefined;
            }),
    });

    const onSubmit = (
        values: FormValues,
        {setSubmitting}: FormikHelpers<FormValues>
    ) => {
        setSubmitting(true);
        const formData = new FormData();
        formData.append('file', values.file!!);
        props.execute(formData, props.tenantId).finally(() => setSubmitting(false));
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({setFieldValue}) => (
                <Form>
                    <BootstrapForm.Group>
                        <BootstrapForm.Label>Upload File</BootstrapForm.Label>
                        <BootstrapForm.Control
                            type="file"
                            name="file"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue("file", event.currentTarget.files![0]);
                            }}
                        />
                        <ErrorMessage name="file">
                            {(msg) => <Alert variant="danger">{msg}</Alert>}
                        </ErrorMessage>
                    </BootstrapForm.Group>

                    <div className="mt-5 mb-5 d-grid gap-2 col-md-8 col-12 mx-auto">
                        <Button variant="primary" type="submit">
                            Upload
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default GenericFileUploadForm;
