import React, {useEffect, useState} from "react";
import {Button, Col, Row, Table} from "react-bootstrap";
import {DatatableWrapper, TableBody, TableColumnType, TableHeader} from "react-bs-datatable";
import Container from "react-bootstrap/Container";
import {SelectedTenantProvider, useSelectedTenant} from "../../components/tenant/SelectedTenantContext";
import {SelectTenant} from "../../components/tenant/SelectTenant";
import {useEnableImport} from "../../api/imports/useDisableEnableImports";
import {useGetUsers} from "../../api/users/useUsers";
import {TenantUserDto, UserDto} from "../../model/Users";
import {useNavigate} from "react-router-dom";
import GenericFileUploadModal from "../../components/file/GenericFileUploadModal";
import {useUsersImport} from "../../api/users/usersImport";
import InviteModal from "../../components/users/InviteModal";
import {useGetDeactivatedUsers} from "../../api/users/useDeactivatedUsers";
import ConfirmModal from "../../components/modals/ConfirmModal";
import notifier from "simple-react-notifications2";
import {useDeactivateUser} from "../../api/users/useDeactivateUser";
import {useReactivateUser} from "../../api/users/useReactivateUser";
import {Loading} from "../../components/Loading";


export function TenantDeactivatedUsers() {

    return (
        <SelectedTenantProvider>
            <SelectTenant/>
            <DeactivatedUsers></DeactivatedUsers>
        </SelectedTenantProvider>
    )
}

type ArrayElementType = TenantUserDto & {
    button: any;
};

export default function DeactivatedUsers() {
    const navigate = useNavigate();
    const [selectedId, setSelectedId] = useState<number>()
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const {loading: loadingReactivate, error: errorReactivate, execute: executeReactivate} = useReactivateUser();

    const {loading, data, error, execute} = useGetDeactivatedUsers();
    const { selectedTenant, setSelectedTenant } = useSelectedTenant();

    useEffect(() => {
        if(selectedTenant) {
            execute(selectedTenant.id);
        }
    }, [selectedTenant]);

    function refresh() {
        if(selectedTenant) {
            execute(selectedTenant.id);
        }
    }

    function executeClicked(id: number) {
        setSelectedId(id);
        setShowConfirmModal(true)
    }


    function reactivate(id: number | undefined) {
        if(selectedTenant?.id && id) {
            executeReactivate(selectedTenant.id, id)
                .then(res => {
                    setShowConfirmModal(false);
                    refresh()
                })
                .catch(e => notifier.error(e.toString()))
        }
    }


    const header: TableColumnType<ArrayElementType>[] = [
        {title: 'Id', prop: 'id', isSortable: true, isFilterable: true},
        {title: 'Username', prop: 'username', isSortable: true, isFilterable: true},
        {title: 'Email', prop: 'email', isSortable: true, isFilterable: true},
        {title: 'Confirmed', prop: 'emailConfirmed', isSortable: true, isFilterable: true},
        {title: 'First Name', prop: 'firstName', isSortable: true, isFilterable: true},
        {title: 'Last Name', prop: 'lastName', isSortable: true, isFilterable: true},
        {title: 'Role', prop: 'role', isSortable: true, isFilterable: true,
            cell: (row) => (<div>{row.role.name}</div>)},
        {
            prop: "button",
            cell: (row) => (
                <>
                    <Button variant="outline-primary" size="sm"
                            onClick={() => executeClicked(row.id)}>
                        Reactivate
                    </Button>

                </>

            )
        }
    ];

    if(loading || loadingReactivate) return (<Loading/>);

    return (
        <>
                <Container>

                    <Row>
                    <DatatableWrapper body={data as ArrayElementType[]} headers={header}>
                        <Table>
                            <TableHeader/>
                            <TableBody/>
                        </Table>
                    </DatatableWrapper>
                    </Row>
                    <ConfirmModal
                        show={showConfirmModal}
                        onHide={() => setShowConfirmModal(false)}
                        onConfirm={() => reactivate(selectedId)}
                        title={"Reactivate"}
                        body={"Reactivate userid: " + selectedId }
                    />
                </Container>
        </>
    )
}
