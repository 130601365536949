import {customAxios} from "../customAxios";
import {useState} from "react";
import {OrderFullDto} from "../../model/order/orderFullDto";
import notifier from "simple-react-notifications2";
import {getOrders} from "./useOrders";


export function useDeleteOrder() {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    async function execute(id: number) {
        try {
            setLoading(true);
            await deleteOrder(id);
        }
        catch (e) {
            setError(e as Error);
            notifier.error((e as Error).message)
            return Promise.reject((e as Error));
        }
        finally {
            setLoading(false);
        }
    }

    return {loading, error, execute};
}


export async function deleteOrder(id: number): Promise<void> {
    const response = await customAxios.delete<void>(`/v1/order/${id}`);
    return response.data;
}