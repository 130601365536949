import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableColumnType,
    TableHeader
} from "react-bs-datatable";
import {Button, Col, Row, Table} from "react-bootstrap";
import {TablePaginationOptionsParameters} from "react-bs-datatable/lib/esm/components/DatatableWrapper";
import Container from "react-bootstrap/Container";
import {useTranslation} from "react-i18next";
import {useOrders} from "../../api/orders/useOrders";
import {Loading} from "../../components/Loading";
import {OrderWorkflowStep} from "../../model/order/orderWorkflow";
import {OrderFullDto} from "../../model/order/orderFullDto";
import {SelectedTenantProvider, useSelectedTenant} from "../../components/tenant/SelectedTenantContext";
import {SelectTenant} from "../../components/tenant/SelectTenant";
import Users from "../users/Users";


type ArrayElementType = OrderFullDto & {
    name: string;
    email: string;
    model: string;
    requiresInput: boolean;
    button: React.ReactNode;
};

export function TenantOrders() {

    return (
        <SelectedTenantProvider>
            <SelectTenant/>
            <Orders></Orders>
        </SelectedTenantProvider>
    )
}
export default function Orders() {

    const {loading, data, error, execute} = useOrders();
    const navigate = useNavigate();
    const { selectedTenant, setSelectedTenant } = useSelectedTenant();
    const { t, i18n } = useTranslation();


    useEffect(() => {
        if(selectedTenant) {
            execute(selectedTenant.id);
        }
    }, [selectedTenant]);

    if(loading) return (<Loading/>)
    if(!data) return (<div>No data</div>)

    function edit(id: number) {
        navigate(`/orders/${id}`)
    }

    function refresh() {
        if(selectedTenant) {
            execute(selectedTenant.id);
        }
    }

    function requiresInput(steps: OrderWorkflowStep[]): boolean {
        const last = steps.filter(it => it.status !== undefined && !it.status.status);
        if(last.length > 0) {
            return last[0].adminEnabled
        }
        return false
    }

    const header: TableColumnType<ArrayElementType>[] = [
        {title: 'Id', prop: 'id', isSortable: true, isFilterable: true},
        {title: t('user.name'), prop: 'name', isSortable: true, isFilterable: true,
            cell: (row) => (<>{`${row.user.lastName}, ${row.user.firstName}`}</>)},
        {title: t('user.email'), prop: 'email', isSortable: true, isFilterable: true,
            cell: (row) => (<>{`${row.user.email}`}</>)},
        {title: t('order.vehicle'), prop: 'vehicle', isSortable: true, isFilterable: true,
            cell: (row) => (<>{`${row.vehicle.brand}, ${row.vehicle.model}`}</>)},
        {title: t('order.imput'), prop: 'requiresInput', isSortable: true, isFilterable: true,
            cell: (row) => {
                const r = requiresInput(row.steps)
                if(r) return (<>True</>)
                else return (<>False</>)
            }
        },
        {
            prop: "button",
            cell: (row) => (
                <Button variant="primary" size="sm"
                        onClick={() => edit(row.id)}>
                    {t('order.viewButton')}
                </Button>
            )
        }
    ];

    const paginationProps={
        initialState: {
            rowsPerPage: 20,
            options: [5, 10, 20, 50, 100]
        }
    } as TablePaginationOptionsParameters

    return (
        <>
            <Container>

                <Row>
                    <DatatableWrapper body={data as ArrayElementType[]} headers={header}
                                      paginationOptionsProps={paginationProps}
                    >
                        <Row className="mb-4 p-2">
                            <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                                <Filter />
                            </Col>

                        </Row>
                        <Table>
                            <TableHeader/>
                            <TableBody/>
                        </Table>
                        <Row>
                            <Col xs={12}  className="d-flex flex-col justify-content-end align-items-end">
                                <PaginationOptions />
                                <Pagination />
                            </Col>
                        </Row>
                    </DatatableWrapper>
                </Row>
            </Container>
        </>
    )
}