import React, {useEffect} from "react";
import Container from "react-bootstrap/Container";
import {useGetVehicle} from "../../api/vehicle/useVehicle";
import {useParams} from "react-router-dom";
import * as yup from "yup";
import {Field, FieldArray, Formik} from "formik";
import {Form, FormGroup, FormControl, FormLabel, Button, Row} from "react-bootstrap";
import {AdminVehicleFullDto} from "../../model/Vehicle";
import {useSaveVehicle} from "../../api/vehicle/useSaveVehicle";
import {Loading} from "../../components/Loading";
import notifier from "simple-react-notifications2";
import {useVehicleMainPhotoImport} from "../../api/vehicle/useVehicleMainPhotoImport";
import GenericFileUploadModal from "../../components/file/GenericFileUploadModal";


export default function VehicleEdit() {

    const {loading, data, error, execute} = useGetVehicle();
    const {loading: loadingSave, data: dataSave, error: errorSave, execute: executeSave} = useSaveVehicle();
    const {loading: loadingPhoto, error: errorPhoto, execute: executePhoto} = useVehicleMainPhotoImport();
    const {vehicleId} = useParams();

    useEffect(() => {
        executeGet();
    }, [vehicleId]);

    function executeGet() {
        if (vehicleId && !Number.isNaN(vehicleId)) {
            execute(Number.parseInt(vehicleId));
        }
    }


    const validationSchema = yup.object().shape({
        model: yup.string().required('Required'),
        // numberOfDoors: yup.number().required('Required').positive().integer(),
        // maximumPowerKw: yup.number().required('Required').positive(),
        // mainPhotoUrl: yup.string().url('Invalid URL').required('Required'),
        // vehicleDetails: yup.object().shape({
            // standardEquipment: yup.string().required('Required'),
            // specialEquipment: yup.string().required('Required'),
            // estimatedDeliveryTime: yup.string().required('Required'),
            // ... more nested validations ...
        // }),
    });

    if (loading || loadingPhoto || loadingSave || !data) return (<Loading/>);

    function save(dto: AdminVehicleFullDto) {
        executeSave(dto)
            .then(r => {
                notifier.success("Saved")
                executeGet();
            })
            .catch(e => {notifier.error("Error saving"); console.log(e.toString())})
    }

    function executeMainPhoto(formData: FormData, vehicleId: number): Promise<void> {
        executePhoto(formData, vehicleId)
            .then(r => {
                notifier.success("Saved");
                executeGet();
            })
        return Promise.resolve();
    }

    return (
        <>
            <Container>
                <Row>
                    <GenericFileUploadModal execute={executeMainPhoto} type={['.png', '.jpg', '.jpeg']} buttonTitle={'Import main photo'}
                                            tenantId={data.id} title={'Import users'}/>
                </Row>
                <Formik initialValues={data} validationSchema={validationSchema} onSubmit={values => save(values)}>
                    {({handleSubmit, setFieldValue, handleChange, handleBlur, values, touched, errors}) => (
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <FormLabel>Model</FormLabel>
                                <Field name="model" onChange={handleChange} value={values.model} as={Form.Control}
                                       isInvalid={touched.model && !!errors.model}/>
                                <FormControl.Feedback type="invalid">{errors.model}</FormControl.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Brand</FormLabel>
                                <Field name="brand" onChange={handleChange} value={values.brand} as={Form.Control}
                                       isInvalid={touched.brand && !!errors.brand}/>
                                <FormControl.Feedback type="invalid">{errors.brand}</FormControl.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>HSN</FormLabel>
                                <Field name="hsn" onChange={handleChange} value={values.hsn} as={Form.Control}
                                       isInvalid={touched.brand && !!errors.brand}/>
                                <FormControl.Feedback type="invalid">{errors.brand}</FormControl.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>TSN</FormLabel>
                                <Field name="tsn" onChange={handleChange} value={values.tsn} as={Form.Control}
                                       isInvalid={touched.brand && !!errors.brand}/>
                                <FormControl.Feedback type="invalid">{errors.brand}</FormControl.Feedback>
                            </FormGroup>

                            <FormGroup>
                                <FormLabel>Number of Doors</FormLabel>
                                <Field name="numberOfDoors" onChange={handleChange} value={values.numberOfDoors} type="number" as={Form.Control}
                                       isInvalid={touched.numberOfDoors && !!errors.numberOfDoors}/>
                                <FormControl.Feedback type="invalid">{errors.numberOfDoors}</FormControl.Feedback>
                            </FormGroup>

                            <FormGroup>
                                <FormLabel>Maximum Power Kw</FormLabel>
                                <Field name="maximumPowerKw" onChange={handleChange} value={values.maximumPowerKw} type="number" as={Form.Control}
                                       isInvalid={touched.maximumPowerKw && !!errors.maximumPowerKw}/>
                                <FormControl.Feedback type="invalid">{errors.maximumPowerKw}</FormControl.Feedback>
                            </FormGroup>

                            <FormGroup>
                                <FormLabel>Consumption Kwh/100km</FormLabel>
                                <Field name="consumption" onChange={handleChange} value={values.consumption} type="number" as={Form.Control}
                                       isInvalid={touched.consumption && !!errors.consumption}/>
                                <FormControl.Feedback type="invalid">{errors.consumption}</FormControl.Feedback>
                            </FormGroup>

                            <FormGroup>
                                <FormLabel>Main Photo Url</FormLabel>
                                <Field name="mainPhotoUrl" onChange={handleChange} value={values.mainPhotoUrl} as={Form.Control}
                                       isInvalid={touched.mainPhotoUrl && !!errors.mainPhotoUrl}/>
                                <FormControl.Feedback type="invalid">{errors.mainPhotoUrl}</FormControl.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Other photos</FormLabel>
                            <FieldArray name="otherPhotos">
                                {({push, remove}) => (
                                    <div>
                                        {values.otherPhotos.map((item, index) => (
                                            <div key={index}>
                                                <FormGroup>
                                                    <FormLabel>Other photo url {index + 1}</FormLabel>
                                                    <FormControl
                                                        as="input"
                                                        value={values.otherPhotos[index]}
                                                        name={`otherPhotos.${index}`}
                                                        onChange={handleChange}
                                                        isInvalid={!!(touched.otherPhotos && errors.otherPhotos && errors.otherPhotos[index])}
                                                    />
                                                    <FormControl.Feedback
                                                        type="invalid">{errors.otherPhotos && errors.otherPhotos[index]}</FormControl.Feedback>
                                                    <Button variant="danger" size="sm"
                                                            onClick={() => remove(index)}>Remove</Button>
                                                </FormGroup>
                                            </div>
                                        ))}
                                        <Button size="sm" onClick={() => push('')} variant="primary">Add New Url</Button>
                                    </div>
                                )}
                            </FieldArray>
                            </FormGroup>

                            <FormGroup>
                                <FormLabel>Range Km</FormLabel>
                                <Field name="rangeKm" onChange={handleChange} value={values.rangeKm} type="number" as={Form.Control}
                                       isInvalid={touched.rangeKm && !!errors.rangeKm}/>
                                <FormControl.Feedback type="invalid">{errors.rangeKm}</FormControl.Feedback>
                            </FormGroup>

                            <FormGroup>
                                <FormLabel>Car Model</FormLabel>
                                <Field name="carModel" onChange={handleChange} value={values.carModel} as={Form.Control}
                                       isInvalid={touched.carModel && !!errors.carModel}/>
                                <FormControl.Feedback type="invalid">{errors.carModel}</FormControl.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Body Type</FormLabel>
                                <Field name="bodyType" onChange={handleChange} value={values.bodyType} as={Form.Control}
                                       isInvalid={touched.bodyType && !!errors.bodyType}/>
                                <FormControl.Feedback type="invalid">{errors.bodyType}</FormControl.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Trim Level</FormLabel>
                                <Field name="trimLevel" onChange={handleChange} value={values.trimLevel} as={Form.Control}
                                       isInvalid={touched.trimLevel && !!errors.trimLevel}/>
                                <FormControl.Feedback type="invalid">{errors.trimLevel}</FormControl.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Colors</FormLabel>
                                <FieldArray name="colors">
                                    {({push, remove}) => (
                                        <div>
                                            {values.colors.map((item, index) => (
                                                <div key={index}>
                                                    <FormGroup>
                                                        <FormLabel>Color {index + 1}</FormLabel>
                                                        <FormControl
                                                            as="input"
                                                            name={`colors.${index}`}
                                                            value={values.colors[index]}
                                                            onChange={handleChange}
                                                            isInvalid={!!(touched.colors && errors.colors && errors.colors[index])}
                                                        />
                                                        <FormControl.Feedback
                                                            type="invalid">{errors.colors && errors.colors[index]}</FormControl.Feedback>
                                                        <Button size="sm" variant="danger"
                                                                onClick={() => remove(index)}>Remove</Button>
                                                    </FormGroup>
                                                </div>
                                            ))}
                                            <Button size="sm" onClick={() => push('')} variant="primary">Add New Color</Button>
                                        </div>
                                    )}
                                </FieldArray>
                            </FormGroup>

                            <FormGroup>
                                <FormLabel>Delivery</FormLabel>
                                <FieldArray name="delivery">
                                    {({push, remove}) => (
                                        <div>
                                            {values.delivery.map((item, index) => (
                                                <div key={index}>
                                                    <FormGroup>
                                                        <FormLabel>Delivery {index + 1}</FormLabel>
                                                        <FormControl
                                                            as="input"
                                                            value={values.delivery[index]}
                                                            name={`delivery.${index}`}
                                                            onChange={handleChange}
                                                            isInvalid={!!(touched.delivery && errors.delivery && errors.delivery[index])}
                                                        />
                                                        <FormControl.Feedback
                                                            type="invalid">{errors.delivery && errors.delivery[index]}</FormControl.Feedback>
                                                        <Button size="sm" variant="danger"
                                                                onClick={() => remove(index)}>Remove</Button>
                                                    </FormGroup>
                                                </div>
                                            ))}
                                            <Button size="sm" onClick={() => push('')} variant="primary">Add New Delivery</Button>
                                        </div>
                                    )}
                                </FieldArray>
                            </FormGroup>


                            <FormGroup>
                                <FormLabel>Standard Equipment</FormLabel>
                                <FormControl
                                    as="textarea"
                                    style={{
                                        height: '100px',
                                        overflowY: 'auto',
                                        resize: 'vertical'
                                    }} onChange={handleChange}
                                    name="vehicleDetails.standardEquipment"
                                    value={values.vehicleDetails?.standardEquipment}
                                    isInvalid={!!touched.vehicleDetails?.standardEquipment && !!errors.vehicleDetails?.standardEquipment}
                                />
                                <FormControl.Feedback
                                    type="invalid">{errors.vehicleDetails?.standardEquipment}</FormControl.Feedback>
                            </FormGroup>

                            <FormGroup>
                                <FormLabel>Special Equipment</FormLabel>
                                <FormControl
                                    as="textarea"
                                    style={{
                                        height: '100px', // This can be set based on your design requirements
                                        overflowY: 'auto',
                                        resize: 'vertical' // This allows users to resize the textarea vertically
                                    }} onChange={handleChange}
                                    name="vehicleDetails.specialEquipment"
                                    value={values.vehicleDetails?.specialEquipment}
                                    isInvalid={!!touched.vehicleDetails?.specialEquipment && !!errors.vehicleDetails?.specialEquipment}
                                />
                                <FormControl.Feedback
                                    type="invalid">{errors.vehicleDetails?.specialEquipment}</FormControl.Feedback>
                            </FormGroup>

                            <FormGroup>
                                <FormLabel>Technical Details</FormLabel>
                                <FormControl
                                    as="textarea"
                                    style={{
                                        height: '100px', // This can be set based on your design requirements
                                        overflowY: 'auto',
                                        resize: 'vertical' // This allows users to resize the textarea vertically
                                    }} onChange={handleChange}
                                    name="vehicleDetails.technicalDetails"
                                    value={values.vehicleDetails?.technicalDetails}
                                    isInvalid={!!touched.vehicleDetails?.technicalDetails && !!errors.vehicleDetails?.technicalDetails}
                                />
                                <FormControl.Feedback
                                    type="invalid">{errors.vehicleDetails?.technicalDetails}</FormControl.Feedback>
                            </FormGroup>

                            <FormGroup>
                                <FormLabel>Delivery from (weeks)</FormLabel>
                                <Field name="deliveryFromWeeks" onChange={handleChange} value={values.deliveryFromWeeks} type="number" as={Form.Control}
                                       isInvalid={touched.deliveryFromWeeks && !!errors.deliveryFromWeeks}/>
                                <FormControl.Feedback type="invalid">{errors.deliveryFromWeeks}</FormControl.Feedback>
                            </FormGroup>

                            <FormGroup>
                                <FormLabel>Delivery to (weeks)</FormLabel>
                                <Field name="deliveryToWeeks" onChange={handleChange} value={values.deliveryToWeeks} type="number" as={Form.Control}
                                       isInvalid={touched.deliveryToWeeks && !!errors.deliveryToWeeks}/>
                                <FormControl.Feedback type="invalid">{errors.deliveryToWeeks}</FormControl.Feedback>
                            </FormGroup>

                            <div className="mt-5 mb-5 d-grid gap-2 col-md-8 col-12 mx-auto">
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                                {/*<Button variant="primary" onClick={() => handleSubmit()}>*/}
                                {/*    Submit2*/}
                                {/*</Button>*/}
                            </div>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    )
}