import {customAxios} from "../customAxios";
import {useEffect, useState} from "react";
import {AdminVehicleFullDto, VehicleSimpleDto} from "../../model/Vehicle";

export function useGetVehicle() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<AdminVehicleFullDto>();
    const [error, setError] = useState<Error>();

    async function execute(id: number) {
        try {
            setLoading(true);
            const data = await getVehicle(id);
            setData(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading, data, error, execute
    };
}

export async function getVehicle(id: number): Promise<AdminVehicleFullDto> {
    const response = await customAxios.get<AdminVehicleFullDto>(`/v1/vehicle/admin/${id}`, );
    return response.data;
}

