import {Form, FormControl, FormControlProps, FormGroup, FormLabel, FormSelect} from "react-bootstrap";
import {Field, FormikErrors, FormikTouched} from "formik";
import React from "react";
import {useTranslation} from "react-i18next";
import {CodeNumberValueDto} from "../../model/salary/SalaryOptions";
import {UserDto, UserSalaryInfo} from "../../model/salary/UserInfo";

interface UserInfoNumberSelectFieldProps {
    name: string;
    value: number | undefined;
    options: CodeNumberValueDto[];
    setFieldValue: (field: string, value: any, shouldValidate?: (boolean | undefined)) => Promise<void | FormikErrors<UserDto>>
    touched: FormikTouched<{ [key: string]: any }>;
    errors: FormikErrors<{ [key: string]: any }>;
}

export default function UserInfoNumberSelectFormGroup(
    props: UserInfoNumberSelectFieldProps
) {
    const {t, i18n} = useTranslation();
    const error = props.errors[props.name];

    return (
        <>
            <FormGroup className="mb-3">
                <FormLabel>{t(`salary.${props.name}`)}</FormLabel>
                <FormSelect
                    name={props.name}
                    value={props.value}
                    onChange={event => {
                        const numValue = parseFloat(event.target.value);
                        props.setFieldValue(props.name, isNaN(numValue) ? 0 : numValue);
                    }}
                    isInvalid={!!props.touched[props.name] && !!props.errors[props.name]}
                >
                    <option value="" label={t(`salary.${props.name}`)}/>
                    {props.options.map((m, i) => {
                        return (<option key={i} value={m.code}>{m.value}</option>)
                    })}
                </FormSelect>
                {error && (
                    <FormControl.Feedback type="invalid">
                        {error.toString()}
                    </FormControl.Feedback>
                )}
            </FormGroup>
        </>

    )
}

