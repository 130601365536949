import React from 'react';

interface CenterHorizontally {
    gap?: string;
    children: React.ReactNode;
}

export function CenterHorizontally(props: CenterHorizontally) {
    return (
        <div style={{display: 'flex', justifyContent: 'center', gap: props.gap, width: '100%'}}>
            {props.children}
        </div>
    );
}