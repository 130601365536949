import {useState} from "react";
import {customAxios} from "../customAxios";



export function useTempFunctions() {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    async function notifyUnconfirmed() {
        try {
            setLoading(true);
            await notify()
            return;
        }
        catch (e) {
            // setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }


    async function deleteUnconfirmed() {
        try {
            setLoading(true);
            await deleteFun()
            return;
        }
        catch (e) {
            // setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {loading, error, notifyUnconfirmed, deleteUnconfirmed,};
}

export async function notify(): Promise<void> {
    const response = await customAxios.post<void>(`/v1/temp/notify`);
    return response.data;
}

export async function deleteFun(): Promise<void> {
    const response = await customAxios.post<void>(`/v1/temp/delete`);
    return response.data;
}
