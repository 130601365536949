import {ChildParentTenantDto, TenantDto} from "../../model/TenantDto";
import {Button, Col, Form, FormControl, FormGroup, FormLabel, FormSelect, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {useSaveTenant} from "../../api/tenants/useSaveTenant";
import {useNavigate, useParams} from "react-router-dom";
import {useGetTenant} from "../../api/tenants/useTenant";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {Loading} from "../../components/Loading";
import notifier from "simple-react-notifications2";
import {useGetTenants} from "../../api/tenants/useTenants";
import TenantBooleanRadioFormGroup from "../../components/tenant/TenantBooleanRadioFormGroup";
import {useGetSupplier} from "../../api/suppliers/useSupplier";
import {SupplierDto} from "../../model/supplier/supplier";
import {useSaveSupplier} from "../../api/suppliers/useSaveSuppliers";
import {useGetSupplierContracts} from "../../api/suppliers/useSupplierContracts";


export default function SupplierEdit() {
    const navigate = useNavigate();

    const {loading, data, error, execute} = useGetSupplier();

    const {loading: loadingSave, data: dataSave, error: errorSave, execute: executeSave} = useSaveSupplier();
    const {supplierId} = useParams();
    const {loading: loadingContracts, data: dataContracts, error: errorContracts, execute: executeContracts} = useGetSupplierContracts();

    useEffect(() => {
        executeContracts();
    }, []);


    useEffect(() => {
        executeGet();
    }, [supplierId]);

    function executeGet() {
        if (supplierId && !Number.isNaN(supplierId)) {
            execute(Number.parseInt(supplierId));
        }
    }

    if(!data) return (<Loading/>);

    const schema = Yup.object().shape({
        name: Yup.string().required('Required'),
        code: Yup.string().required('Required'),
        deliveryAnnex: Yup.string().nullable(),
        registrationAnnex: Yup.string().nullable(),
        financeRateAnnex: Yup.string().nullable(),
        vehicleInsuranceAnnex: Yup.string().nullable(),
        maintenanceAndServicingAnnex: Yup.string().nullable(),
        tirePackageAnnex: Yup.string().nullable(),
        tireChangeAnnex: Yup.string().nullable(),
        broadcastingFeeAnnex: Yup.string().nullable(),
        accidentPreventionAnnex: Yup.string().nullable(),
        cancellationAndServiceFeeAnnex: Yup.string().nullable(),
        rateInsuranceAnnex: Yup.string().nullable(),
    });

    function save(dto: SupplierDto) {
        executeSave(dto).then(res => {
            notifier.success("Saved")
            executeGet()
        });
    }

    function sortedContracts(): string[] {
        if(dataContracts) {
            return dataContracts.sort((a, b) => a.localeCompare(b))
        }
        return [];
    }

    return (
        <>
            <Container>

                <Formik initialValues={data} validationSchema={schema} onSubmit={values => save(values)}>
                    {({handleSubmit, setFieldValue, handleChange, handleBlur, values, touched, errors}) => (
                        <Form onSubmit={handleSubmit}>
                            {/*<Row>{JSON.stringify(values)}</Row>*/}
                            <FormGroup>
                                <FormLabel>Name</FormLabel>
                                <Field name="name" value={values.name} as={Form.Control}
                                       isInvalid={touched.name && !!errors.name} onChange={handleChange}/>
                                <FormControl.Feedback type="invalid">{errors.name}</FormControl.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Code</FormLabel>
                                <Field name="code" value={values.code} as={Form.Control} disabled={true}
                                       isInvalid={touched.code && !!errors.code} onChange={handleChange}/>
                                <FormControl.Feedback type="invalid">{errors.code}</FormControl.Feedback>
                            </FormGroup>

                            <FormGroup className="mb-3">
                                <FormLabel>Lieferkosten  (deliveryAnnex)</FormLabel>
                                <FormSelect
                                    name="deliveryAnnex" value={values.deliveryAnnex}
                                    isInvalid={touched.deliveryAnnex && !!errors.deliveryAnnex} onChange={handleChange}
                                >
                                    <option value={undefined} label={''}/>
                                    {sortedContracts().map((m, i) => {
                                        return (<option key={i} value={m}>{m}</option>)
                                    })}
                                </FormSelect>
                                <FormControl.Feedback type="invalid">{errors.deliveryAnnex}</FormControl.Feedback>
                            </FormGroup>

                            <FormGroup className="mb-3">
                                <FormLabel>Zulassung (registrationAnnex)</FormLabel>
                                <FormSelect
                                    name="registrationAnnex" value={values.registrationAnnex}
                                    isInvalid={touched.registrationAnnex && !!errors.registrationAnnex} onChange={handleChange}
                                >
                                    <option value={undefined} label={''}/>
                                    {sortedContracts().map((m, i) => {
                                        return (<option key={i} value={m}>{m}</option>)
                                    })}
                                </FormSelect>
                                <FormControl.Feedback type="invalid">{errors.registrationAnnex}</FormControl.Feedback>
                            </FormGroup>

                            <FormGroup className="mb-3">
                                <FormLabel>Finanzrate (financeRateAnnex)</FormLabel>
                                <FormSelect
                                    name="financeRateAnnex" value={values.financeRateAnnex}
                                    isInvalid={touched.financeRateAnnex && !!errors.financeRateAnnex} onChange={handleChange}
                                >
                                    <option value={undefined} label={''}/>
                                    {sortedContracts().map((m, i) => {
                                        return (<option key={i} value={m}>{m}</option>)
                                    })}
                                </FormSelect>
                                <FormControl.Feedback type="invalid">{errors.financeRateAnnex}</FormControl.Feedback>
                            </FormGroup>


                            <FormGroup className="mb-3">
                                <FormLabel>KFZ Versicherung (vehicleInsuranceAnnex)</FormLabel>
                                <FormSelect
                                    name="vehicleInsuranceAnnex" value={values.vehicleInsuranceAnnex}
                                    isInvalid={touched.vehicleInsuranceAnnex && !!errors.vehicleInsuranceAnnex} onChange={handleChange}
                                >
                                    <option value={undefined} label={''}/>
                                    {sortedContracts().map((m, i) => {
                                        return (<option key={i} value={m}>{m}</option>)
                                    })}
                                </FormSelect>
                                <FormControl.Feedback type="invalid">{errors.vehicleInsuranceAnnex}</FormControl.Feedback>
                            </FormGroup>


                            <FormGroup className="mb-3">
                                <FormLabel>Service Paket für Wartung und Instandhaltung (maintenanceAndServicingAnnex)</FormLabel>
                                <FormSelect
                                    name="maintenanceAndServicingAnnex" value={values.maintenanceAndServicingAnnex}
                                    isInvalid={touched.maintenanceAndServicingAnnex && !!errors.maintenanceAndServicingAnnex} onChange={handleChange}
                                >
                                    <option value={undefined} label={''}/>
                                    {sortedContracts().map((m, i) => {
                                        return (<option key={i} value={m}>{m}</option>)
                                    })}
                                </FormSelect>
                                <FormControl.Feedback type="invalid">{errors.maintenanceAndServicingAnnex}</FormControl.Feedback>
                            </FormGroup>

                            <FormGroup className="mb-3">
                                <FormLabel>Reifenersatz (tirePackageAnnex)</FormLabel>
                                <FormSelect
                                    name="tirePackageAnnex" value={values.tirePackageAnnex}
                                    isInvalid={touched.tirePackageAnnex && !!errors.tirePackageAnnex} onChange={handleChange}
                                >
                                    <option value={undefined} label={''}/>
                                    {sortedContracts().map((m, i) => {
                                        return (<option key={i} value={m}>{m}</option>)
                                    })}
                                </FormSelect>
                                <FormControl.Feedback type="invalid">{errors.tirePackageAnnex}</FormControl.Feedback>
                            </FormGroup>

                            <FormGroup className="mb-3">
                                <FormLabel>Reifenwechsel (tireChangeAnnex)</FormLabel>
                                <FormSelect
                                    name="tireChangeAnnex" value={values.tireChangeAnnex}
                                    isInvalid={touched.tireChangeAnnex && !!errors.tireChangeAnnex} onChange={handleChange}
                                >
                                    <option value={undefined} label={''}/>
                                    {sortedContracts().map((m, i) => {
                                        return (<option key={i} value={m}>{m}</option>)
                                    })}
                                </FormSelect>
                                <FormControl.Feedback type="invalid">{errors.tireChangeAnnex}</FormControl.Feedback>
                            </FormGroup>

                            <FormGroup className="mb-3">
                                <FormLabel>Rundfunkgebühr (broadcastingFeeAnnex)</FormLabel>
                                <FormSelect
                                    name="broadcastingFeeAnnex" value={values.broadcastingFeeAnnex}
                                    isInvalid={touched.broadcastingFeeAnnex && !!errors.broadcastingFeeAnnex} onChange={handleChange}
                                >
                                    <option value={undefined} label={''}/>
                                    {sortedContracts().map((m, i) => {
                                        return (<option key={i} value={m}>{m}</option>)
                                    })}
                                </FormSelect>
                                <FormControl.Feedback type="invalid">{errors.broadcastingFeeAnnex}</FormControl.Feedback>
                            </FormGroup>

                            <FormGroup className="mb-3">
                                <FormLabel>UUV (accidentPreventionAnnex)</FormLabel>
                                <FormSelect
                                    name="accidentPreventionAnnex" value={values.accidentPreventionAnnex}
                                    isInvalid={touched.accidentPreventionAnnex && !!errors.accidentPreventionAnnex} onChange={handleChange}
                                >
                                    <option value={undefined} label={''}/>
                                    {sortedContracts().map((m, i) => {
                                        return (<option key={i} value={m}>{m}</option>)
                                    })}
                                </FormSelect>
                                <FormControl.Feedback type="invalid">{errors.accidentPreventionAnnex}</FormControl.Feedback>
                            </FormGroup>

                            <FormGroup className="mb-3">
                                <FormLabel>Rückholung inkl. Abmeldung und Service Fee (cancellationAndServiceFeeAnnex)</FormLabel>
                                <FormSelect
                                    name="cancellationAndServiceFeeAnnex" value={values.cancellationAndServiceFeeAnnex}
                                    isInvalid={touched.cancellationAndServiceFeeAnnex && !!errors.cancellationAndServiceFeeAnnex} onChange={handleChange}
                                >
                                    <option value={undefined} label={''}/>
                                    {sortedContracts().map((m, i) => {
                                        return (<option key={i} value={m}>{m}</option>)
                                    })}
                                </FormSelect>
                                <FormControl.Feedback type="invalid">{errors.cancellationAndServiceFeeAnnex}</FormControl.Feedback>
                            </FormGroup>

                            <FormGroup className="mb-3">
                                <FormLabel>Ratenschutz (rateInsuranceAnnex)</FormLabel>
                                <FormSelect
                                    name="rateInsuranceAnnex" value={values.rateInsuranceAnnex}
                                    isInvalid={touched.rateInsuranceAnnex && !!errors.rateInsuranceAnnex} onChange={handleChange}
                                >
                                    <option value={undefined} label={''}/>
                                    {sortedContracts().map((m, i) => {
                                        return (<option key={i} value={m}>{m}</option>)
                                    })}
                                </FormSelect>
                                <FormControl.Feedback type="invalid">{errors.rateInsuranceAnnex}</FormControl.Feedback>
                            </FormGroup>


                            <div className="mt-5 mb-5 d-grid gap-2 col-md-8 col-12 mx-auto">
                                {loadingSave && (<Loading/>)}
                                <Button disabled={loadingSave} variant="primary" type="submit">
                                    Submit
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    )
}