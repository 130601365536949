import {useState} from "react";
import {TenantDto} from "../../model/TenantDto";
import {customAxios} from "../customAxios";
import {updateTenant} from "./useUpdateTenant";
import {createTenant} from "./useCreateTenant";
import notifier from "simple-react-notifications2";

export function useSaveTenant() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<TenantDto>();
    const [error, setError] = useState<Error>();

    async function execute(dto: TenantDto) {
        try {
            setLoading(true);
            if(dto.id > 0) {
                setData(await updateTenant(dto, dto.id))
            } else {
                setData(await createTenant(dto))
            }
            return data;
        }
        catch (e) {
            notifier.error((e as Error).message)
            setError(e as Error);
            // return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }


    return {
        loading, data, error, execute
    };
}