import {customAxios} from "../customAxios";
import {useEffect, useState} from "react";
import {AdminVehicleFullDto, VehicleSimpleDto} from "../../model/Vehicle";
import {updateTenant} from "../tenants/useUpdateTenant";
import {createTenant} from "../tenants/useCreateTenant";
import {TenantDto} from "../../model/TenantDto";
import {UserDto} from "../../model/Users";

export function useInvite() {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    async function execute(tenantId: number,  dto: InviteDto) {
        try {
            setLoading(true);
            invite(tenantId, dto)
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading, error, execute
    };
}

export async function invite(tenantId: number, dto: InviteDto): Promise<void> {
    const response = await customAxios.post<void>(`/v1/tenant/${tenantId}/users/invite`, dto);
    return;
}

export interface InviteDto{
    roleId: number,
    emails: string[]
}