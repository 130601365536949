import {
    Col,
    FormCheck,
    FormControl,
    FormGroup,
    FormLabel,
    Row
} from "react-bootstrap";
import {FormikErrors, FormikTouched} from "formik";
import React from "react";
import {useTranslation} from "react-i18next";
import {parseBoolean} from "../../util/parsing";
import {TenantDto} from "../../model/TenantDto";

interface TenantBooleanRadioFieldProps {
    name: string;
    value: boolean | undefined;
    setFieldValue: (field: string, value: any, shouldValidate?: (boolean | undefined)) => Promise<void | FormikErrors<TenantDto>>
    touched: FormikTouched<{ [key: string]: any }>;
    errors: FormikErrors<{ [key: string]: any }>;
}

export default function TenantBooleanRadioFormGroup(
    props: TenantBooleanRadioFieldProps
) {
    const {t, i18n} = useTranslation();
    const error = props.errors[props.name];

    return (
        <>
            <FormGroup className="mt-1 mb-3">
                <Row>
                    <Col xs={6}>
                        <FormLabel
                            className="form-check-inline">{t(`tenant.${props.name}`)}</FormLabel>
                    </Col>
                    <Col xs={6} className="text-end">
                        <div className="form-check-inline">
                            <FormCheck
                                className="form-check-inline"
                                type="radio"
                                aria-describedby="inputGroupPrepend"
                                name={props.name}
                                id={`${props.name}Yes`}
                                label={t('tenant.yes')}
                                onChange={event => {
                                    props.setFieldValue(props.name, parseBoolean(event.target.value));
                                }}
                                checked={props.value === true}
                                value="true"
                                isInvalid={!!props.touched[props.name] && !!props.errors[props.name]}
                                // as={Field}
                            />
                            <FormCheck
                                className="form-check-inline"
                                type="radio"
                                aria-describedby="inputGroupPrepend"
                                name={props.name}
                                id={`${props.name}No`}
                                label={t('tenant.no')}
                                onChange={event => {
                                    props.setFieldValue(props.name, parseBoolean(event.target.value));
                                }}
                                checked={props.value === false}
                                value="false"
                                isInvalid={!!props.touched[props.name] && !!props.errors[props.name]}
                                // as={Field}
                            />
                        </div>
                    </Col>
                </Row>
                {error && (
                    <FormControl.Feedback type="invalid">
                        {error.toString()}
                    </FormControl.Feedback>
                )}
            </FormGroup>
        </>

    )
}

