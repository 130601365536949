import * as Yup from "yup";
import {ErrorMessage, Field, FieldArray, Form, Formik} from "formik";
import {Alert, Button, Card, Col, Form as BootstrapForm, FormGroup, ListGroup, Row, Spinner} from "react-bootstrap";
import React, {useState} from "react";
import {useLeasePackagesImportPrepare} from "../../api/importsV2/leasePaackagesImportPrepare";
import {
    FoundVehiclesDto, MultiTenantImportsDto, OfferMaster,
    VehicleImport, VehicleImportDto,
    VehicleImportWithChoices,
    VehicleKeyAll, VehicleKeyAndFound
} from "../../model/import/importDtos";
import * as yup from "yup";
import {parseNumber} from "../../util/parsing";
import {TenantDto} from "../../model/TenantDto";
import {useGetTenants} from "../../api/tenants/useTenants";
import {useLeasePackagesImportV2Execute} from "../../api/importsV2/leasePaackagesImportV2Execute";
import notifier from "simple-react-notifications2";
import Container from "react-bootstrap/Container";


export default function ImportsV2New() {

    const {loading: loadingPrepare, data: dataPrepare, error: errorPrepare, execute: executePrepare} = useLeasePackagesImportPrepare()
    const {loading: loadingExecute, data: dataExecute, error: errorExecute, execute: executeExecute} = useLeasePackagesImportV2Execute()
    const [confirmedPreparation, setConfirmedPreparation] = useState<VehicleImport[]>([])
    const [selectedTenantIds, setSelectedTenantIds] = useState<number[]>([])

    function uploadPrepare(formData: FormData) {
        executePrepare(formData)
    }

    function confirmPreparation(ims: VehicleImport[]) {
        setConfirmedPreparation(ims)
    }

    function executeImport() {
        const dto = {tenantIds: selectedTenantIds, filename: dataPrepare?.filename, imports: confirmedPreparation} as MultiTenantImportsDto
        executeExecute(dto)
            .then(r => notifier.success("Sucess"))
            .catch(e => notifier.error("Error"))
    }

    const loadingAny = loadingPrepare

    return(
        <>
            <MultiImportFileUpload upload={uploadPrepare} loading={loadingAny} />
            {dataPrepare && (<><PreparedData data={dataPrepare.vehicleKeys} execute={confirmPreparation}/></>)}
            {loadingPrepare && (<Spinner/>)}
            {confirmedPreparation && confirmedPreparation.length > 0 && (
                <TenantMultiselectComponent onChange={setSelectedTenantIds} selectedIds={selectedTenantIds}/>
            )}
            {selectedTenantIds && selectedTenantIds.length > 0 && (
                <div className="mt-2 mb-5 d-grid gap-2 col-md-8 col-12 mx-auto">
                    <Button className="mb-5" variant="primary" type="submit" onClick={executeImport}>
                        Execute import
                    </Button>
                </div>
            )}
        </>
    )
}

function PreparedData(props: {data: VehicleKeyAndFound[], execute: (ims: VehicleImport[]) => void}) {

    const initData = dataChanged()
    function dataChanged(): VehicleImportWithChoices[] {
        const map = [] as VehicleImportWithChoices[];
        props.data.forEach(k => map.push(toVehicleImport(k.key, toVehicleImport(k.key, k.found))))
        return map
    }
    function toVehicleImport(key: VehicleKeyAll, fv: FoundVehiclesDto): VehicleImportWithChoices {
        return {key: key, selectedVehicleId: fv.match?.id, newVehicle: fv.newVehicle, offers: fv.offers, match: fv.match, similar: fv.similar} as VehicleImportWithChoices;
    }

    const importsSchema = Yup.object().shape({
        // selectedVehicleId: yup.number().when('newVehicle', {
        //     is: (newVehicle: any) => newVehicle === undefined,
        //     then: () =>
        //         yup.number().required(),
        //     otherwise: () =>
        //         yup.number().nullable()
        // }),
        // newVehicle: yup.object().when('selectedVehicleId', {
        //     is: (selectedVehicleId: number | undefined) => selectedVehicleId === undefined,
        //     then: () =>
        //         yup.object().required(),
        //     otherwise: () =>
        //         yup.number().nullable()
        // }),
    });
    // const multiImportSchema = Yup.object().shape({
    //     imports: Yup.array().of(importsSchema),
    // });

    function handleSubmit(r: VehicleImportWithChoices[]) {
        const mapped = r.map(i => (
            {selectedVehicleId: parseNumber(i.selectedVehicleId?.toString()), offers: i.offers, newVehicle: i.newVehicle} as VehicleImport))
        props.execute(mapped)
    }

    function getBackgroundColor(imc: VehicleImportWithChoices,): string | null {
        if(!imc.selectedVehicleId) return "yellow"
        if(imc.match?.id && imc.match?.id === imc.selectedVehicleId) {
            return "green";
        }
        return null
    }

    return (
        <>
            <Container fluid >
            <Formik
                initialValues={initData}
                // validationSchema={multiImportSchema}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange }) => (
                    <Form>

                                <div>
                                    {initData.map((imc, index) => (
                                        <Card key={`Card-${index}`}>
                                        <div key={`vehicle-import-${index}`}>
                                            <h2>Vehicle input</h2>
                                            <Row className="g-2">
                                                <Col >
                                                    <strong>TSN:</strong>
                                                    <div>{imc.key.tsn}</div>
                                                </Col>
                                                <Col >
                                                    <strong>HSN:</strong>
                                                    <div>{imc.key.hsn}</div>
                                                </Col>
                                                <Col >
                                                    <strong>Trim:</strong>
                                                    <div>{imc.key.trimLevelNorm}</div>
                                                </Col>
                                                <Col >
                                                    <strong>Model:</strong>
                                                    <div>{imc.key.modelNorm}</div>
                                                </Col>
                                                <Col >
                                                    <strong>Brand:</strong>
                                                    <div>{imc.key.brandNorm}</div>
                                                </Col>
                                                <Col >
                                                    <strong>Body Type:</strong>
                                                    <div>{imc.key.bodyTypeNorm}</div>
                                                </Col>
                                            </Row>

                                            <BootstrapForm.Group className="mb-3">
                                                <BootstrapForm.Label htmlFor={`${index}.selectedVehicleId`}>Selected vehicle</BootstrapForm.Label>
                                                <Field
                                                    name={`${index}.selectedVehicleId`}
                                                    as={BootstrapForm.Select}
                                                    onChange={handleChange}
                                                    // style={{backgroundColor: getBackgroundColor(imc)}}
                                                >
                                                    <option key={`${index}-new`} value="" ><><>New: </><DropdownVehicle v={imc.newVehicle}/></></option>
                                                    {imc.similar.map((similar) => (
                                                        <option key={`${index}-${similar.id}`} value={similar.id} >{similar.id === imc.selectedVehicleId ? (<>Existing: </>) : (<>Potential: </>)}<DropdownVehicle v={similar}/></option>
                                                    ))}
                                                </Field>
                                                <ErrorMessage name={`${index}.role`} component="div" className="text-danger" />
                                            </BootstrapForm.Group>
                                            <Row>
                                                {imc.offers.map(offer => (<OffersPreview offer={offer}/>))}
                                            </Row>
                                        </div>
                                        </Card>
                                    ))}
                                </div>
                        <div className="mt-2 mb-5 d-grid gap-2 col-md-8 col-12 mx-auto">
                            <Button variant="primary" type="submit">
                                Confirm selection
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
            </Container>
        </>
    )
}

function DropdownVehicle(props: {v: VehicleImportDto}) {

    return (
        <Row>{`id:${props.v.id}, brand:${props.v.brandNorm} model:${props.v.modelNorm} tsn:${props.v.tsn}, hsn:${props.v.hsn}, trim:${props.v.trimLevelNorm}`}</Row>
    )
}

function OffersPreview(props: {offer: OfferMaster}) {

    // return(<>{JSON.stringify(props.offer)}</>)
    return(
        <>
            {`OfferNum: ${props.offer.offerNumber}, hsn: ${props.offer.hsn}, tsn: ${props.offer.tsn}, km: ${props.offer.kmPerYear}`}
        </>
    )
}

interface FormValues {
    file: File | null;
}
function MultiImportFileUpload(props: {upload: (formData: FormData) => void, loading: boolean }) {

    const initialValues: FormValues = {
        file: null,
    };

    const validationSchema = Yup.object().shape({
        file: Yup.mixed()
            .required("A file is required")
            .test("fileSize", "File is too large", value => {
                return value && (value as File).size <= 10048576; // 10MB limit
            })
            .test("type", "Unsupported file format", (value) => {
                return value && (value as File).name.endsWith('.xlsx');
            }),
    });

    const onSubmit = (
        values: FormValues,
    ) => {
        const formData = new FormData();
        formData.append('file', values.file!!);
        props.upload(formData)
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ setFieldValue }) => (
                <Form>
                    <BootstrapForm.Group>
                        <BootstrapForm.Label>Upload File</BootstrapForm.Label>
                        <BootstrapForm.Control
                            type="file" name="file" disabled={props.loading}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue("file", event.currentTarget.files![0]);
                            }}
                        />
                        <ErrorMessage name="file">
                            {(msg) => <Alert variant="danger">{msg}</Alert>}
                        </ErrorMessage>
                    </BootstrapForm.Group>

                    <div className="mt-5 mb-5 d-grid gap-2 col-md-8 col-12 mx-auto">
                        <Button variant="primary" type="submit">
                            Prepare
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

interface TenantMultiselectProps {
    selectedIds: number[];
    onChange: (selectedIds: (number)[]) => void;
}
function TenantMultiselectComponent({selectedIds, onChange,}: TenantMultiselectProps) {

    const {loading: loadingAll, data: dataAll, error:errorAll, execute:executeAll} = useGetTenants();

    if(!dataAll) return (<></>)
    if(loadingAll) return (<Spinner/>)

    const handleToggle = (id: number) => {
        const updatedIds = selectedIds.includes(id)
            ? selectedIds.filter(selectedId => selectedId !== id)
            : [...selectedIds, id];
        onChange(updatedIds);
    };

    return (
        <Row className="mt-1 mb-3">
            <h1>Select tenants</h1>
        <ListGroup>
            {dataAll.map(option => (
                <ListGroup.Item key={option.id} className="d-flex align-items-center">
                    <BootstrapForm.Check
                        type="checkbox"
                        id={`option-${option.id}`}
                        checked={selectedIds.includes(option.id)}
                        onChange={() => handleToggle(option.id)}
                        label={`${option.title}`}
                    />
                </ListGroup.Item>
            ))}
        </ListGroup>
        </Row>
    );
}

