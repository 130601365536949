import {useGetTenants} from "../../api/tenants/useTenants";
import {DatatableWrapper, TableBody, TableColumnType, TableHeader} from "react-bs-datatable";
import {TenantDto} from "../../model/TenantDto";
import {Button, Row, Table} from "react-bootstrap";
import React, {useState} from "react";
import Container from "react-bootstrap/Container";
import TenantModal from "../../components/tenant/TenantUploadModal";
import {useSaveTenant} from "../../api/tenants/useSaveTenant";
import {useNavigate} from "react-router-dom";
import {useSaveSupplier} from "../../api/suppliers/useSaveSuppliers";
import {SupplierDto} from "../../model/supplier/supplier";
import {useGetSuppliers} from "../../api/suppliers/useSuppliers";

type ArrayElementType = SupplierDto & {
    button: any;
};
export default function Suppliers() {
    const navigate = useNavigate();

    const {loading, data, error, execute} = useGetSuppliers();
    // const {loading: loadingSave, data: dataSave, error: errorSave, execute: executeSave} = useSaveSupplier();

    const header: TableColumnType<ArrayElementType>[] = [
        {title: 'Id', prop: 'id', isSortable: true, isFilterable: true},
        {title: 'Name', prop: 'name', isSortable: true, isFilterable: true},
        {title: 'Code', prop: 'code', isSortable: true, isFilterable: true},
        {
            prop: "button",
            cell: (row) => (
                <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => {
                        edit(row);
                    }}
                >
                    Edit
                </Button>
            )
        }
    ];

    function edit(row: SupplierDto) {
        navigate(`/suppliers/${row.id}`)
    }

    function rowClick(row: TenantDto) {
        console.log(row)
    }

    function newTenant() {
        navigate(`/supplier-new`)
    }

    return (
        <>
            <Container>
                <Row className="mt-5 mb-5">
                    <Button variant="primary" onClick={() => newTenant()}>Create Supplier</Button>
                </Row>
                <Row className="mt-5 mb-5">
                    <DatatableWrapper
                        body={data as ArrayElementType[]}
                        headers={header}
                    >
                        <Table className="custom-table">
                            <TableHeader/>
                            <TableBody
                                onRowClick={(row, event) => rowClick(row as TenantDto)}
                            />
                        </Table>
                    </DatatableWrapper>
                </Row>
            </Container>
        </>
    )
}