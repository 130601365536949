import {authAxios} from "./apiContext";
import {AuthData} from "../model/AuthData";
// import {configuration} from "../components/reusable/Configuration/configuration";
// import {customNotifier} from "../components/reusable/Notifications/notifier";

export async function refresh(refreshToken: string) {
    try {
        // const response = await axios.post<AuthData>(`${configuration.backend_host}/v1/admin/refreshToken`, {
        const response = await authAxios.post<AuthData>(`/v1/auth/refreshToken`, {
            refreshToken
        });
        return response.data;
    } catch (e) {
        // customNotifier.error("Error while refreshing token!");
        return Promise.reject(e);
    }
}