import React from "react";
import {Row, Col} from "react-bootstrap";
import {VehicleOverrideComponent} from "../../components/vehicle/VehicleOverrideComponent";
import VehicleEdit from "./VehicleEdit";


export default function VehicleOverride() {

    return (
        <>
            <Row>
                <Col xs={6}>
                    <VehicleEdit></VehicleEdit>
                </Col>
                <Col xs={6}>
                    <VehicleOverrideComponent></VehicleOverrideComponent>
                </Col>
            </Row>
        </>
    )
}