import {customAxios} from "../customAxios";
import {useEffect, useState} from "react";
import {SupplierDto} from "../../model/supplier/supplier";

export function useGetSuppliers() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<SupplierDto[]>([]);
    const [error, setError] = useState<Error>();

    useEffect(() => {
        execute();
    }, []);
    async function execute() {
        try {
            setLoading(true);
            const data = await getSuppliers();
            setData(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading, data, error, execute
    };
}

export async function getSuppliers(): Promise<SupplierDto[]> {
    const response = await customAxios.get<SupplierDto[]>('/v1/supplier', );
    return response.data;
}

