import {
    Button,
    Col,
    Form,
    FormControl,
    FormGroup,
    FormLabel,
    FormSelect, ListGroup,
    Row
} from "react-bootstrap";
import {FieldArray, Formik} from "formik";
import React, {FocusEventHandler, useEffect, useState} from "react";
import * as yup from "yup";
import {useNavigate} from "react-router-dom";
import {SalaryOptions} from "../../model/salary/SalaryOptions";
import {useSalaryOptions} from "../../api/users/useSalaryOptions";
import {useUserInfo} from "../../api/users/useUserInfo";
import Container from "react-bootstrap/Container";
import {
    CenterVerticallyAndHorizontally
} from "../../components/layout/CenterVerticallAndHorizontally/CenterVerticallyAndHorizontally";
import {useTranslation} from "react-i18next";
import UserInfoGenericFormGroup from "../../components/form/UserInfoGenericFormGroup";
import UserInfoFederalStatesFormGroup from "../../components/form/UserInfoFederalStatesFormGroup";
import UserInfoNumberSelectFormGroup from "../../components/form/UserInfoNumberSelectFormGroup";
import UserInfoBooleanRadioFormGroup from "../../components/form/UserInfoBooleanRadioFormGroup";
import UserInfoGenericSplitFormGroup from "../../components/form/UserInfoGenericSplitFormGroup";
import {useEditUserInfo} from "../../api/users/useEditUserInfo";
import {SalaryConversion} from "../../model/salary/UserInfo";
import notifier from "simple-react-notifications2";
import UserInfoGenericFlattenSplitFormGroup from "../../components/form/UserInfoGenericFlattenSplitFormGroup";

const salaryConversionTypes = ['BIKE_LEASING_UNDER_25', 'BIKE_LEASING_OVER_25', 'HARDWARE_LEASING'];

export function Salary() {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [options, setOptions] = useState<SalaryOptions>();
    const {
        loading: loadingOptions,
        data: dataOptions,
        error: errorOptions,
        execute: executeOptions
    } = useSalaryOptions();
    const {
        loading: loadingUserInfo,
        data: dataUserInfo,
        error: errorUserInfo,
        execute: executeUserInfo
    } = useUserInfo();
    const {
        loading: loadingEditUserInfo,
        data: dataEditUserInfo,
        error: errorEditUserInfo,
        execute: executeEditUserInfo
    } = useEditUserInfo();

    useEffect(() => {
        executeOptions();
        executeUserInfo();
    }, []);

    if (dataUserInfo === undefined || dataOptions === undefined) return (<div></div>)

    console.log(dataUserInfo)

    const schema = yup.object().shape({
        addressDto: yup.object().shape({
            firstName: yup.string().required(t('salary.required')),
            lastName: yup.string().required(t('salary.required')),
            street: yup.string().required(t('salary.required')),
            streetNumber: yup.string().required(t('salary.required')),
            place: yup.string().required(t('salary.required')),
            postcode: yup.string().required(t('salary.required')),
            state: yup.string().required(t('salary.required')),
            placeOfBirth: yup.string().required(t('salary.required')),
        }),
        salaryInfo: yup.object().shape({
            federalState: yup.string().required(t('salary.required')).oneOf(dataOptions.federalStates.map(v => v.code), t('salary.required')),
            dateOfBirth: yup.date().required(t('salary.required')),
            grossMonthlySalaryEur: yup.number().required(t('salary.required')).min(1, t('salary.min') + " 1").max(200000, t('salary.max') + " 200000"),
            taxClass: yup.number().required(t('salary.required')).oneOf(dataOptions.taxClass.map(v => v.code), t('salary.required')),
            spouseFactor: yup.number().when('taxClass', {
                is: (taxClass: number) => taxClass === 4,
                then: () =>
                    yup.number().required("This is required").min(0, t('salary.min') + " 0").max(1, t('salary.max') + " 1"),
                otherwise: () =>
                    yup.number().nullable()
            }),
            taxAllowance: yup.number().required(t('salary.required')).min(0, t('salary.min') + " 0").max(200000, t('salary.max') + " 200000"),
            children: yup.boolean().required(t('salary.required')),
            childrenUnder25: yup.number().required(t('salary.required')).oneOf(dataOptions.childrenUnder25.map(v => v.code), t('salary.required')),
            childrenAllowances: yup.number().required(t('salary.required')).oneOf(dataOptions.childrenAllowances.map(v => v.code), t('salary.required')),
            pensionInsurance: yup.boolean().required(t('salary.required')),
            unemploymentInsurance: yup.boolean().required(t('salary.required')),
            churchTax: yup.boolean().required(t('salary.required')),
            healthInsurance: yup.string().required(t('salary.required')).oneOf(['STATUTORY', 'PRIVATE']),
            basicRateHealthInsurance: yup.number().when('healthInsurance', {
                is: (healthInsurance: string) => healthInsurance === 'STATUTORY',
                then: () =>                      // .max(1000, t('salary.max') + " 1000")
                    yup.number().required("This is required").min(0, t('salary.min') + " 0").max(25, t('salary.max') + " 25"),
                otherwise: () =>
                    yup.number().nullable()
            }),
            additionalAmountHealthInsurance: yup.number().when('healthInsurance', {
                is: (healthInsurance: string) => healthInsurance === 'STATUTORY',
                then: () =>
                    yup.number().required("This is required").min(0, t('salary.min') + " 0").max(3, t('salary.max') + " 3"),
                otherwise: () =>
                    yup.number().nullable()
            }),
            beitragPkv: yup.number().when('healthInsurance', {
                is: (healthInsurance: string) => healthInsurance === 'PRIVATE',
                then: () =>
                    yup.number().required("This is required").min(0, t('salary.min') + " 0"),
                otherwise: () =>
                    yup.number().nullable()
            }),
            beitragPv: yup.number().when('healthInsurance', {
                is: (healthInsurance: string) => healthInsurance === 'PRIVATE',
                then: () =>
                    yup.number().required("This is required").min(0, t('salary.min') + " 0"),
                otherwise: () =>
                    yup.number().nullable()
            }),
            basisbeitragSteuerrelevant: yup.number().when('healthInsurance', {
                is: (healthInsurance: string) => healthInsurance === 'PRIVATE',
                then: () =>
                    yup.number().required("This is required").min(0, t('salary.min') + " 0"),
                otherwise: () =>
                    yup.number().nullable()
            }),
            arrivalKilometers: yup.number().required(t('salary.required')).min(0, t('salary.min') + " 0").max(10000, t('salary.max') + " 10000"),
            arrivalNumber: yup.number().required(t('salary.required')).min(0, t('salary.min') + " 0").max(32, t('salary.max') + " 32"),
            salaryConversions: yup.array().of(
                yup.object().shape({
                    conversationType: yup.string().oneOf(salaryConversionTypes).required('Type is required'),
                    leasingRate: yup.number().when('conversationType', {
                        is: (type: string) => type === 'BIKE_LEASING_UNDER_25' || type === 'BIKE_LEASING_OVER_25',
                        then: () =>
                            yup.number().required("This is required").required('Leasing rate is required'),
                        otherwise: () =>
                            yup.number().nullable()
                    }),
                    monetaryBenefitPrivateUse: yup.number().when('conversationType', {
                        is: (type: string) => type === 'BIKE_LEASING_UNDER_25' || type === 'BIKE_LEASING_OVER_25',
                        then: () =>
                            yup.number().required("This is required"),
                        otherwise: () =>
                            yup.number().nullable()
                    }),
                    monetaryBenefitCommuteToWork: yup.number().when('conversationType', {
                        is: (type: string) => type === 'BIKE_LEASING_UNDER_25' || type === 'BIKE_LEASING_OVER_25',
                        then: () =>
                            yup.number().required("This is required"),
                        otherwise: () =>
                            yup.number().nullable()
                    }),
                    salaryConversionAmount: yup.number().when('conversationType', {
                        is: (type: string) => type !== 'BIKE_LEASING_UNDER_25' && type !== 'BIKE_LEASING_OVER_25',
                        then: () =>
                            yup.number().required("This is required"),
                        otherwise: () =>
                            yup.number().nullable()
                    }),
                })
            ),
        })

    });


    return (
        <>
            <Container className="vh-100 px-0 ">
                <CenterVerticallyAndHorizontally>
                    <Formik
                        initialValues={dataUserInfo}
                        validationSchema={schema}
                        onSubmit={values => {
                            executeEditUserInfo(values).then(r => {
                                if(r.errors && r.errors.length > 0) notifier.warn(r.errors.join('\n'))
                                else {notifier.success('Saving success')}
                                executeUserInfo()
                            })
                        }}
                    >
                        {({handleSubmit, setFieldValue, handleChange, handleBlur, values, touched, errors}) => (
                            <>
                            <Form noValidate onSubmit={handleSubmit} className="d-grid w-100">
                                <Row>
                                    <Col xs={12}>
                                        <div>{JSON.stringify(errors, null, 2)}</div>
                                    </Col>

                                    <Col xs={12}>
                                        <div className="d-grid">
                                            <div className="d-grid mx-auto">
                                                <h1>{t('salary.info')}</h1>
                                            </div>
                                        </div>
                                        <UserInfoFederalStatesFormGroup name={"salaryInfo.federalState"} value={values.salaryInfo.federalState} options={dataOptions?.federalStates} onChange={handleChange} touched={touched} errors={errors} />

                                        <UserInfoGenericFormGroup type={"date"} name={"salaryInfo.dateOfBirth"} value={values.salaryInfo.dateOfBirth} onChange={handleChange} touched={touched} errors={errors}/>
                                        <UserInfoGenericFormGroup type={"number"} name={"salaryInfo.grossMonthlySalaryEur"} value={values.salaryInfo.grossMonthlySalaryEur} onChange={handleChange} touched={touched} errors={errors}/>
                                        <UserInfoGenericFormGroup type={"number"} name={"salaryInfo.monetaryBenefit"} value={values.salaryInfo.monetaryBenefit} onChange={handleChange} touched={touched} errors={errors}/>

                                        <UserInfoNumberSelectFormGroup name={"salaryInfo.taxClass"} value={values.salaryInfo.taxClass} options={dataOptions.taxClass} setFieldValue={setFieldValue} touched={touched} errors={errors}/>


                                        {
                                            values.salaryInfo.taxClass === 4 && (
                                                <UserInfoGenericFormGroup type={"number"} name={"salaryInfo.spouseFactor"} value={values.salaryInfo.spouseFactor} onChange={handleChange} touched={touched} errors={errors}/>
                                            )
                                        }

                                        <UserInfoGenericFormGroup type={"number"} name={"salaryInfo.taxAllowance"} value={values.salaryInfo.taxAllowance} onChange={handleChange} touched={touched} errors={errors}/>

                                        <UserInfoBooleanRadioFormGroup name={"salaryInfo.children"} value={values.salaryInfo.children} setFieldValue={setFieldValue} touched={touched} errors={errors}/>
                                        <UserInfoNumberSelectFormGroup name={"salaryInfo.childrenUnder25"} value={values.salaryInfo.childrenUnder25} options={dataOptions?.childrenUnder25} setFieldValue={setFieldValue} touched={touched} errors={errors}/>
                                        <UserInfoNumberSelectFormGroup name={"salaryInfo.childrenAllowances"} value={values.salaryInfo.childrenAllowances} options={dataOptions?.childrenAllowances} setFieldValue={setFieldValue} touched={touched} errors={errors}/>

                                        <UserInfoBooleanRadioFormGroup name={"salaryInfo.pensionInsurance"} value={values.salaryInfo.pensionInsurance} setFieldValue={setFieldValue} touched={touched} errors={errors}/>
                                        <UserInfoBooleanRadioFormGroup name={"salaryInfo.unemploymentInsurance"} value={values.salaryInfo.unemploymentInsurance} setFieldValue={setFieldValue} touched={touched} errors={errors}/>
                                        <UserInfoBooleanRadioFormGroup name={"salaryInfo.churchTax"} value={values.salaryInfo.churchTax} setFieldValue={setFieldValue} touched={touched} errors={errors}/>

                                        {
                                            options?.federalStates?.find(it => it.code === values.salaryInfo.federalState)?.akb === true && (
                                                <UserInfoBooleanRadioFormGroup name={"salaryInfo.akb"} value={values.salaryInfo.akb} setFieldValue={setFieldValue} touched={touched} errors={errors}/>
                                            )
                                        }

                                        <FormGroup className="mt-1 mb-3">
                                            <Row>
                                                <FormGroup className="mb-3">
                                                    <FormLabel>{t('salary.salaryInfo.healthInsurance')}</FormLabel>
                                                    <FormSelect
                                                        name="salaryInfo.healthInsurance"
                                                        value={values.salaryInfo.healthInsurance}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={!!touched.salaryInfo?.healthInsurance && !!errors.salaryInfo?.healthInsurance}
                                                    >
                                                        <option value="" label={t('salary.salaryInfo.healthInsurance')}/>
                                                        <option key={'healthInsurance-STATUTORY'} value={'STATUTORY'}>{'STATUTORY'}</option>
                                                        <option key={'healthInsurance-PRIVATE'} value={'PRIVATE'}>{'PRIVATE'}</option>
                                                    </FormSelect>
                                                    <FormControl.Feedback type="invalid">
                                                        {errors.salaryInfo?.healthInsurance}
                                                    </FormControl.Feedback>
                                                </FormGroup>
                                            </Row>
                                        </FormGroup>

                                        {
                                            values.salaryInfo.healthInsurance === 'STATUTORY' && (
                                                <>
                                                    <UserInfoGenericFormGroup type={"number"} name={"salaryInfo.basicRateHealthInsurance"} value={values.salaryInfo.basicRateHealthInsurance} onChange={handleChange} touched={touched} errors={errors}/>
                                                    <UserInfoGenericFormGroup type={"number"} name={"salaryInfo.additionalAmountHealthInsurance"} value={values.salaryInfo.additionalAmountHealthInsurance} onChange={handleChange} touched={touched} errors={errors}/>
                                                </>
                                            )
                                        }
                                        {
                                            values.salaryInfo.healthInsurance?.toString() === 'PRIVATE' && (
                                                <>
                                                    <UserInfoBooleanRadioFormGroup name={"salaryInfo.arbeitgeberzuschuss"} value={values.salaryInfo.arbeitgeberzuschuss} setFieldValue={setFieldValue} touched={touched} errors={errors}/>
                                                    <UserInfoGenericFormGroup type={"number"} name={"salaryInfo.beitragPkv"} value={values.salaryInfo.beitragPkv} onChange={handleChange} touched={touched} errors={errors}/>
                                                    <UserInfoGenericFormGroup type={"number"} name={"salaryInfo.beitragPv"} value={values.salaryInfo.beitragPv} onChange={handleChange} touched={touched} errors={errors}/>
                                                    <UserInfoGenericFormGroup type={"number"} name={"salaryInfo.basisbeitragSteuerrelevant"} value={values.salaryInfo.basisbeitragSteuerrelevant} onChange={handleChange} touched={touched} errors={errors}/>
                                                </>
                                            )
                                        }
                                        <UserInfoGenericFormGroup type={"number"} name={"salaryInfo.companyPensionPlan"} value={values.salaryInfo.companyPensionPlan} onChange={handleChange} touched={touched} errors={errors}/>

                                        <UserInfoGenericSplitFormGroup type={"number"} name={"salaryInfo.arrivalKilometers"} value={values.salaryInfo.arrivalKilometers} onChange={handleChange} touched={touched} errors={errors}/>
                                        <UserInfoGenericSplitFormGroup type={"number"} name={"salaryInfo.arrivalNumber"} value={values.salaryInfo.arrivalNumber} onChange={handleChange} touched={touched} errors={errors}/>

                                        <SalaryConversions items={values.salaryInfo.salaryConversions} touched={touched} errors={errors} onItemChange={handleChange}  handleBlur={handleBlur}/>


                                    </Col>
                                    <Col xs={12}>
                                        <div className="d-grid">
                                            <div className="d-grid mx-auto">
                                                <h1>{t('main.personalInfo')}</h1>
                                            </div>
                                        </div>
                                        <UserInfoGenericFormGroup type={"text"} name={"addressDto.firstName"} value={values.addressDto.firstName} onChange={handleChange} touched={touched} errors={errors}/>
                                        <UserInfoGenericFormGroup type={"text"} name={"addressDto.lastName"} value={values.addressDto.lastName} onChange={handleChange} touched={touched} errors={errors}/>
                                        <UserInfoGenericFormGroup type={"text"} name={"addressDto.street"} value={values.addressDto.street} onChange={handleChange} touched={touched} errors={errors}/>
                                        <UserInfoGenericFormGroup type={"text"} name={"addressDto.streetNumber"} value={values.addressDto.streetNumber} onChange={handleChange} touched={touched} errors={errors}/>
                                        <UserInfoGenericFormGroup type={"text"} name={"addressDto.place"} value={values.addressDto.place} onChange={handleChange} touched={touched} errors={errors}/>
                                        <UserInfoGenericFormGroup type={"text"} name={"addressDto.postcode"} value={values.addressDto.postcode} onChange={handleChange} touched={touched} errors={errors}/>
                                        <UserInfoGenericFormGroup type={"text"} name={"addressDto.state"} value={values.addressDto.state} onChange={handleChange} touched={touched} errors={errors}/>
                                        <UserInfoGenericFormGroup type={"text"} name={"addressDto.placeOfBirth"} value={values.addressDto.placeOfBirth} onChange={handleChange} touched={touched} errors={errors}/>
                                        <div className="mt-5 mb-5 d-grid gap-2 col-md-8 col-12 mx-auto">
                                            <Button variant="primary" type="submit">
                                                Submit
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                            </>
                        )}
                    </Formik>
                </CenterVerticallyAndHorizontally>
            </Container>
        </>
    )
}




interface SalaryConversionsProps {
    items: SalaryConversion[] | undefined;
    touched:  any;
    errors:  any;
    onItemChange: React.ChangeEventHandler<any>;
    handleBlur: FocusEventHandler<any>
}

export function SalaryConversions(props: SalaryConversionsProps) {

    const {t, i18n} = useTranslation();

    return (
        <FormGroup>
            <FormLabel>Salary conversions</FormLabel>

            <FieldArray name="salaryInfo.salaryConversions">

                {({push, remove}) => (
                    <>
                    <ListGroup >

                    {props.items && props.items.map((item, index) => (
                            <ListGroup.Item key={index}>
                                <FormGroup className="mb-3">
                                    <FormLabel>{t('salary.salaryInfo.salaryConversions')}</FormLabel>
                                    <FormSelect
                                        name={`salaryInfo.salaryConversions.${index}.conversationType`}
                                        value={item.conversationType}
                                        onChange={props.onItemChange}
                                        onBlur={props.handleBlur}
                                        isInvalid={!!props.touched.salaryInfo?.salaryConversions?.[index]?.conversationType && !!props.errors.salaryInfo?.salaryConversions?.[index]?.conversationType}
                                    >
                                        <option value="" label={t('salary.salaryConversions.conversationType')}/>
                                        {salaryConversionTypes.map((type, index) => (
                                            <option key={`salaryConversions-${index}-${type}`} value={type}>{type}</option>
                                        ))}
                                    </FormSelect>
                                    <FormControl.Feedback type="invalid">
                                        {props.errors.salaryInfo?.salaryConversions?.[index]?.conversationType}
                                    </FormControl.Feedback>
                                </FormGroup>

                                {item.conversationType && item.conversationType !== 'HARDWARE_LEASING' && (<UserInfoGenericFlattenSplitFormGroup type={"number"} name={'leasingRate'} propName={`salaryInfo.salaryConversions.${index}.leasingRate`} value={item.leasingRate} onChange={props.onItemChange} touched={props.touched} errors={props.errors}/>)}
                                {item.conversationType && item.conversationType !== 'HARDWARE_LEASING' && (<UserInfoGenericFlattenSplitFormGroup type={"number"} name={'monetaryBenefitPrivateUse'} propName={`salaryInfo.salaryConversions.${index}.monetaryBenefitPrivateUse`} value={item.monetaryBenefitPrivateUse} onChange={props.onItemChange} touched={props.touched} errors={props.errors}/>)}
                                {item.conversationType && item.conversationType === 'BIKE_LEASING_OVER_25' && (<UserInfoGenericFlattenSplitFormGroup type={"number"} name={'monetaryBenefitCommuteToWork'} propName={`salaryInfo.salaryConversions.${index}.monetaryBenefitCommuteToWork`} value={item.monetaryBenefitCommuteToWork} onChange={props.onItemChange} touched={props.touched} errors={props.errors}/>)}
                                {item.conversationType && item.conversationType === 'HARDWARE_LEASING' && (<UserInfoGenericFlattenSplitFormGroup type={"number"} name={'salaryConversionAmount'} propName={`salaryInfo.salaryConversions.${index}.salaryConversionAmount`} value={item.salaryConversionAmount} onChange={props.onItemChange} touched={props.touched} errors={props.errors}/>)}
                                <Button variant="danger" size="sm"
                                        onClick={() => remove(index)}>Remove</Button>

                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                        <Button size="sm" onClick={() => push(newSalaryConversion())} variant="primary">Add Salary conversion</Button>

                    </>
                )}
            </FieldArray>
        </FormGroup>
    )
}

function newSalaryConversion(): SalaryConversion {
    return {
        id: undefined,
        conversationType: undefined,
        leasingRate: undefined,
        monetaryBenefitPrivateUse: undefined,
        monetaryBenefitCommuteToWork: undefined,
        salaryConversionAmount: undefined,
    }
}