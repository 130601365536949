import React, {useEffect} from "react";
import {Button, Col, Row, Table} from "react-bootstrap";
import {DatatableWrapper, TableBody, TableColumnType, TableHeader} from "react-bs-datatable";
import Container from "react-bootstrap/Container";
import {SelectedTenantProvider, useSelectedTenant} from "../../components/tenant/SelectedTenantContext";
import {SelectTenant} from "../../components/tenant/SelectTenant";
import {useEnableImport} from "../../api/imports/useDisableEnableImports";
import {useGetUsers} from "../../api/users/useUsers";
import {TenantUserDto, UserDto} from "../../model/Users";
import {useNavigate} from "react-router-dom";
import GenericFileUploadModal from "../../components/file/GenericFileUploadModal";
import {useUsersImport} from "../../api/users/usersImport";
import InviteModal from "../../components/users/InviteModal";


export function TenantUsers() {

    return (
        <SelectedTenantProvider>
            <SelectTenant/>
            <Users></Users>
        </SelectedTenantProvider>
    )
}

type ArrayElementType = TenantUserDto & {
    button: any;
};

export default function Users() {
    const navigate = useNavigate();
    const {loading: loadingImport, error: errorImport, execute: executeImport} = useUsersImport();

    const {loading, data, error, execute} = useGetUsers();
    const {loading: loadingEnable, error: errorEnable, execute: executeEnable} = useEnableImport();
    const { selectedTenant, setSelectedTenant } = useSelectedTenant();

    useEffect(() => {
        if(selectedTenant) {
            execute(selectedTenant.id);
        }
    }, [selectedTenant]);

    function edit(id: number) {
        navigate(`/tenant/${selectedTenant?.id}/users/${id}`)
    }

    function refresh() {
        if(selectedTenant) {
            execute(selectedTenant.id);
        }
    }


    const header: TableColumnType<ArrayElementType>[] = [
        {title: 'Username', prop: 'id', isSortable: true, isFilterable: true},
        {title: 'Email', prop: 'email', isSortable: true, isFilterable: true},
        {title: 'Confirmed', prop: 'emailConfirmed', isSortable: true, isFilterable: true},
        {title: 'Username', prop: 'username', isSortable: true, isFilterable: true},
        {title: 'First Name', prop: 'firstName', isSortable: true, isFilterable: true},
        {title: 'Last Name', prop: 'lastName', isSortable: true, isFilterable: true},
        {title: 'Role', prop: 'role', isSortable: true, isFilterable: true,
            cell: (row) => (<div>{row.role.name}</div>)},
        {
            prop: "button",
            cell: (row) => (
                <Button variant="outline-primary" size="sm"
                    onClick={() => edit(row.id)}>
                    Edit
                </Button>
            )
        }
    ];

    return (
        <>
                <Container>
                    <Row>
                        <Col>
                        <GenericFileUploadModal execute={executeImport} type={['.csv']} buttonTitle={'Import users file'}
                                                tenantId={selectedTenant?.id} title={'Import users'}/>
                        </Col>
                        <Col><InviteModal tenantId={selectedTenant?.id} callback={refresh}></InviteModal></Col>

                    </Row>

                    <Row>
                    <DatatableWrapper body={data as ArrayElementType[]} headers={header}>
                        <Table>
                            <TableHeader/>
                            <TableBody/>
                        </Table>
                    </DatatableWrapper>
                    </Row>
                </Container>
        </>
    )
}
