import {useState} from "react";
import {customAxios} from "../customAxios";
import {UserDto, UserInfoResponse} from "../../model/salary/UserInfo";

export function useEditUserInfo() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<UserInfoResponse>();
    const [error, setError] = useState<Error>();

    async function execute(dto: UserDto) {
        try {
            setLoading(true);
            const data = await editUserInfo(dto);
            setData(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {loading, data, error, execute};
}


export async function editUserInfo(dto: UserDto): Promise<UserInfoResponse> {
    const response = await customAxios.put<UserInfoResponse>(`/v1/user/info`, dto);
    return response.data;
}