import {OrderWorkflowStep} from "../../../model/order/orderWorkflow";
import {Order} from "../../../model/order/order";
import Container from "react-bootstrap/Container";
import {PreviewContract} from "./PreviewContract";
import {UserStepsFinished} from "./UserStepsFinished";
import {AdminSteps} from "./AdminSteps";


export interface CommonStepProps {
    step: OrderWorkflowStep;
    orderId: number;
    reload: () => void;
}
type ComponentMap<P = {}> = {
    [key: string]: React.ComponentType<P>;
};

const components: ComponentMap<CommonStepProps> = {
    'PREVIEW_CONTRACT' : PreviewContract,
    'USER_STEPS_FINISHED' : UserStepsFinished,
    'TENANT_ADMIN_CLICK_CONFIRM' : AdminSteps,
    'ADMIN_CONFIRM' : AdminSteps,
    'LEASING_ACTIVE' : AdminSteps,
};

export function DynamicStepComponentRenderer(props:  CommonStepProps) {

    const Component = components[props.step.stepType];

    if (Component) {
        return (
            <>
                <Container style={{backgroundColor: 'rgba(174,220,171,0.1)',}}>
                    <Component {...props} />
                </Container>
            </>
        );
    } else {
        return <div>Component not found</div>;
    }

}