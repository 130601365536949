import {customAxios} from "../customAxios";
import {useState} from "react";
import {RoleDto} from "../../model/Users";

export function useGetRoles() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<RoleDto[]>([]);
    const [error, setError] = useState<Error>();

    async function execute(tenantId: number) {
        try {
            setLoading(true);
            const data = await getRoles(tenantId);
            setData(data);
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading, data, error, execute
    };
}

export async function getRoles(tenantId: number): Promise<RoleDto[]> {
    const response = await customAxios.get<RoleDto[]>(`/v1/tenant/${tenantId}/roles`, );
    return response.data;
}

