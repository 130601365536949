import {authAxios} from "./apiContext";
import {AuthData} from "../model/AuthData";
// import {configuration} from "../components/reusable/Configuration/configuration";
// import {customNotifier} from "../components/reusable/Notifications/notifier";

export async function authenticate(username: string, password: string) {
    try {
        // const response = await axios.post<AuthData>(`${configuration.backend_host}/v1/admin/login`, {
        const response = await authAxios.post<AuthData>(`/v1/auth/login`, { /* pogledaj gornji red */
            username,
            password
        })
        if (!response.data.authenticated) {
            // customNotifier.error("Incorrect credentials!");
        }
        return response.data;
    }
    catch(error) {
        // customNotifier.error("Error while authenticating!");
        return Promise.reject(error);
    }
}