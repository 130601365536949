import {useState} from "react";
import {CountersignDtoWithOrders} from "../../model/order/countersignithOrders";
import {customAxios} from "../customAxios";
import notifier from "simple-react-notifications2";

export function useGetCountersign() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<CountersignDtoWithOrders>();
    const [error, setError] = useState<Error>();

    async function execute(tenantId: number, countersignId: number) {
        try {
            setLoading(true);
            const fetched = await getCountersign(tenantId, countersignId);
            setData(fetched);
            return fetched;
        }
        catch (e) {
            setError(e as Error);
            notifier.error((e as Error).message)
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {loading, data, error, execute};
}



export async function getCountersign(tenantId: number, countersignId: number): Promise<CountersignDtoWithOrders> {
    const response = await customAxios.get<CountersignDtoWithOrders>(`/v1/order/tenant/${tenantId}/countersign/${countersignId}`);
    return response.data;
}