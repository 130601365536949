import {Form, FormControl, FormControlProps, FormGroup, FormLabel, FormSelect} from "react-bootstrap";
import {Field, FormikErrors, FormikTouched} from "formik";
import React from "react";
import {useTranslation} from "react-i18next";
import {Bundesland} from "../../model/salary/SalaryOptions";

interface UserInfoFederalStatesFieldProps {
    name: string;
    value: string | undefined;
    options: Bundesland[];
    onChange: React.ChangeEventHandler<HTMLSelectElement>;
    touched: FormikTouched<{ [key: string]: any }>;
    errors: FormikErrors<{ [key: string]: any }>;
}

export default function UserInfoFederalStatesFormGroup(
    props: UserInfoFederalStatesFieldProps
) {
    const {t, i18n} = useTranslation();
    const error = props.errors[props.name];

    return (
        <>
            <FormGroup className="mb-3">
                <FormLabel>{t(`salary.${props.name}`)}</FormLabel>
                <FormSelect
                    name={props.name}
                    value={props.value}
                    onChange={props.onChange}
                    isInvalid={!!props.touched[props.name] && !!props.errors[props.name]}
                >
                    <option value="" label={t(`salary.${props.name}`)}/>
                    {props.options.map((m, i) => {
                        return (<option key={i} value={m.code}>{m.name}</option>)
                    })}
                </FormSelect>
                {error && (
                    <FormControl.Feedback type="invalid">
                        {error.toString()}
                    </FormControl.Feedback>
                )}
            </FormGroup>
        </>

    )
}

