import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Row, Table} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {useTranslation} from "react-i18next";
import notifier from "simple-react-notifications2";
import {Loading} from "../../components/Loading";
import {useGetCountersign} from "../../api/orders/useGetCountersign";
import {useCheckCountersign} from "../../api/orders/useCheckCountersign";
import {useSelectedTenant} from "../../components/tenant/SelectedTenantContext";
import {OrderCountersignDto, OrderDocumentDto} from "../../model/order/orderFullDto";
import {useDownloadSignedContract} from "../../api/orders/useDownloadSignedContract";


export default function OrdersCountersign() {

    const {loading, data, error, execute} = useGetCountersign();
    const {loading: loadingCheck, data: signCheck, error: errorCheck, execute: executeCheck} = useCheckCountersign();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const {countersignId, tenantId} = useParams();
    const {loading: loadingContract, data: dataContract, error: errorContract, execute: executeContract,} = useDownloadSignedContract();


    useEffect(() => {
        fetch()
    }, [tenantId, countersignId]);

    function fetch() {
        if(tenantId && countersignId && !Number.isNaN(countersignId)) {
            execute(Number.parseInt(tenantId), Number.parseInt(countersignId));
        }
    }

    if(loading || loadingCheck) return (<Loading/>)
    if(!data) return (<div>No data</div>)


    function check() {
        if(tenantId && countersignId && !Number.isNaN(countersignId)) {
            executeCheck(Number.parseInt(tenantId), Number.parseInt(countersignId))
                .then(r => fetch())
                .catch(e => notifier.error("Error"))
        }
    }

    function sign() {
        window.open(data?.url)
    }

    function downloadDoc(orderId: number, documentId: number) {
        executeContract(orderId, documentId).then(r => {
            window.open(r)
        })
    }
    function getCountersigned(order: OrderCountersignDto): OrderDocumentDto | undefined {
        const doc = order.documents.find(d => d.type === "CONTRACT_COUNTERSIGNED")
        return doc
    }
    function downloadCountersignedDoc(order: OrderCountersignDto) {
        const doc = getCountersigned(order)
        if(doc) {
            downloadDoc(order.id, doc.id)
        }
    }

    return (
        <>
            <Container>
                <Row className="mt-2">
                    <Col className="text-end">
                        {!data.finished && (
                            <>
                                <Button className="m-1" variant="primary" onClick={sign}>
                                    {/*{t('user.invitePeople')}*/}
                                    Sign
                                </Button>
                                <Button className="m-1" variant="primary" disabled={loadingCheck} onClick={check}>
                                    {/*{t('user.invitePeople')}*/}
                                    Check
                                </Button>
                            </>
                        )}


                    </Col>

                </Row>
                <Row>
                    <p>Finished: {data.finished ? 'True' : 'False'}</p>
                    <p>Success: {data.success ? 'True' : 'False'}</p>
                </Row>
                <Row>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Email</th>
                            <th>Name</th>
                            <th>Vehicle</th>
                            {data.success && (<th>Contract</th>)}
                            {/*<th>View Order</th>*/}
                        </tr>
                        </thead>
                        <tbody>
                        {data.orders.map((row) => (
                            <tr key={row.id}>
                                <td>{row.id}</td>
                                <td>{row.user.email}</td>
                                <td>{row.user.lastName}, {row.user.firstName}</td>
                                <td>{row.vehicle.brand}, {row.vehicle.model}</td>

                                {data.success && (
                                    <td>
                                        <Button disabled={loadingContract} onClick={() => downloadCountersignedDoc(row)}>Countersigned</Button>
                                    </td>
                                )}

                                {/*<td>*/}
                                {/*    {row.documents.map(doc => (*/}
                                {/*        <Button disabled={loadingContract} onClick={() => downloadDoc(row.id, doc.id)}>{doc.name}</Button>*/}
                                {/*    ))}*/}
                                {/*</td>*/}
                                {/*<td><Button onClick={() => edit(row.id)}>View</Button></td>*/}
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Row>

            </Container>
        </>
    )
}