import {LeasePackageImportDto} from "../../../model/Imports";
import {useNavigate} from "react-router-dom";
import {useDisableImport, useEnableImport} from "../../../api/imports/useDisableEnableImports";
import {useDisableImportPackage, useEnableImportPackage} from "../../../api/imports/useDisableEnableImportsPackages";
import {useDisableImportVehicle, useEnableImportVehicle} from "../../../api/imports/useDisableEnableImportsVehicles";
import notifier from "simple-react-notifications2";
import {Accordion, Button, Col, Row} from "react-bootstrap";
import {Loading} from "../../../components/Loading";
import React from "react";


export function VehicleWithPackages(
    props: {import: LeasePackageImportDto, reload: () => void}
) {

    const navigate = useNavigate()

    const {loading: loadingEnable, error: errorEnable, execute: executeEnable} = useEnableImport();
    const {loading: loadingDisable, error: errorDisable, execute: executeDisable} = useDisableImport();
    const {loading: loadingEnablePackage, error: errorEnablePackage, execute: executeEnablePackage} = useEnableImportPackage();
    const {loading: loadingDisablePackage, error: errorDisablePackage, execute: executeDisablePackage} = useDisableImportPackage();
    const {loading: loadingEnableVehicle, error: errorEnableVehicle, execute: executeEnableVehicle} = useEnableImportVehicle();
    const {loading: loadingDisableVehicle, error: errorDisableVehicle, execute: executeDisableVehicle} = useDisableImportVehicle();


    function enable(id: number) {
        executeEnable(id).then(r => {
            notifier.success("enabled")
        }).finally(() => props.reload())
    }

    function disable(id: number) {
        executeDisable(id).then(r => {
            notifier.warn("disabled")
        }).finally(() => props.reload())
    }

    // if(loadingEnable || loadingDisable) return (<Loading/>)

    return (
        <>
            <Row>
                <Col>
                    {(loadingDisable || loadingEnable) && (<Loading/>)}
                    <Button className="button-outline" size={"sm"} onClick={() => {
                        if(props.import.enabled) {disable(props.import.id)} else {enable(props.import.id)}
                    }}>
                        {props.import.enabled ? 'Disable' : 'Enable'}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Accordion>

                    {props.import.vehiclePackages.map((it, index) => (
                        <Accordion.Item key={'Ims-v' + index} eventKey={index.toString()}>
                            <Accordion.Header>
                                <Row>
                                    <Col>Id: {it.vehicle.id}</Col>
                                    <Col>{it.vehicle.brand}</Col>
                                    <Col>{it.vehicle.model}</Col>
                                    <Col>Doors: {it.vehicle.numberOfDoors}</Col>
                                    <Col>KW: {it.vehicle.maximumPowerKw}</Col>
                                    <Col>Range: {it.vehicle.rangeKm}</Col>
                                    <Col>Trim: {it.vehicle.trimLevel}</Col>
                                    <Col>Body: {it.vehicle.bodyType}</Col>
                                    <Col>
                                        <div onClick={() => navigate(`/vehicles/${it.vehicle.id}`)} className="button btn-outline-primary">Edit</div>
                                        <div onClick={() => navigate(`/vehicles/${it.vehicle.id}/imports/${props.import.id}/override`)} className="button btn-outline-primary">Add override</div>
                                        <div onClick={() => executeDisableVehicle(props.import.id, it.vehicle.id).finally(props.reload)} className="button btn-outline-primary">Deactivate for vehicle</div>
                                        <div onClick={() => executeEnableVehicle(props.import.id, it.vehicle.id).finally(props.reload)} className="button btn-outline-primary">Activate for vehicle</div>
                                    </Col>
                                </Row>
                            </Accordion.Header>
                            <Accordion.Body>
                                {it.leasePackages && it.leasePackages.sort((a, b) => a.kmPerYear - b.kmPerYear).map((p, indexP) => (
                                    <Row key={`pcks-${indexP}`}>
                                        <Col>{p.kmPerYear}km</Col>
                                        <Col>{p.durationMonths} Months</Col>
                                        <Col>id: {p.offerId}</Col>
                                        <Col>Monthly: {p.totalMonthlyNetCostEur}</Col>
                                        <Col>Monthly gross: {p.totalMonthlyGrossCostEur}</Col>
                                        <Col>Monthly comparison gross: {p.grossComparisonCostEur}</Col>
                                        <Col>Gross: {p.grossRetailPriceEur}</Col>
                                        <Col>Tires: {p.tires}</Col>
                                        <Col>Active: {p.active ? 'True' : 'False'}</Col>
                                        <Col>
                                            {p.active && (
                                                <div onClick={() => executeDisablePackage(props.import.id, p.id).finally(props.reload)} className="button btn-outline-primary">Deactivate</div>
                                            )}
                                            {!p.active && (
                                                <div onClick={() => executeEnablePackage(props.import.id, p.id).finally(props.reload)} className="button btn-outline-primary">Activate</div>
                                            )}
                                        </Col>
                                    </Row>
                                ))}
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>

            </Row>
        </>
    )
}

