import {customAxios} from "../customAxios";
import {useState} from "react";

export function useEnableImportPackage() {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    async function execute(importId: number, packageId: number) {
        try {
            setLoading(true);
            await enableImportPackage(importId, packageId);
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading, error, execute
    };
}

export async function enableImportPackage(importId: number, packageId: number): Promise<void> {
    const response = await customAxios.put<void>(`/v1/lease-packages/import/${importId}/package/${packageId}`, );
    return response.data;
}

export function useDisableImportPackage() {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    async function execute(importId: number, packageId: number) {
        try {
            setLoading(true);
            await disableImportPackage(importId, packageId);
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading, error, execute
    };
}

export async function disableImportPackage(importId: number, packageId: number): Promise<void> {
    const response = await customAxios.delete<void>(`/v1/lease-packages/import/${importId}/package/${packageId}`, );
    return response.data;
}

