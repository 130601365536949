import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const fallbackLanguage = 'en';

export function getFallbackLanguage() {
  return fallbackLanguage;
}

export interface Language {
  languageName: string;
  languageAbbreviation: string;
}

export const availableLanguages = [
  { languageName: 'Deutsch', languageAbbreviation: 'de' },
  { languageName: 'English', languageAbbreviation: 'en' },
];

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(Backend)
    // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'de',
    partialBundledLanguages: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
    resources: {
      de: {
        translation: {
          main: {
            home: 'Home',
            orders: 'Bestellungen',
            login: 'Anmeldung',
            carSearch: 'Autosuche',
            profile: 'Profil',
            personalInfo: 'Persönliche Informationen',
            faq: 'FAQ',
            about: 'Über uns',
            filter: "Anwenden",
            clear: "Zurücksetzen"
          },
          vehicle: {
              brand: "Marke",
              model: "Modell",
              range: "Reichweite",
              yourNetRate: "Ihre Netto-Rate",
              privateLeasing: "Privates Leasing",
              savings: "Ersparnisse",
              listPrice: "Listenpreis",
              duration: "Leasingdauer",
              months: "Monate",
              kmPerYear: "Laufleistung p.a.",
              tires: "Reifen",
              tiresEnum: {
                  ALL_SEASON: "Ganzjahresreifen",
                  SUMMER_WINTER_SETS: "Sommer und Winterreifen",
              },
              color: "Farbe",
              delivery: "Lieferung",
              order: "Bestellen",
              nextStep: "Nächster Schritt"
          },
          salary: {
              salary: 'Gehalt',
              info: 'Gehaltsinformationen',
              salaryInfo: {
                  federalState: "Bundesland",
                  healthInsurance: "Krankenversicherung",
                  healthInsuranceStatutory: "Gesetzlich",
                  healthInsurancePrivate: "Privat",
                  basicRateHealthInsurance: "Basissatz der Krankenkasse in %",
                  additionalAmountHealthInsurance: "Zusatzbetrag der Krankenkasse in %",
                  spouseFactor: "Ehegattenfaktor",
                  dateOfBirth: "Geburtsdatum",
                  contributionPrivateHealthInsuranceEur: "Versicherungsbeitrag",
                  contributionPrivateCareInsuranceEur: "Beitrag private Pflegepflichtversicherung",
                  grossMonthlySalaryEur: "Monatsbruttogehalt",
                  taxClass: "Steuerklasse",
                  register: "Anmelden",
                  basicRatePrivateHealthInsurance: "Basisprämie / Basistarif",
                  taxAllowance: "Steuerfreibetrag",
                  pension: "Rentenversicherung",
                  children: "Kinder",
                  arrivalKilometers: "Wohnung - Arbeitsstätte in km",
                  childSupplement: "Kinderzuschlag",
                  arrivalNumber: "Anzahl mtl. Fahrten zur Arbeitsstätte in Tage",
                  churchTax: "Kirchensteuer",
                  monetaryBenefit: "monetaryBenefit",
                  childrenUnder25: "childrenUnder25",
                  childrenAllowances: "childrenAllowances",
                  pensionInsurance: "pensionInsurance",
                  unemploymentInsurance: "unemploymentInsurance",
                  grossPrice: "grossPrice",
                  monthlyNet: "monthlyNet",
                  contribution: "contribution",
                  companyPensionPlan: "companyPensionPlan",
              },
              save: "Speichern",
              yes: "Ja",
              no: "Nein",
              auto: "Auto",
              required: "Dieses Feld ist erforderlich",
              min: "Minimum",
              max: "Maximum",
              addressDto: {
                  firstName: "Vorname",
                  lastName: "Nachname",
                  street: "Strasse",
                  streetNo: "Hausnummer",
                  place: "Ort",
                  postCode: "PLZ",
                  state: "Staat",
                  placeOfBirth: "placeOfBirth",
              }
          }
        },
      },
    },
  });

export default i18n;
