import {useGetVehicleOverride} from "../../api/vehicle/useVehicleOverrides";
import {useEditVehicleOverride} from "../../api/vehicle/useEditVehicleOverrides";
import {useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {AdminVehicleFullDto, VehicleImportOverride} from "../../model/Vehicle";
import {Loading} from "../Loading";
import notifier from "simple-react-notifications2";
import {Field, FieldArray, Formik} from "formik";
import {Button, Form, FormControl, FormGroup, FormLabel} from "react-bootstrap";
import * as yup from "yup";


export function VehicleOverrideComponent() {

    const {loading: loadingGetOverride, data: dataGetOverride, error: errorGetOverride, execute: executeGetOverride} = useGetVehicleOverride();
    const {loading: loadingEditOverride, data: dataEditOverride, error: errorEditOverride, execute: executeEditOverride} = useEditVehicleOverride();
    const {vehicleId, importId} = useParams();

    useEffect(() => {
        executeOverride();
    }, [vehicleId, importId]);

    function executeOverride() {
        if (vehicleId && !Number.isNaN(vehicleId) && importId && !Number.isNaN(importId)) {
            executeGetOverride(Number.parseInt(vehicleId), Number.parseInt(importId));
        }
    }
    function executeSaveOverride(dto: VehicleImportOverride) {
        if (vehicleId && !Number.isNaN(vehicleId) && importId && !Number.isNaN(importId)) {
            executeEditOverride(Number.parseInt(vehicleId), Number.parseInt(importId), dto);
        }
    }

    function save(dto: VehicleImportOverride) {
        if (vehicleId && !Number.isNaN(vehicleId) && importId && !Number.isNaN(importId)) {
            executeEditOverride(Number.parseInt(vehicleId), Number.parseInt(importId), dto)
                .then(r => {
                    notifier.success("Saved")
                    executeOverride();
                })
                .catch(e => {
                    notifier.error("Error saving");
                    console.log(e.toString())
                })
        }
    }

    if (loadingGetOverride || loadingEditOverride || !dataGetOverride) return (<Loading/>);


    return (
        <>
            <Formik initialValues={dataGetOverride} validationSchema={yup.object()} onSubmit={values => save(values)}>
                {({handleSubmit, setFieldValue, handleChange, handleBlur, values, touched, errors}) => (
                    <Form onSubmit={handleSubmit}>

                        <FormGroup>
                            <FormLabel>Colors</FormLabel>
                            <FieldArray name="colors">
                                {({push, remove}) => (
                                    <div>
                                        {values.colors.map((item, index) => (
                                            <div key={index}>
                                                <FormGroup>
                                                    <FormLabel>Color {index + 1}</FormLabel>
                                                    <FormControl
                                                        as="input"
                                                        name={`colors.${index}`}
                                                        value={values.colors[index]}
                                                        onChange={handleChange}
                                                        isInvalid={!!(touched.colors && errors.colors && errors.colors[index])}
                                                    />
                                                    <FormControl.Feedback
                                                        type="invalid">{errors.colors && errors.colors[index]}</FormControl.Feedback>
                                                    <Button size="sm" variant="danger"
                                                            onClick={() => remove(index)}>Remove</Button>
                                                </FormGroup>
                                            </div>
                                        ))}
                                        <Button size="sm" onClick={() => push('')} variant="primary">Add New Color</Button>
                                    </div>
                                )}
                            </FieldArray>
                        </FormGroup>

                        <FormGroup>
                            <FormLabel>Delivery</FormLabel>
                            <FieldArray name="delivery">
                                {({push, remove}) => (
                                    <div>
                                        {values.delivery.map((item, index) => (
                                            <div key={index}>
                                                <FormGroup>
                                                    <FormLabel>Delivery {index + 1}</FormLabel>
                                                    <FormControl
                                                        as="input"
                                                        value={values.delivery[index]}
                                                        name={`delivery.${index}`}
                                                        onChange={handleChange}
                                                        isInvalid={!!(touched.delivery && errors.delivery && errors.delivery[index])}
                                                    />
                                                    <FormControl.Feedback
                                                        type="invalid">{errors.delivery && errors.delivery[index]}</FormControl.Feedback>
                                                    <Button size="sm" variant="danger"
                                                            onClick={() => remove(index)}>Remove</Button>
                                                </FormGroup>
                                            </div>
                                        ))}
                                        <Button size="sm" onClick={() => push('')} variant="primary">Add New Delivery</Button>
                                    </div>
                                )}
                            </FieldArray>
                        </FormGroup>


                        <FormGroup>
                            <FormLabel>Special Equipment</FormLabel>
                            <FormControl
                                as="textarea"
                                style={{
                                    height: '100px', // This can be set based on your design requirements
                                    overflowY: 'auto',
                                    resize: 'vertical' // This allows users to resize the textarea vertically
                                }} onChange={handleChange}
                                name="specialEquipment"
                                value={values?.specialEquipment}
                                isInvalid={!!touched.specialEquipment && !!errors.specialEquipment}
                            />
                            <FormControl.Feedback
                                type="invalid">{errors.specialEquipment}</FormControl.Feedback>
                        </FormGroup>


                        <FormGroup>
                            <FormLabel>Delivery from (weeks)</FormLabel>
                            <Field name="deliveryFromWeeks" onChange={handleChange} value={values?.deliveryFromWeeks} type="number" as={Form.Control}
                                   isInvalid={touched.deliveryFromWeeks && !!errors.deliveryFromWeeks}/>
                            <FormControl.Feedback type="invalid">{errors.deliveryFromWeeks}</FormControl.Feedback>
                        </FormGroup>

                        <FormGroup>
                            <FormLabel>Delivery to (weeks)</FormLabel>
                            <Field name="deliveryToWeeks" onChange={handleChange} value={values?.deliveryToWeeks} type="number" as={Form.Control}
                                   isInvalid={touched.deliveryToWeeks && !!errors.deliveryToWeeks}/>
                            <FormControl.Feedback type="invalid">{errors.deliveryToWeeks}</FormControl.Feedback>
                        </FormGroup>


                        <div className="mt-5 mb-5 d-grid gap-2 col-md-8 col-12 mx-auto">
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>

        </>
    )
}