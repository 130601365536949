import {customAxios} from "../customAxios";
import {useEffect, useState} from "react";
import {AdminVehicleFullDto, VehicleSimpleDto} from "../../model/Vehicle";
import {updateTenant} from "../tenants/useUpdateTenant";
import {createTenant} from "../tenants/useCreateTenant";
import {TenantDto} from "../../model/TenantDto";
import {UserDto} from "../../model/Users";

export function useSaveUser() {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<UserDto>();
    const [error, setError] = useState<Error>();

    async function execute(tenantId: number,  dto: UserDto) {
        try {
            setLoading(true);
            if(dto.id > 0) {
                setData(await updateUser(tenantId, dto, dto.id))
            } else {
                setData(await createUser(tenantId, dto))
            }
            return data;
        }
        catch (e) {
            setError(error);
            return Promise.reject(e);
        }
        finally {
            setLoading(false);
        }
    }

    return {
        loading, data, error, execute
    };
}

export async function createUser(tenantId: number, dto: UserDto): Promise<UserDto> {
    const response = await customAxios.post<UserDto>(`/v1/tenant/${tenantId}/users`, dto);
    return response.data;
}

export async function updateUser(tenantId: number, dto: UserDto, id: number): Promise<UserDto> {
    const response = await customAxios.put<UserDto>(`/v1/tenant/${tenantId}/users/${dto.id}`, dto);
    return response.data;
}
