import React, {useState} from "react";
import {CommonStepProps} from "./DynamicStepComponentRenderer";
import {Button} from "react-bootstrap";
import ConfirmModal from "../../modals/ConfirmModal";
import {useTranslation} from "react-i18next";
import {useStepFunctions} from "../../../api/steps/useStepFunctions";
import notifier from "simple-react-notifications2";


export function ClickConfirm(props: CommonStepProps) {
    const {t, i18n} = useTranslation();
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [showDeclineModal, setShowDeclineModal] = useState(false)
    const {loading, error, execute, accept, decline} = useStepFunctions();

    function executeClicked() {
        setShowConfirmModal(true)
    }

    function executeDeclineClicked() {
        setShowDeclineModal(true)
    }

    function executeConfirm() {
        accept(props.orderId, props.step.status?.id!!).then(res => {
            notifier.success("Success")
            props.reload()
        })
    }

    function executeDecline() {
        decline(props.orderId, props.step.status?.id!!).then(res => {
            notifier.success("Success")
            props.reload()
        })
    }

    return (
        <>
            <Button disabled={loading} variant="primary" onClick={() => executeClicked()}>
                {t('order.confirm')}
            </Button>
            <Button disabled={loading} variant="warning" onClick={() => executeDeclineClicked()}>
                {t('main.cancel')}
            </Button>
            <ConfirmModal
                show={showConfirmModal}
                onHide={() => setShowConfirmModal(false)}
                onConfirm={executeConfirm}
                title={t('order.confirmTitle')}
                body={t('order.confirmBody')}
            />
            <ConfirmModal
                show={showDeclineModal}
                onHide={() => setShowDeclineModal(false)}
                onConfirm={executeDecline}
                title={t('main.cancel')}
                body={t('main.cancel')}
            />
        </>
    )
}